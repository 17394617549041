import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import Caption from '../../atoms/caption'

import styles from './ruler.module.scss'

interface RulerItemProps {
  label: string
  offset: number
}

export interface RulerProps {
  items: RulerItemProps[]
}

const Ruler: FunctionComponent<RulerProps> = ({
  items
}) => {
  return <ol className={clsx(
    styles.ruler
  )}>
    {items && items?.length > 0 && items.map( ({ label, offset }, index) =>
      <li className={clsx(
        styles.item,
      )} key={`${index}-${offset}`} style={{ left: `${offset}%` }}>
        <span className={styles.line}></span>
        <Caption className={styles.label}>{label}</Caption>
      </li>)}
  </ol>
}

export default Ruler

export type {
  RulerItemProps
}

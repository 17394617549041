import type { SectionProps } from '../section'

import { sortByKey } from '@sporza/utils/arrays'
import { useEffect,useState } from 'react'

type SortConfigItem = {
  label: string,
  iconAfter: string
  sortKey: keyof any
  order: 'ascending' | 'descending'
}

const useSortedItems = (data: any, sortConfig?: SortConfigItem[]) => {
  const { items } = data || {}

  const [sortedItems, setSortedItems] = useState<SectionProps['items']>(items)
  const [sortCfg, setSortCfg] = useState<SortConfigItem | undefined>(sortConfig?.[0])

  useEffect(() => {
    setSortedItems(items)
  }, [items])

  useEffect(() => {
    if (sortCfg) {
      const { sortKey, order } = sortCfg

      if (!sortKey) {
        console.warn('section - no `sortKey` defined while sort config is present')
        return
      }

      const newSortedItems = items?.length ? [...items] : []
      newSortedItems?.sort(sortByKey(sortKey as string, order))

      setSortedItems(newSortedItems)
    }
  }, [sortCfg, data])

  return {
    sortedItems,
    sortCfg,
    setSortCfg
  }
}

export {
  useSortedItems
}

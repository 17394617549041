import type { ClassValue } from 'clsx'
import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import Image, { ImageLayout } from '../../../molecules/image'

import styles from './podium.module.scss'

enum PodiumType {
  Podium = 'podium',
}

interface PodiumProps {
  className?: ClassValue
  type?: PodiumType
  title?: string
  imageUrls?: string[]
  designSystemBaseUrl?: string,
}

const Podium: FunctionComponent<PodiumProps> = (
  {
    className,
    type = PodiumType.Podium,
    imageUrls,
    designSystemBaseUrl
  }
) => {
  return <div
    className={clsx(
      styles.container,
      type === PodiumType.Podium && styles.podium,
      className
    )}
  >
    {
      imageUrls?.map((imageUrl, index) => {
        return <div className={styles.profile}>
          <Image
            className={styles.profileImg}
            fallbackImage={`${designSystemBaseUrl}/images/placeholders/player.svg`} src={imageUrl}
            // fallbackImage={`${designSystemBaseUrl}/images/cycling/default-shirt.svg`} src={imageUrl}
            layout={ImageLayout.Square}
            priority={true}
          />
        </div>
      })
    }
  </div>
}

export {
  Podium
}

export type {
  PodiumProps
}

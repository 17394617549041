const useEbaData = (ebaData: any = {}) => {
  const enrichEbaDataWithItemData = (
    {
      analyticsId,
      title,
      total,
      index,
      item,
      type
    }: any
  ) => {
    let elementTitle

    if (item?.componentProps) {
      elementTitle = item.componentProps.title
    } else {
      elementTitle = item?.title
    }

    return {
      listAnalyticsId: analyticsId,
      listTitle: title,
      listType: type === 'SectionSlider'
        ? 'horizontal'
        : 'grid',
      listElementCount: total,
      listElementPosition: index + 1 + (ebaData?.listElementOffset || 0),
      elementTargetUrl: item.link,
      elementTitle: elementTitle || 'no title provided',
      ...ebaData,
    }
  }

  return {
    enrichEbaDataWithItemData
  }
}

export {
  useEbaData
}

import ecsFormat from '@elastic/ecs-pino-format'
import {
  awsLambdaFunctionName,
  awsLambdaFunctionVersion,
  logLevel,
  nodeEnv
} from '@sporza/config'
import pino from 'pino'
import pinoHTTP from 'pino-http'

const isProduction = nodeEnv === 'production'

const loggerOptions = ecsFormat()

if (isProduction) {
  loggerOptions.base = {
    faas: {
      name: awsLambdaFunctionName,
      version: awsLambdaFunctionVersion
    }
  }
  loggerOptions.level = logLevel
} else {
  // TODO: add pino-pretty
}

loggerOptions.browser = {
  asObject: false
}

const logger = pino(loggerOptions)

const httpLogger = pinoHTTP({
  logger,
  level: logLevel
})

export {
  httpLogger,
  logger
}

export default logger

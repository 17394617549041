import type { FunctionComponent } from 'react'
import type {
  ResultGroupsProps,
  StartGroupsProps
} from '../../index'

import clsx from 'clsx'

import { StatusTypes } from '../../../../molecules/score'
import commonStyles from '../../draw.module.scss'
import { ResultSelection } from '../../index'

import styles from './iedereen-bondscoach-selection.module.scss'

interface IedereenBondscoachSelectionProps {
  name?: string
  status?: string
  availableSlots: number | null
  startingGroups: StartGroupsProps | null
  resultGroups: ResultGroupsProps
  designSystemBaseUrl?: string
}

const IedereenBondscoachSelection: FunctionComponent<IedereenBondscoachSelectionProps> = (
  {
    name,
    status,
    resultGroups,
    availableSlots,
    designSystemBaseUrl
  }
) => {
  const isLive = status?.toLowerCase() === StatusTypes.Live.toLowerCase()

  return <div
    className={clsx(
      commonStyles.draw,
      styles.selection
    )}
    aria-label={name}
  >
    {
      resultGroups && !resultGroups.hidden
        ? <ResultSelection
          {...resultGroups}
          isLive={isLive}
          availableSlots={availableSlots}
          designSystemBaseUrl={designSystemBaseUrl}
        />
        : false
    }
  </div>
}

export {
  IedereenBondscoachSelection
}

export type {
  IedereenBondscoachSelectionProps
}


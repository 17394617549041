import clsx from 'clsx'
import React, { FunctionComponent, PropsWithChildren } from 'react'

import Caption, { CaptionWeight } from '../../atoms/caption'
import Icon from '../../atoms/icon'
import { Icons } from '../../design-tokens/iconography/icons'
import { getOffsetPercentageByMinutes } from '../hourpicker'

import styles from './program-item.module.scss'

interface ProgramItemProps extends PropsWithChildren {
  title: string
  subTitle: string
  link?: string
  start: string
  end: string
  icon?: Icons | string
  isLive?: boolean
  listStart?: Date
  listEnd?: Date
}

const ProgramItem: FunctionComponent<ProgramItemProps> = (
  {
    title,
    subTitle,
    link,
    icon,
    isLive,
    start,
    end,
    listStart,
    listEnd
  }
) => {
  const style = (listStart && listEnd) ? {
    left: getOffsetPercentageByMinutes(new Date(start), listStart, listEnd) + '%',
    width: getOffsetPercentageByMinutes(new Date(end), listStart, listEnd) - getOffsetPercentageByMinutes(new Date(start), listStart, listEnd) + '%'
  } : {}

  return <li className={clsx(
    styles.programItem,
    isLive && styles.live
  )}
  style={style}>
    <Caption className={styles.subTitle}>
      {icon && <Icon name={icon} />} {subTitle}
    </Caption>
    <Caption weight={CaptionWeight.Medium} className={styles.title}>
      {link ? <a href={link}>{title}</a> : title}
    </Caption>
  </li>
}

export default ProgramItem

export type {
  ProgramItemProps
}

import type { FunctionComponent, PropsWithChildren } from 'react'

import Title, { TitleElement, TitleSize } from '../../atoms/title'
import Table, { TableComponentProps } from '../table'

import styles from './medal-table.module.scss'

interface MedalTableComponentProps {
  componentProps: MedalTableProps
}

interface MedalTableProps extends PropsWithChildren {
  title?: string
  headerTable?: TableComponentProps
  table?: TableComponentProps
}

const MedalTable: FunctionComponent<MedalTableProps> = (
  {
    title,
    headerTable,
    table
  }
) => {
  return <div className={styles.medalTable}>
    {title && <Title size={TitleSize.Large} tag={TitleElement.H2}>{title}</Title>}
    {headerTable && <Table {...headerTable.componentProps} className={styles.headerTable} />}
    {table && <Table {...table.componentProps} className={styles.table} />}
  </div>
}

export default MedalTable

export type {
  MedalTableProps,
  MedalTableComponentProps
}

import type { FunctionComponent } from 'react'

import clsx from 'clsx'
import { useEffect, useState } from 'react'

import Title, { TitleElement } from '../../../atoms/title'
import Button from '../../../molecules/button'
import { DrawItemType } from '../config'
import commonStyles from '../draw.module.scss'

import { ResultMatch } from './index'
import styles from './result-matches.module.scss'

interface ResultMatchesProps {
  hidden?: boolean
  type?: DrawItemType
  name?: string
  groups?: any[]
  sport?: string
  isLive?: boolean
  availableSlots?: number | null
}

const ResultMatches: FunctionComponent<ResultMatchesProps> = (
  {
    type,
    name,
    groups,
    sport,
    isLive,
    availableSlots
  }
) => {
  const [init, setInit] = useState<boolean>(false)
  const [hasNewItem, setHasNewItem] = useState<boolean>(false)
  const [previousLengths, setPreviousLengths] = useState<number[] | undefined>(undefined)

  useEffect(() => {
    if (!init) {
      setInit(true)
    }
  }, [])

  useEffect(() => {
    if (!groups || !init)
      return

    const currentLengths = groups.map(group => (
      group.objects
        ? group.objects.filter((obj: any) => obj.name !== '---').length
        : 0
    ))

    if (
      previousLengths
      && currentLengths.some((length, index) => length > previousLengths[index])
    ) {
      setHasNewItem(true)
    }

    setPreviousLengths(currentLengths)

    const timerId = setTimeout(() => {
      setHasNewItem(false)
    }, 1000)

    return () => clearTimeout(timerId)
  }, [groups])

  return <div className={styles.resultMatches}>
    {
      isLive
        ? <Button
          variant="live"
          iconBefore="live-alt"
          className={clsx(
            commonStyles.liveButtonDraw,
            commonStyles.hideOnMobile
          )}
        >live</Button>
        : false
    }
    <Title size="Large" tag={TitleElement.H2}>{name}</Title>

    <div className={clsx(
      styles.matches,
      hasNewItem && styles.hasNewItem
    )}>
      {
        groups
          ? groups.map((group: any) =>
            <ResultMatch
              key={group.name}
              {...group}
              type={type}
              sport={sport}
              availableSlots={availableSlots}
            />
          )
          : false
      }
    </div>
  </div>
}

export {
  ResultMatches
}

export type {
  ResultMatchesProps
}

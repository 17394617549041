import type { FunctionComponent } from 'react'
import type { VotingProps } from '../../../voting'

import { useAuth, useScreenshot, useWebShareApi } from '@sporza/hooks'
import clsx from 'clsx'
import { useRef } from 'react'

import Paragraph from '../../../../../atoms/paragraph'
import Title, { TitleSize } from '../../../../../atoms/title'
import Button from '../../../../../molecules/button'
import { VotingPhase } from '../../../config'
import { ShareScreenshot } from '../components'
import { Sponsor } from '../components'
import commonStyles from '../eddies.module.scss'

type EddiesResultsProps = VotingProps

const mapResultsVote = (vote) => {
  return vote?.votings?.map((category) => {
    return {
      // category: category.shortDescription,
      // vote: category.selection?.external?.shortTitle
    }
  })
}

const EddiesResults: FunctionComponent<EddiesResultsProps> = (
  {
    categories,
    vote,
    broadcastDate,
    handlePhaseChange,
    className
  }
) => {
  const ref = useRef(null)
  const {
    getImageBlob,
    imageExtension
  } = useScreenshot(ref)
  const { isWebShareApiSupported, share } = useWebShareApi()
  const { name } = useAuth()

  const shareResult = async () => {
    const imageBlob = await getImageBlob()

    const shareData: ShareData = {
      title: `${name ? `${name}'s` : 'mijn'} votes`,
      text: 'Dit zijn mijn votes voor de Eddies',
      url: window.location.href,
      files: [
        new File([imageBlob], `${name ? `${name}` : 'mijn'}-eddies-votes.${imageExtension}`, {
          type: imageBlob.type,
        }),
      ],
    }

    await share(shareData)
  }

  return <div
    className={className}
  >
    <div className={clsx(
        commonStyles.header,
        commonStyles.headerCenter
    )}>
      <Sponsor className={commonStyles.sponsor} />
      <Title size={TitleSize.Large} desktopSize={TitleSize.XLarge}>bedankt<span className={commonStyles.accentPoint}>.</span></Title>
      <Paragraph>We hebben je selectie goed ontvangen. Volg de uitzending van de Eddy&apos;s op <b>{broadcastDate}</b> op Sporza, VRT MAX of Proximus Pickx.</Paragraph>
      <br/>
      {vote && <div className={commonStyles.buttonWrapper}>
        {isWebShareApiSupported
          && <Button
          className={commonStyles.ctaButton}
          onClick={() => shareResult()}
          iconAfter="chevron-right"
        >deel je stem</Button>}
        <a
          className={commonStyles.linkButton}
          onClick={() => {
            handlePhaseChange && handlePhaseChange(VotingPhase.voting, null, vote)
          }}
        >stem aanpassen</a>
      </div>}
      {vote && <ShareScreenshot categories={categories} vote={vote} reffy={ref} />}
    </div>
  </div>
}

export {
  EddiesResults
}

export type {
  EddiesResultsProps
}


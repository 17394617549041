import type { FunctionComponent } from 'react'

import Title, { TitleSize } from '../../../../atoms/title'
import styles from '../../score.module.scss'
import { ScoreRoundsProps } from '../default'

const BannerRounds: FunctionComponent<ScoreRoundsProps> = ({
  roundsToGo,
  startLabel,
  date,
  darkMode
}) => {
  return <Title darkMode={darkMode} size={TitleSize.XSmall}>
    {date && <span className={styles.item}>{date}</span>}
    {startLabel && <span className={styles.item}>{startLabel}</span>}
    {roundsToGo && <span className={styles.item}>nog {roundsToGo} {roundsToGo === 1 ? 'ronde' : 'rondes'}</span>}
  </Title>
}

export default BannerRounds

export type {
  ScoreRoundsProps
}

import type { VotingProps } from '../../../voting'

import { useAuth, useInteractionService } from '@sporza/hooks'
import clsx from 'clsx'
import { FunctionComponent, useEffect } from 'react'

import Paragraph from '../../../../../atoms/paragraph'
import Title, { TitleSize } from '../../../../../atoms/title'
import Button from '../../../../../molecules/button'
import { VotingPhase } from '../../../config'
import { LoginButton, Sponsor } from '../components'
import commonStyles from '../eddies.module.scss'

import styles from './landing.module.scss'

type EddiesLandingProps = VotingProps & {
  isLive: boolean,
  broadcastDate: string,
}

const EddiesLanding: FunctionComponent<EddiesLandingProps> = (
  {
    isLive,
    broadcastDate,
    handlePhaseChange,
    className
  }
) => {
  const { isLoggedIn, name } = useAuth()

  const title = 'breng je stem uit'
  const subtitle = isLive
    ? <>Dag {name}, stem in elke categorie op je favoriet en bepaal mee wie er <b>op {broadcastDate}</b> met een Eddy naar huis gaat. <b>Let op:</b> je stem is pas geldig na het bevestigen van de laatste categorie.</>
    : <>Dag {name}, de stemming voor de Eddy’s is nog niet gestart. Kom later terug om je favorieten te kiezen en bepaal mee wie er zich <b>op {broadcastDate}</b> met een Eddy naar huis gaat.</>

  return <div className={className}>
    <div className={clsx(
      commonStyles.header,
      commonStyles.headerCenter
    )}>
      <Sponsor className={commonStyles.sponsor} />
      <Title size={TitleSize.Large} desktopSize={TitleSize.XLarge}>{title}<span className={commonStyles.accentPoint}>.</span></Title>
      <Paragraph className={styles.subtitle}>{subtitle}</Paragraph>
    </div>
    {
      isLive
        ?
        isLoggedIn
          ? <Button
            className={commonStyles.ctaButton}
            iconAfter="chevron-right"
            onClick={() => handlePhaseChange && handlePhaseChange(VotingPhase.voting)}
          >
            ok, ik begin eraan
          </Button>
          : <LoginButton/>
        : <>we beginnen er zo dadelijk aan</>
    }
  </div>
}

export {
  EddiesLanding
}

export type {
  EddiesLandingProps
}


import type { ClassValue } from 'clsx'
import type { FunctionComponent, PropsWithChildren, RefObject } from 'react'

import clsx from 'clsx'
import { useHotkeys } from 'react-hotkeys-hook'

import styles from './modal.module.scss'

interface ModalProps extends PropsWithChildren {
  className?: ClassValue,
  onClose?: () => void
  innerRef: RefObject<any>
}

const Modal: FunctionComponent<ModalProps> = (
  {
    className,
    children,
    onClose,
    innerRef,
  }
) => {
  useHotkeys(
    'esc',
    () => onClose
      ? onClose()
      : innerRef.current?.close(),
  )

  return <dialog
    className={clsx(
    styles.modal,
    className
  )}
    ref={innerRef}
    onCancel={event => event.preventDefault()}
  >
    {children}
  </dialog>
}

export {
  Modal
}

export type {
  ModalProps
}

import type { VotingProps } from '../../../voting'
import type { Vote } from '@sporza/services'

import { useInteractionService } from '@sporza/hooks'
import { setStorage } from '@sporza/utils/storage'
import clsx from 'clsx'
import { FunctionComponent, useEffect, useState } from 'react'

import Paragraph from '../../../../../atoms/paragraph'
import Title, { TitleSize } from '../../../../../atoms/title'
import Button from '../../../../../molecules/button'
import { Notification, NotificationType } from '../../../../../molecules/notification'
import { VotingPhase } from '../../../config'
import { OptionOptionProps, Selection, Sponsor } from '../components'
import { key } from '../eddies'
import commonStyles from '../eddies.module.scss'

interface EddiesVotingCategoryProps {
  name: string
  description: string
  shortDescription: string
  votingId: string
  options: OptionOptionProps[]
}

interface EddiesVotingProps extends VotingProps {
  categories?: EddiesVotingCategoryProps[]
  aggregatorUrl?: string
}

// const mapVoteToModel = (vote: Vote) => {
//   return vote?.votings?.map((category) => {
//     return {
//       category: category.shortDescription,
//       vote: category.selection?.external?.shortTitle
//     }
//   })
// }

const EddiesVoting: FunctionComponent<EddiesVotingProps> = (
  {
    slug,
    categories = [],
    handlePhaseChange,
    className,
    votingBaseUrl,
    aggregatorUrl
  }
) => {
  const { createVote: createInteractionVote } = useInteractionService(votingBaseUrl, slug)

  const [categoriesSt, setCategoriesSt] = useState(categories)
  const [category, setCategory] = useState<EddiesVotingCategoryProps>(categoriesSt[0])
  const [step, setStep] = useState(1)
  const [disabled, setDisabled] = useState(true)
  const [notification, setNotification] = useState<string>()

  const createVote = async () => {
    setNotification(undefined)

    if (!slug) {
      console.error('slug is missing')

      return
    }

    const selection = categoriesSt
      ?.map((category: EddiesVotingCategoryProps) => {
        const selected = category.options?.find((option) => option.selected)

        if (!selected) {
          return []
        }

        return  {
          ...category,
          selection: [{
            kind: 'Id',
            id: selected.interactionOptionId,
            external: selected
          }]
        }
      })

    const vote: Vote = {
      slug,
      votings: selection
    }

    if (createInteractionVote) {
      const responseStatus = await createInteractionVote(vote)

      if (responseStatus > 299) {
        setNotification('er is iets misgegaan, probeer het later opnieuw')
      } else {
        setNotification('bedankt voor je stem!')
        handlePhaseChange && handlePhaseChange(VotingPhase.results, null, vote)
      }
    }
  }

  const toggleOption = (option: any) => {
    const updatedOptions = category.options
      .map((o) => {
        const unSelected = category.options.find((o) => o === option && !o.selected) ? false : undefined
        if (o === option) {
          return {
            ...o,
            selected: o.selected ? undefined : true
          }
        } else {
          return {
            ...o,
            selected: unSelected
          }
        }
      })

    setCategory({
      ...category,
      options: updatedOptions
    })
  }

  const getSelectedOption = category?.options?.find((option) => option.selected)

  useEffect(() => {
    setNotification(undefined)
    setCategory(categoriesSt[step-1])
  }, [step])

  useEffect(() => {
    setDisabled(!getSelectedOption)
    if (getSelectedOption){
      categoriesSt[step-1] = category
      setStorage(key, categoriesSt)
    }
  }, [category])

  useEffect(() => {
    setCategoriesSt(categories)
    setCategory(categories[0])
  }, [categories])

  return category && <div
    className={clsx(
      className
    )}
  >
    <div className={commonStyles.header}>
      <Sponsor className={commonStyles.sponsor} />
      <Paragraph><b>{step}</b> van <b>{categoriesSt.length}</b></Paragraph>
      <Title size={TitleSize.Large} desktopSize={TitleSize.XLarge}>{category?.name}<span className={commonStyles.accentPoint}>.</span></Title>
      <Paragraph>
        {category?.description}
      </Paragraph>
    </div>

    <Selection
      aggregatorUrl={aggregatorUrl}
      title={category?.name}
      options={category?.options}
      toggleOption={toggleOption} />

    <div className={commonStyles.buttonWrapper}>
      {step > 1 && <Button
        className={commonStyles.backButton}
        onClick={() => {
          setStep(step - 1)
        }}
        iconBefore="chevron-left"
      >vorige</Button>}

      <Button
        className={commonStyles.ctaButton}
        disabled={disabled}
        ariaLabel={disabled ? 'Kies eerst een optie hierboven' : undefined}
        onClick={() => {
          switch (true) {
            case getSelectedOption === undefined:
              setNotification('kies een optie')
              break
            case step === categoriesSt.length:
              createVote()
              break
            default: {
              setStep(step + 1)
              break
            }
          }
        }}
        iconAfter="chevron-right"
      >volgende</Button>
    </div>

    {
      notification
      && <Notification
        type={NotificationType.Error}
        text={notification}
        darkMode={true}
      />
    }

  </div>
}

export {
  EddiesVoting
}

export type {
  EddiesVotingProps
}


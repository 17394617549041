import type { FunctionComponent } from 'react'
import type { Size } from 'react-container-query/lib/interfaces'
import type {
  WielerManagerCountdownProps,
  WielerManagerRankingProps,
  WielerManagerStatisticsProps
} from './layouts'
import type { BannerClickArguments } from '@sporza/tracking/events'

import { useBannerImpression } from '@sporza/tracking'
import { bannerClickEvent } from '@sporza/tracking/events'
import { capitalize } from '@sporza/utils/strings'
import { useEffect, useRef } from 'react'
import { useContainerQuery } from 'react-container-query'

import styles from './layouts/common.module.scss'
import { WielerManagerCountdown, WielerManagerRanking, WielerManagerStatistics } from './layouts'

enum WielerManagerLayout {
  Countdown = 'Countdown',
  Ranking = 'Ranking',
  Statistics = 'Statistics',
}

interface WielerManagerProps extends WielerManagerCountdownProps, WielerManagerRankingProps, WielerManagerStatisticsProps {
  layout?: WielerManagerLayout
}

const layouts = {
  [WielerManagerLayout.Countdown]: WielerManagerCountdown,
  [WielerManagerLayout.Ranking]: WielerManagerRanking,
  [WielerManagerLayout.Statistics]: WielerManagerStatistics
}

const query = {
  medium: {
    minWidth: 640
  },
  large: {
    minWidth: 1024
  }
}

const getWidgetName = (source?: string) => {
  switch (source?.toLowerCase()) {
    case 'giro':
    case 'girof':
    case 'girom':
      return 'giromanager'
    case 'tour':
    case 'tourf':
    case 'tourm':
      return 'tourmanager'
    case 'vuel':
    case 'vuelf':
    case 'vuelm':
      return 'vueltamanager'
    default:
      return 'wielermanager'
  }
}

const shouldShowSponser = (source?: string) => {
  switch (source?.toLowerCase()) {
    case 'tour':
    case 'tourf':
    case 'tourm':
    case 'klasf':
    case 'klasm':
      return true
    default:
      return false
  }
}

const trackBannerClick = (bannerContext: any) => {
  bannerClickEvent(bannerContext as BannerClickArguments)

  return true
}

const WielerManager: FunctionComponent<Partial<WielerManagerProps>> = (
  {
    layout = WielerManagerLayout.Countdown,
    ...props
  }
) => {
  const Layout = layouts[layout] || layouts[WielerManagerLayout.Countdown]
  const widgetName = getWidgetName(props.source)

  const bannerContext = {
    bannerTitle: `${capitalize(widgetName)} ${layout}`,
    componentName: widgetName
  }

  const [params, containerRef] = useContainerQuery(query, null as unknown as Size)
  const ref = useRef(null)

  useBannerImpression(
    {
      ref: ref,
      ...bannerContext
    }
  )

  useEffect(() => {
    if (ref) {
      containerRef(ref.current)
    }
  }, [ref])

  return <div className={styles.root}>
    <Layout
      widgetName={widgetName}
      bannerContext={bannerContext}
      containerQueries={{ ...params, [layout]: true }}
      showSponsor={shouldShowSponser(props.source)}
      {...props}
      innerRef={ref}
    />
  </div>
}

export default WielerManager

export {
  WielerManagerLayout,
  trackBannerClick
}

export type {
  WielerManagerProps
}

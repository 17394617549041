import cookie from 'cookie'

const parseCookie = (cookieString?: string | null) => {
  if (!cookieString) {
    return
  }

  return cookie.parse(cookieString)
}

const setCookie = (name: string, val: string) => {
  const date = new Date()
  const value = val

  // Set it expire in 7 days
  date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000))

  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`
}

const getCookie = (name: string) => {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + name + '=')

  if (parts.length == 2) {
    return parts
      .pop()
      ?.split(';')
      ?.shift()
  }
}

const deleteCookie = (name: string) => {
  const date = new Date()

  // Set it expire in -1 days
  date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000))

  document.cookie = name+'=; expires='+date.toUTCString()+'; path=/'
}

const cookieExists = (cookieName: string) =>
  !!document.cookie.match(
    RegExp(`(?:^|;\\s*)${cookieName}=([^;]*)`)
  )

export {
  parseCookie,
  setCookie,
  getCookie,
  deleteCookie,
  cookieExists
}

import { Vote } from '@sporza/services'
import { FunctionComponent, PropsWithChildren } from 'react'

import Icon from '../../../../../atoms/icon'
import Paragraph from '../../../../../atoms/paragraph'
import Image from '../../../../../molecules/image'

import styles from './share-screenshot.module.scss'
import { Sponsor } from './sponsor'

interface ShareScreenshotProps extends PropsWithChildren {
  vote: Vote
  categories: any
  reffy?: any
}

const ShareScreenshot: FunctionComponent<ShareScreenshotProps> = ({ categories, vote, reffy }) => {
  const votingsWithShortDescription = vote?.votings?.map((category) => {
    const matchingCategory = categories?.find((c) => c.votingId === category.votingId)

    return {
      ...category,
      shortDescription: matchingCategory?.shortDescription || matchingCategory?.name,
    }
  })

  return <div className={styles.screenshot} aria-hidden={true} ref={reffy}>
    <Sponsor className={styles.sponsor}/>
    <div className={styles.bg}></div>
    <Icon name={'sporza-small-dark'} className={styles.screenshotSporzaIcon}/>
    <div className={styles.screenshotHeader}>
      <Image src={'https://images.vrt.be/orig/sporza/backgrounds/voting/eddies-share-header.png?updated=123'} priority={true} noCrop={true} />
      <Paragraph>dit zijn mijn favorieten voor de uitreiking van de Eddy&apos;s op 16 oktober</Paragraph>
    </div>
    <ul className={styles.screenshotList}>
      {votingsWithShortDescription?.map((category, index) => {
        return <li key={index} className={styles.screenshotListItem}>
          <Paragraph className={styles.screenshotNumber}>{index + 1}.</Paragraph>
          <Paragraph className={styles.screenshotLabel}>{category.shortDescription}</Paragraph>
          <Paragraph className={styles.screenshotVote}>{category.selection[0]?.external?.shortTitle}</Paragraph>
        </li>
      })}
    </ul>
  </div>
}

export {
  ShareScreenshot
}

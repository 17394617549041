import type { ClassValue } from 'clsx'
import type { FunctionComponent } from 'react'

import { mapToComponent } from '@sporza/services/component-mapper'
import clsx from 'clsx'

interface ExperienceFragmentProps {
  items?: any[]
  className?: ClassValue
  siteConfig?: Record<any, any>
}

const ExperienceFragment: FunctionComponent<ExperienceFragmentProps> = (
  {
    items,
    className,
    siteConfig
  }
) => {
  return <div className={clsx(className)}>
    {
      items
        ?.map((subItem: any) =>
          mapToComponent(subItem, siteConfig)
        )
    }
  </div>
}

export default ExperienceFragment

export type {
  ExperienceFragmentProps
}

import type { ChangeEvent, FocusEvent, FunctionComponent } from 'react'
import type { SearchDataType, SearchProps } from '../index'

import { getStorage, removeStorage, setStorage } from '@sporza/utils/storage'
import { getParam, setParam } from '@sporza/utils/urls'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { useDebounce } from 'use-debounce'

import Icon from '../../../atoms/icon'
import Button, { ButtonVariant } from '../../../molecules/button'
import Cell from '../../cell'
import Grid from '../../grid'
import LinkList, { LinkListDirection, LinkListLayout } from '../../link-list'
import Section from '../../section/section'
import styles from '../search.module.scss'

enum SearchFilterType {
  articles = 'articles',
  videos = 'videos',
  podcasts = 'podcasts',
  players = 'players',
  teams = 'teams'
}

const FilterElements = (type: SearchFilterType, data: any) => {
  switch (type) {
    case SearchFilterType.articles:
    case SearchFilterType.videos:
    case SearchFilterType.podcasts:
      return <Section {...data} />
    case SearchFilterType.players:
    case SearchFilterType.teams:
      return <LinkList className={styles.linkWithImage} {...data} />
  }
}

const Default: FunctionComponent<SearchProps> = (
  {
    query,
    filter,
    data,
    sportApiUrl,
    minCharacters = 2,
    key = 'recent_searches'
  }
) => {
  const [currentData, setCurrentData] = useState<SearchDataType>(data)
  const [q, setQ] = useState<string>(getParam('q') || query || '')
  const [f, setF] = useState<SearchFilterType|string>(getParam('f') as SearchFilterType || filter || '')
  const [p, setP] = useState<number>(getParam('p') ? parseInt(String(getParam('p'))) :  1)
  const [debouncedQ] = useDebounce(q, 300)
  const recentSearches = getStorage(key) || []
  const [loading, setLoading] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [cursorPosition, setCursorPosition] = useState<number | null>(null)

  useEffect(() => {
    setCurrentData({})
    if (debouncedQ === ''){
      setP(1)
    }
    setParam('q', debouncedQ)
    setParam('f', f)
    setParam('p', String(p))
    addToRecentSearches(debouncedQ)
    getResults(debouncedQ, f, p)
  }, [debouncedQ, f, p])

  const changeFilter = (value: SearchFilterType|string) => {
    setP(1)
    setF(value)
  }

  const handleClose = () => {
    setLoading(false)
    setQ('')
    setF('')
    setP(1)
  }

  const addToRecentSearches = (value: string) => {
    if (value && value.length >= minCharacters){
      const recentSearches = getStorage(key) || []

      const itemFound = recentSearches.filter((item: any) => item?.title?.toLowerCase() === value.toLowerCase())

      if (currentData.themes && currentData[SearchFilterType.articles] && itemFound.length === 0) {
        recentSearches.push({
          title: value,
          href: '?q=' + value,
          timestamp: Date.now()
        })
        setStorage(key, recentSearches.slice(0, 8))
      }
    }
  }

  const getSuggestion = () => {
    return getParam('prev')
  }

  const setEBAData = (results: any) => {
    window.ebaData = {
      ...window.ebaData,
      search: {
        context_search: {
          searchTerm: getSuggestion() || debouncedQ,
          suggestSearchTerm: getSuggestion() ? debouncedQ : '',
          searchFilter: f,
          searchType: getSuggestion() ? 'autosuggest' : 'classic',
          searchCount: results?.pagination?.totalResults
        }
      }
    }
  }

  const getResults = (value: string, filter: SearchFilterType|string, p: number|string) => {
    if (value && value.length >= minCharacters) {
      const url = new URL(sportApiUrl)
      url.searchParams.set('query', value)
      url.searchParams.set('filter', filter)
      url.searchParams.set('page', String(p))

      setLoading(true)
      fetch(url, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (!responseJson.errorMessages && !responseJson.error) {
            setEBAData(responseJson.componentProps.data)
            setCurrentData(responseJson.componentProps.data)
            setLoading(false)
          }
          return null
        })
        .catch((error) => {
          console.error(error)
          return null
        })
    }
  }

  const handleOnChange = (e:ChangeEvent<HTMLInputElement>) => {
    setQ(e.target.value)

    if(inputRef.current)
      setCursorPosition(inputRef.current.selectionStart)
  }

  const handleOnFocus = (e:FocusEvent<HTMLInputElement>) => {
    if(typeof cursorPosition === 'number')
      e.target.setSelectionRange(cursorPosition, cursorPosition)
  }

  const hasResults = currentData[SearchFilterType.articles] || currentData[SearchFilterType.videos] || currentData[SearchFilterType.podcasts] || currentData.players || currentData.teams || currentData.themes

  const Input = () => <div className={styles.inputWrapper}>
    <input ref={inputRef} className={styles.input} onChange={handleOnChange} onFocus={handleOnFocus} value={q} type="text" placeholder={'zoek sporten, competities, artikels, sporters, …'} autoFocus />
    {loading ? <Icon name={'spinner'} className={clsx(styles.spinner,styles.searchIcon)} /> : <Icon name={'search'} className={styles.searchIcon} />}
    <Button iconAfter={'close'} variant={ButtonVariant.secondary} className={styles.closeIcon} onClick={() => handleClose()} />
    {currentData.suggestions && <LinkList className={styles.suggestions} iconAfter={''} items={currentData.suggestions} />}
    {currentData.didYouMean && <div className={styles.didYouMean}>bedoelde je: <LinkList className={styles.didYouMeanList} direction={LinkListDirection.Horizontal} iconAfter={''} items={currentData.didYouMean} /></div>}
  </div>

  const Filter = () => <div className={styles.filter}>
    {currentData.filters?.map((item) => <Button key={`filter-${item.title}`} active={f === item.value} {...item} onClick={() => changeFilter(item.value as SearchFilterType)}>{item.title}</Button>)}
  </div>

  const OverviewResults = () => hasResults ? <>
    {currentData[SearchFilterType.articles] && <Section {...currentData[SearchFilterType.articles]?.componentProps} linkAction={() => changeFilter(SearchFilterType.articles)} />}
    {currentData[SearchFilterType.videos] && <Section {...currentData[SearchFilterType.videos]?.componentProps} linkAction={() => changeFilter(SearchFilterType.videos)} />}
    {currentData[SearchFilterType.podcasts] && <Section {...currentData[SearchFilterType.podcasts]?.componentProps} linkAction={() => changeFilter(SearchFilterType.podcasts)} />}
    {currentData[SearchFilterType.players] && <LinkList layout={LinkListLayout.Grey} {...currentData[SearchFilterType.players]?.componentProps} />}
    {currentData[SearchFilterType.teams] && <LinkList layout={LinkListLayout.Grey} {...currentData[SearchFilterType.teams]?.componentProps} />}
  </> : null

  const FilteredResults = () => {
    const { pagination = {} } = currentData
    const { currentPage, totalPages } = pagination

    return f && currentData[SearchFilterType[f as SearchFilterType]] ? <>
      {FilterElements(SearchFilterType[f as SearchFilterType], currentData[SearchFilterType[f as SearchFilterType]]?.componentProps)}
      {totalPages !== undefined && <div className={styles.pagination}>
          <Button variant={'tertiary'} iconBefore={currentPage-1 > 0 ? 'chevron-left' : 'chevron-left-disabled'} onClick={() => currentPage-1 > 0 && setP(currentPage-1)} />
          {currentPage} / {totalPages}
          <Button variant={'tertiary'} iconBefore={currentPage+1 <= totalPages ? 'chevron-right' : 'chevron-right-disabled'} onClick={() => currentPage+1 <= totalPages && setP(currentPage+1)} />
      </div>}
    </> : null
  }

  const emptyPlaceholder = <div className={styles.message}>
    We konden geen resultaten vinden voor <b>&apos;{q}&apos;</b> Probeer opnieuw met een andere zoekterm.
  </div>

  return <Grid>
    <Cell size={4}>
      <Input />
      {!debouncedQ && recentSearches.length > 0 && <LinkList className={styles.keywords} variant={ButtonVariant.primary} iconAfter={''} direction={LinkListDirection.Horizontal} subtitle={<a href={'#'} onClick={() => removeStorage(key)} >wis recent gezocht</a>} items={recentSearches} title={'recent gezocht'} />}
      {!debouncedQ && <LinkList className={styles.keywords} variant={ButtonVariant.primary} iconAfter={''} direction={LinkListDirection.Horizontal} {...data?.mostSearched?.componentProps} />}
    </Cell>
    {debouncedQ && hasResults && <>
      <Cell size={3}>
        <Filter />
        {f ? <FilteredResults /> : <OverviewResults />}
      </Cell>
      <Cell size={1}>{currentData.themes && <LinkList className={styles.themes} {...currentData.themes.componentProps} />}</Cell>
    </>}
    {q && !hasResults && !loading && minCharacters <= debouncedQ.length && emptyPlaceholder}
  </Grid>
}

export default Default

export {
  SearchFilterType
}

export type {
  SearchProps
}

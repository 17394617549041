import type { FunctionComponent } from 'react'
import type { StoryCardComponentProps } from '../story-card'

import { ScoreboardComponentProps } from '../scoreboard'
import { SectionComponentProps } from '../section'

import EventDefault from './layouts'

interface EventProps {
  title?: string
  link?: string,
  calendarUrl?: string,
  articleSubTitle?: string
  matchSubTitle?: string
  livestreamSubTitle?: string
  items: StoryCardComponentProps[]
  matchItems?: ScoreboardComponentProps[]
  livestreamSection?: SectionComponentProps
  config: {
    icon?: string,
    accentColor: string,
    backgroundLeftImageUrl: string,
    backgroundRightImageUrl: string,
    logoUrl: string,
    iconUrl: string,
  }
  darkMode?: boolean
  analyticsId?: string
}

const Event: FunctionComponent<EventProps> = (props) => {

  return props?.items?.length
    ? <EventDefault {...props} />
    : null
}

export default Event

export type {
  EventProps
}

import type { FunctionComponent, PropsWithChildren } from 'react'

import Caption from '../../../atoms/caption'
import Icon from '../../../atoms/icon'
import Title, { TitleSize } from '../../../atoms/title'

import styles from './error-element.module.scss'

interface ErrorElementProps extends PropsWithChildren {
  title: string
  message: string
}

const ErrorElement: FunctionComponent<ErrorElementProps> = (
  {
    title,
    message,
    children
  }
) => <div className={styles.error}>
  <div className={styles.errorInner}>
    <Icon name="error" className={styles.errorIcon} />
    {children}
    <Title size={TitleSize.XSmall} className={styles.errorTitle}>{title}</Title>
    <Caption className={styles.errorMessage}>{message}</Caption>
  </div>
</div>

export {
  ErrorElement
}

import { useEffect, useState } from 'react'

const useMatch = (match: any) => {
  const [ isLive, setIsLive ] = useState(!!match?.isLive)

  useEffect(() => {
    setIsLive(!!match?.isLive)
  }, [match.isLive])

  return {
    isLive
  }
}

export {
  useMatch
}

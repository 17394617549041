import type { FunctionComponent, PropsWithChildren } from 'react'

import { splitInEqualParts } from '@sporza/utils/arrays'
import clsx from 'clsx'

import Title, { TitleElement, TitleSize } from '../../atoms/title'

import styles from './accordion.module.scss'
import AccordionItem, { AccordionItemProps } from './accordion-item'

enum AccordionLayouts {
  Groups = 'groups',
  Default ='default'
}

interface AccordionProps extends PropsWithChildren {
  label?: string
  items: AccordionItemProps[]
  emptyPlaceholder?: any
  darkMode?: boolean
  layout?: string
}

const getItems = (
  items: any[],
  emptyPlaceholder: AccordionProps['emptyPlaceholder'],
  openFirst?: boolean,
  layout?: string
) => {
  return items.length > 0
    ? <div>
      {
        items.map((item, index) =>
          {
            return <AccordionItem
              key={(item?.label || '') + index}
              open={openFirst && index === 0}
              layout={layout}
              content={item?.content}
              {...item}
            />
          }
        )
      }
    </div>
    : emptyPlaceholder
}

const Accordion: FunctionComponent<AccordionProps> = (
  {
    label,
    items = [],
    darkMode,
    emptyPlaceholder,
    layout= AccordionLayouts.Default

}
) => {
  const [group1, group2] = items?.length > 0 && layout === AccordionLayouts.Default
    ? splitInEqualParts(items, 2)
    : [[], []]

  return <div className={clsx(
    styles.accordion,
    layout === AccordionLayouts.Groups && styles.groups
  )}>
    <Title
      tag={TitleElement.H2}
      size={TitleSize.Medium}
    >
      {label}
    </Title>
    <div className={styles.items}>
      {getItems(group1, emptyPlaceholder, true)}
      {getItems(group2, emptyPlaceholder)}
      {layout === AccordionLayouts.Groups && getItems(items, emptyPlaceholder, false, layout)}
    </div>
  </div>
}

export default Accordion

export type {
  AccordionProps
}

export {
  AccordionLayouts
}

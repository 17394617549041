import type { FunctionComponent } from 'react'
import type { ScoreboardDistanceProps } from '../default'

import clsx from 'clsx'

import Caption from '../../../../atoms/caption'
import Icon from '../../../../atoms/icon'
import Paragraph from '../../../../atoms/paragraph'
import Title, { TitleSize } from '../../../../atoms/title'
import Score, { ScoreLayoutTypes } from '../../../../molecules/score'

import styles from './banner.module.scss'

const BannerDistanceBoard: FunctionComponent<ScoreboardDistanceProps> = (
  {
    sport,
    competitionName,
    title,
    date,
    status,
    type,
    typeIcon,
    weatherIcon,
    temperature,
    distance,
    distanceToGo,
    winners,
    label,
    startLabel,
    endLabel,
    darkMode
  }
) => {
  return <div className={clsx(
    styles.scoreboard,
    styles.distance,
    darkMode ? styles.darkMode : undefined
  )}>
    {title && <Caption className={styles.title}>{title}</Caption>}
    <Title darkMode={darkMode} size={TitleSize.XSmall} desktopSize={TitleSize.Medium}>{competitionName}</Title>
    <Score layout={ScoreLayoutTypes.Banner}
           sport={sport}
           startLabel={startLabel}
           endLabel={endLabel}
           distance={distance}
           distanceToGo={distanceToGo}
           status={status}
           date={date}
           darkMode={darkMode}
           label={label} />
    <div className={styles.list}>
      {type && <Paragraph darkMode={darkMode} className={styles.listItem}>{typeIcon && <Icon name={typeIcon} />}{type}</Paragraph>}
      {distance && <Paragraph darkMode={darkMode} className={styles.listItem}><Icon name={'cycling-distance'} />{distance} km</Paragraph>}
      {temperature && <Paragraph darkMode={darkMode} className={styles.listItem}>{weatherIcon && <Icon name={weatherIcon} />}{temperature} &deg;C</Paragraph>}
    </div>
    {winners &&
      <div className={styles.winners}>{winners.map(winner =>
        <Paragraph className={clsx(
          styles.winner,
          winner.position === 1 && styles.winnerFirst
        )} key={winner.name} darkMode={darkMode}>
          {winner.position === 1 && <Icon name={'cycling-hotseat'}/>} {winner.position} {winner.name}
        </Paragraph>
      )}</div>
    }
  </div>
}

export default BannerDistanceBoard

export type {
  ScoreboardDistanceProps
}

enum Owner {
  NEUTRAL = 'NEUTRAL',
  HOME = 'HOME',
  AWAY = 'AWAY'
}

enum EventType {
  ARTICLE = 'ARTICLE',
  BASE = 'BASE',
  INSTAGRAM = 'INSTAGRAM',
  MEDIAPLAYER = 'MEDIAPLAYER',
  PLAYER = 'PLAYER',
  QUOTE = 'QUOTE',
  SCOREBOARD = 'SCOREBOARD',
  SEPARATOR = 'SEPARATOR',
  SUBSTITUTION = 'SUBSTITUTION',
  THREADS = 'THREADS',
  TWITTER = 'TWITTER',
  QUALIFIO = 'QUALIFIO',
}

enum TimelineMode {
  Default = 'default',
  Teaser = 'teaser'
}

enum TimelineFilterType {
  Default = 'default',
  Highlighted = 'highlighted',
}

export {
  Owner,
  EventType,
  TimelineMode,
  TimelineFilterType
}

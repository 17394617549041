import type { ClassValue } from 'clsx'
import type { FunctionComponent } from 'react'

import clsx from 'clsx'
import { useEffect, useState } from 'react'

import styles from './embed.module.scss'

interface EmbedProps {
  className?: ClassValue
  embedCode?: string
}

const Embed: FunctionComponent<EmbedProps> = (
  {
    className,
    embedCode
  }
) => {
  const [embeddedContent, setEmbeddedContent] = useState(embedCode)

  useEffect(() => {
    embedCode && setEmbeddedContent(embedCode)
  }, [embedCode])

  return embeddedContent
    ? <div
      className={clsx(
        styles.embed,
        className
      )}
      dangerouslySetInnerHTML={{ __html: embeddedContent }}
    />
    : null
}

export default Embed

export type {
  EmbedProps
}

import type { FunctionComponent } from 'react'
import type { StoryCardComponentProps } from '../../story-card'

import { Theme } from '@sporza/config'
import clsx from 'clsx'
import { useRef, useState } from 'react'

import Title, { TitleSize } from '../../../atoms/title'
import { Advertisement } from '../../../molecules/advertisement'
import Cell from '../../cell'
import Grid from '../../grid'
import { SectionLayout } from '../../section'
import Section from '../../section/section'
import SpotlightArticles from '../partials/articles'
import SpotlightImages from '../partials/images'
import styles from '../spotlight.module.scss'

interface SpotlightDefaultProps {
  title?: string
  items: StoryCardComponentProps[]
  analyticsId?: string
  theme?: Theme
}

const SpotlightDefault: FunctionComponent<SpotlightDefaultProps> = (
  {
    title,
    items,
    analyticsId,
    theme
  }
) => {
  const id = 'spotlight_title'
  const [active, setActive] = useState<number>(0)

  const ref = useRef(null)

  const ebaData = {
    listAnalyticsId: analyticsId,
    componentType: 'spotlight',
    listTitle: title,
    listElementCount: items?.length,
    listType: 'grid'
  }

  const titleElement = title
    && <Title
      id={id}
      className={styles.title}
      size={TitleSize.Large}
    >{title}</Title>

  const itemsWithoutText = (items: StoryCardComponentProps[]) => {
    return items.map((item: any, index) => {
      return {
        ...item,
        componentProps: {
          ...item.componentProps,
          className: index === 0 ? styles.firstItem : undefined,
          text: undefined
        }
      }
    })
  }

  const spotlightItems = items.slice(0, 3)
  const otherItems = itemsWithoutText(items).slice(3, 15)

  return <div ref={ref} className={clsx(
    styles.spotlightWrapper,
    theme === Theme.OlympischeSpelen2024 && styles.themeOs2024,
    theme === Theme.ParalympischeSpelen2024 && styles.themeOs2024,
  )}>
    <Grid className={styles.sections} collapseX={true}>
      <Cell size={4}>
        {titleElement}
      </Cell>
      <Cell size={3}>
        <div className={styles.spotlight}>
          <SpotlightImages items={spotlightItems} activeIndex={active} />
          <SpotlightArticles
            items={spotlightItems}
            activeIndex={active}
            ebaData={ebaData}
          />
          <Section
            setActive={setActive}
            items={itemsWithoutText(spotlightItems)}
            layout={SectionLayout.Grid3Column}
            className={styles.spotlightSection}
            ebaData={ebaData}
            ariaLabelledBy={id}
          />
        </div>
      </Cell>
      <Cell size={1}>
        <Advertisement adType={'halfpage'} />
      </Cell>
    </Grid>
    <Grid className={styles.sections} collapseX={true}>
      <Section
        items={otherItems.slice(0, 12)}
        layout={SectionLayout.Grid4Column}
        ebaData={{
          ...ebaData,
          listElementOffset: 3
        }}
        ariaLabelledBy={id}
      />
    </Grid>
  </div>
}

export default SpotlightDefault

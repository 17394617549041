import type { FunctionComponent } from 'react'
import type { DataCardProps } from '../../index'

import clsx from 'clsx'

import Title, { TitleSize } from '../../../../atoms/title'
import DataList from '../../../../molecules/data-list'
import Teamname, { TeamnameSize } from '../../../../molecules/teamname'
import Card from '../../../card'
import styles from '../../data-card.module.scss'

const DataCardWithNumber: FunctionComponent<DataCardProps> = (
  {
    title,
    imageChildren,
    team,
    dataList
  }
) => {
  return <Card
    className={clsx(
      styles.dataCard,
      styles.dataCardWithNumber
  )}>
    {imageChildren}
    <div className={styles.content}>
      {title && <Title className={styles.floatTitle} size={TitleSize.Large} title={title}/>}
      {team && <Teamname className={styles.team} size={TeamnameSize.Large} team={team}/>}
    </div>
    {dataList && dataList[0] && dataList[0].map((item, index) => <DataList
      key={`${title}${index}`}
      className={styles.dataList}
      {...item.componentProps}
    />)}
</Card>
}

export {
  DataCardWithNumber
}

import type { VotingProps } from '../../voting'

import { useAuth, useInteractionService } from '@sporza/hooks'
import { getStorage } from '@sporza/utils/storage'
import clsx from 'clsx'
import { FunctionComponent, useEffect } from 'react'
import { useState } from 'react'

import { VotingPhase } from '../../config'

import styles from './eddies.module.scss'
import { EddiesLanding, EddiesResults, EddiesVoting } from './layouts'

interface EddiesProps extends VotingProps {
  formations?: string[]
}

const phaseComponents: Record<VotingPhase, any> = {
  [VotingPhase.landing]: EddiesLanding,
  [VotingPhase.countdown]: EddiesLanding,
  [VotingPhase.voting]: EddiesVoting,
  [VotingPhase.results]: EddiesResults
}

export const key = 'eddies_vote'

const Eddies: FunctionComponent<EddiesProps> = (
  {
    phase = VotingPhase.landing,
    ...props
  }
) => {
  const { isLoggedIn } = useAuth()
  const { vote, fetchVote, hasFetchedVote } = useInteractionService(props.votingBaseUrl, props.slug) || {}
  const [fetchingVote, setFetchingVote] = useState<boolean>(false)

  useEffect(() => {
    if (!fetchingVote && !hasFetchedVote && isLoggedIn && fetchVote) {
      fetchVote()
      setFetchingVote(true)
    }
  }, [hasFetchedVote, isLoggedIn, fetchVote])

  const [phaseSt, setPhaseSt] = useState<VotingPhase>(phase as VotingPhase)
  const [categories, setCategories] = useState(props.categories)
  const [voteSt, setVoteSt] = useState(props.vote)
  const Phase = phaseComponents[phaseSt]

  const handlePhaseChange = (newPhase: VotingPhase, cat?: any, vote?: any) => {
    setPhaseSt(newPhase)

    if (cat) {
      setCategories(cat)
    }

    if (vote) {
      const selectedCategories = categories.map((category: any) => {
        return {
          ...category,
          options: category.options.map((option: any) => {
            const selection = vote?.votings.find((vote: any) => vote.votingId === category.votingId)?.selection[0]
            const selected = selection.id === option.interactionOptionId

            return {
              ...option,
              selected
            }
          })
        }
      })

      setCategories(selectedCategories)
      setVoteSt(vote)
    }
  }

  useEffect(() => {
    if (vote) {
      setVoteSt(vote)
    }
  }, [vote])

  useEffect(() => {
    if (voteSt) {
      handlePhaseChange && handlePhaseChange(VotingPhase.results)
    }
  }, [voteSt])

  useEffect(() => {
    const storedCategories = getStorage(key)
    if (isLoggedIn && !vote && storedCategories && storedCategories.length > 0) {
      handlePhaseChange && handlePhaseChange(VotingPhase.voting, storedCategories)
    }
  }, [isLoggedIn, vote])

  useEffect(() => {
    if (!isLoggedIn) {
      setPhaseSt(VotingPhase.landing)
    } else {
      setPhaseSt(phase as VotingPhase)
    }
  }, [isLoggedIn])

  return <Phase
    {...props}
    vote={voteSt}
    phase={phaseSt}
    handlePhaseChange={handlePhaseChange}
    categories={categories}
    className={clsx(
      props.className,
      styles.eddies,
      phaseSt !== VotingPhase.voting && styles.background
    )}
  />
}

export {
  Eddies
}

export type {
  EddiesProps
}


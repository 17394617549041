import type { FunctionComponent, PropsWithChildren } from 'react'
import type { Icons } from '../../design-tokens/iconography/icons'

import clsx from 'clsx'

import Icon from '../../atoms/icon'

import styles from './tag.module.scss'

enum TagSize {
  Small = 'Small',
  Medium = 'Medium'
}

interface TagProps extends PropsWithChildren {
  text?: string
  size?: string | TagSize
  iconBefore?: Icons
  iconAfter?: Icons
  className?: string
  darkMode?: boolean
  mobileOnly?: boolean
  desktopOnly?: boolean
}

const Tag: FunctionComponent<TagProps> = (
  {
    text,
    size,
    children,
    iconBefore,
    iconAfter,
    className,
    darkMode,
    mobileOnly,
    desktopOnly
  }
) => {
  return <span className={clsx(
    styles.tag,
    size === TagSize.Medium && styles.tagMedium,
    className,
    darkMode && styles.tagDark,
    mobileOnly && styles.mobileOnly,
    desktopOnly && styles.desktopOnly
  )}>
    {iconBefore && <Icon name={iconBefore} darkMode={!!darkMode}/>}
    {text || children}
    {iconAfter && <Icon name={iconAfter} darkMode={!!darkMode}/>}
  </span>
}

export default Tag

export {
  TagSize
}

export type {
  TagProps
}

import type { FunctionComponent } from 'react'
import type { TagProps } from '../../molecules/tag'
import type { CardProps } from '../card'

import { useListClick, useListImpression } from '@sporza/tracking'
import clsx from 'clsx'
import { useRef } from 'react'

import Caption from '../../atoms/caption'
import Icon from '../../atoms/icon'
import Title from '../../atoms/title'
import Image, { ImageLayout } from '../../molecules/image'
import Tag from '../../molecules/tag'
import Card from '../card'
import List from '../list'

import styles from './podcast-program-card.module.scss'

interface PodcastProgramCardComponentProps {
  componentType: string
  componentProps: PodcastProgramCardProps
}

interface PodcastProgramCardProps extends CardProps {
  title?: string
  text?: string
  image?: string
  tags?: TagProps[]
}

const PodcastProgramCard: FunctionComponent<PodcastProgramCardProps> = (
  {
    link,
    target,
    title,
    text,
    image,
    tags,
    withHover = true,
    className,
    ebaData = {}
  }
) => {
  const ref = useRef(null)

  useListClick({
    ref,
    ...ebaData
  })

  useListImpression(
    {
      ref,
      ...ebaData
    }
  )

  return <Card
    link={link}
    target={target}
    className={clsx(
      styles.podcastProgramCard,
      className
    )}
    withHover={withHover}
    innerRef={ref}
  >
    <div className={styles.imageContainer}>
      <Icon
        name="audio"
        className={styles.imageIcon}
        withBackground={true}
      />
      <Image
        src={image}
        alt={title}
        layout={ImageLayout.Square}
        className={styles.image}
      />
    </div>
    <div className={styles.content}>
      <Title size={'Small'} darkMode={true}>{title}</Title>
      <List className={styles.tags}>
        {tags?.map((tag:any) =>
          <Tag key={tag.text} {...tag} />
        )}
      </List>
      <Caption className={styles.text}>{text}</Caption>
    </div>
  </Card>
}

export {
  PodcastProgramCard
}

export type {
  PodcastProgramCardProps,
  PodcastProgramCardComponentProps
}

import type { FunctionComponent } from 'react'

import clsx from 'clsx'
import { useEffect, useState } from 'react'

import Title, { TitleElement } from '../../../atoms/title'
import Button from '../../../molecules/button'
import { DrawItemType } from '../config'

import { ResultItem } from './index'
import styles from './result-groups.module.scss'

interface ResultGroupProps {
  type?: DrawItemType
  name?: string
  objects?: any[]
  availableSlots?: number
  sport?: string
  open?: boolean
}

function fillGroups(groups: any[] = [], availableSlots?: number) {
  if (!availableSlots)
    return groups

  const existingPositions = new Set(groups.map(obj => obj.position))
  let emptyPosition = 0

  while (groups.length < availableSlots) {
    while (existingPositions.has(emptyPosition)) {
      emptyPosition++
    }

    groups.push({ position: emptyPosition, name: '---' })
    existingPositions.add(emptyPosition)
  }

  groups.sort((a, b) => a.position - b.position)

  return groups
}

const ResultGroup: FunctionComponent<ResultGroupProps> = (
  {
    type,
    name,
    objects,
    availableSlots,
    sport,
    open,
  }
) => {
  const [init, setInit] = useState<boolean>(false)
  const [active, setActive] = useState<boolean>(false)
  const [groups, setGroups] = useState(fillGroups(objects, availableSlots))
  const [hasNewItem, setHasNewItem] = useState<boolean>(false)
  const [previousLength, setPreviousLength] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (!init) {
      setInit(true)
    }
  }, [])

  useEffect(() => {
    setActive(open || false)
  }, [open])

  useEffect(() => {
    if (!objects || !init)
      return

    const currentLength = objects
      .filter((obj: any) => obj.name !== '---')
      .length

    if (previousLength === undefined) {
      setPreviousLength(currentLength)
    } else if (currentLength > previousLength) {
      setHasNewItem(true)
    }

    setPreviousLength(currentLength || 0)

    const timerId = setTimeout(() => {
      setHasNewItem(false)
    }, 1000)

    setGroups(fillGroups(objects, availableSlots))

    return () => clearTimeout(timerId)
  }, [objects, availableSlots])

  return <div
    key={name}
    className={clsx(
      styles.group,
      hasNewItem && styles.hasNewGroupItem
    )}
  >
    <Button
      iconAfter="chevron-down"
      variant={'tertiary'}
      className={clsx(
        styles.resultGroupTitle,
        active && styles.active
      )}
      onClick={() => setActive(!active)}
    >
      <Title
        className={styles.resultGroup}
        size="Small"
        tag={TitleElement.H3}
      >{name}</Title>

      {
        !active
        && <div className={styles.teaserResults}>
          {
            groups?.map((group: any, index  ) =>
              <ResultItem
                key={`teaser-${group.name}-${index}`}
                {...group}
                type={type}
                sport={sport}
                logoOnly={true}
              />
            )
          }
        </div>
      }
    </Button>

    <div className={clsx(
      styles.content,
      active && styles.contentIsActive
    )}>
      {
        groups?.map((group: any, index) =>
          <ResultItem
            key={`${group.name}-${index}`}
            {...group}
            type={type}
            sport={sport}
          />
        )
      }
    </div>
  </div>
}

export {
  ResultGroup
}

export type {
  ResultGroupProps
}

import type { FunctionComponent, PropsWithChildren } from 'react'

import { mapToComponent } from '@sporza/services/component-mapper'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

import Title, { TitleSize } from '../../atoms/title'
import Button, { ButtonVariant } from '../../molecules/button'

import { AccordionLayouts } from './accordion'
import AccordionGroupItem from './accordion-group-item'
import styles from './accordion-item.module.scss'

interface AccordionItemProps extends PropsWithChildren {
  open?: boolean
  label?: string
  labelImage?: string
  content?: any
  layout?: string
  time?: string
  groupAbbreviation?: string
}

const AccordionItem: FunctionComponent<AccordionItemProps> = (
  {
    open,
    label,
    labelImage,
    content,
    layout,
    time,
    groupAbbreviation,
  }
) => {
  const [active, setActive] = useState<boolean>(false)

  useEffect(() => {
    if (open) {
      setActive(open)
    }
  }, [open])

  return <div className={clsx(
    styles.accordionItem,
    layout === AccordionLayouts.Groups && styles.accordionGroupItem,
    groupAbbreviation === 'P' && styles.accordionGroupItemPeloton
  )}>
    <Button
      variant={ButtonVariant.tertiary}
      onClick={() => setActive(!active)}
      className={clsx(
        styles.label,
        active && styles.active
      )}
      imageBefore={labelImage}
      iconAfter={!content?.componentProps?.data?.length && !content?.data?.length ? undefined : 'chevron-down'}
    >

      {
        layout === AccordionLayouts.Groups
          ? <AccordionGroupItem
            time={time}
            groupAbbreviation={groupAbbreviation}
            label={label}
            active={active}
            content={content}
          />
          : <Title
            size={TitleSize.XSmall}
            className={styles.title}
          >
            {label}
          </Title>
      }
    </Button>
    <div className={clsx(
      styles.content,
      active && styles.contentIsActive
    )}>
      {
        active && content
          ? 'componentType' in content
            ? mapToComponent(content)
            : content
          : null
      }
    </div>
  </div>
}

export default AccordionItem

export type {
  AccordionItemProps
}


import type { FunctionComponent } from 'react'
import type { ScoreScoreProps } from '../default'

import DefaultScore from '../default/score'

const DetailedScore: FunctionComponent<ScoreScoreProps> = (
  {
    score,
    label
  }
) => {
  return <DefaultScore score={score} label={label} />
}

export default DetailedScore

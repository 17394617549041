import type { FunctionComponent } from 'react'

import { useAuth } from '@sporza/hooks'
import clsx from 'clsx'

import Caption from '../../../../../atoms/caption'
import Button, { ButtonVariant } from '../../../../../molecules/button'
import commonStyles from '../iedereen-bondscoach.module.scss'

const LoginButton: FunctionComponent = () => {
  const { isLoggedIn, name } = useAuth()

  return isLoggedIn
    ? <div className={commonStyles.footer}>
      <Caption>aangemelde bondscoach</Caption>

      <div>
        <Button
          className={clsx(
            commonStyles.ctaButton,
            commonStyles.loggedInButton
          )}
          iconBefore="clipboard"
          variant={ButtonVariant.live}
        >
          {name}
        </Button>
      </div>
    </div>
    : <div className={commonStyles.footer}>
      <div>
        <Button
          className={commonStyles.ctaButton}
          iconAfter="chevron-right"
          onClick={() => VRT?.SSOController?.manualLogin()}
        >
          aanmelden
        </Button>
      </div>

      <Caption>meld je alvast aan om deel te nemen</Caption>
    </div>
}

export {
  LoginButton
}

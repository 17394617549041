import type { CSSProperties, FunctionComponent } from 'react'
import type { BannerClickArguments } from '@sporza/tracking/events'

import { ThemeConfig } from '@sporza/config'
import { useBannerImpression } from '@sporza/tracking'
import { bannerClickEvent } from '@sporza/tracking/events'
import { getPathName } from '@sporza/utils/urls'
import { useRef } from 'react'

import styles from './play-banner.module.scss'

interface PlayBannerThemeConfig extends ThemeConfig {
  backgroundImageUrl?: string
  leftImageUrl?: string
  rightImageUrl?: string
  logoUrl?: string
  accentColor?: string
  texrtColor?: string
}

interface PlayBannerProps {
  themeConfig?: PlayBannerThemeConfig
  bannerTargetUrl?: string
  designSystemBaseUrl?: string
}

const PlayBanner: FunctionComponent<PlayBannerProps> = (
  {
    themeConfig,
    bannerTargetUrl,
    designSystemBaseUrl
  }
) => {
  const {
    backgroundImageUrl = `${designSystemBaseUrl}/images/play/play-sports-background.png`,
    leftImageUrl = `${designSystemBaseUrl}/images/play/play-sports-left.svg`,
    centerImageUrl = `${designSystemBaseUrl}/images/play/play-sports-center.svg`,
    rightImageUrl = `${designSystemBaseUrl}/images/play/play-sports-right.svg`
  } = themeConfig || {}

  const bannerRef = useRef(null)
  const bannerContext = {
    bannerTitle: 'play sports - één abonnement verandert alles',
    componentName: 'mediaplayer-banner',
    targetPageUrl: bannerTargetUrl,
    targetPageName: getPathName(bannerTargetUrl),
    targetPageId: bannerTargetUrl,
    bannerAdvertiser: 'Telenet Play'
  }

  const trackBannerClick = (bannerContext: any) => {
    bannerClickEvent(bannerContext as BannerClickArguments)

    return true
  }

  useBannerImpression(
    {
      ref: bannerRef,
      ...bannerContext
    }
  )

  return <a
    className={styles.playBanner}
    style={{ '--play-banner-background': `url("${backgroundImageUrl}")` } as CSSProperties}
    href={bannerTargetUrl}
    target="_blank"
    rel="noreferrer"
    ref={bannerRef}
    onClick={() => trackBannerClick(bannerContext)}
  >
    <div className={styles.left}>
      <img src={leftImageUrl} alt="één abonnement verandert alles"/>
    </div>
    <div className={styles.center}>
      <img src={centerImageUrl} alt="champions league + europa league + conference league"/>
    </div>
    <div className={styles.right}>
      <img src={rightImageUrl} alt="vanaf 21,95€ per maand"/>
    </div>
  </a>
}

export type {
  PlayBannerProps
}

export {
  PlayBanner
}

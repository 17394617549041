import type { FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import type { FilterProps } from '../../molecules/filter'

import clsx from 'clsx'
import { useEffect, useState } from 'react'

import Caption from '../../atoms/caption'
import Title, { TitleElement } from '../../atoms/title'
import Button from '../../molecules/button'
import Filter from '../../molecules/filter'
import { FilterPosition } from '../tab-section'

import styles from './tab.module.scss'

interface TabProps extends PropsWithChildren {
  title?: string
  titleBefore?: ReactNode
  items: TabOption[]
  align?: 'left' | 'right'
  emptyPlaceholder?: any
  darkMode?: boolean
  label?: string
  callback?: any
  activeIndex?: number
  showTitleAlways?: boolean
  filter?: FilterProps
  handleFilter?: any
  filterPosition?: FilterPosition
}

interface TabOption {
  label?: string
  content?: JSX.Element | any[]
  contentData?: any
  contentProps?: any
  onClick?: any
}

const Tab: FunctionComponent<TabProps> = (
  {
    title,
    titleBefore,
    items = [],
    align = 'left',
    darkMode,
    emptyPlaceholder,
    label,
    callback = () => void 0,
    activeIndex = 0,
    showTitleAlways = false,
    filter,
    handleFilter,
    filterPosition
  }
) => {
  const [active, setActive] = useState<number>(activeIndex)

  useEffect(() => {
    setActive(activeIndex)
  }, [items])

  const filteredItems = items.filter((item: TabOption) => item.content)

  const Buttons = () => {
    return (filteredItems?.length > 1 || true) && filteredItems.map((item: TabOption, index: number) =>
      <li
        key={`${index}${item.label}-label`}
        className={styles.tabButton}
      >
        <Button
          variant={'secondary'}
          darkMode={darkMode}
          active={active === index}
          onClick={() => {
            item.onClick && item.onClick()
            setActive(index)
            callback(index, item)
          }}
        >{item.label}</Button>
      </li>)
  }

  const Elements = () => {
    return filteredItems
      ?.map((item: TabOption, index: number) =>
        <div
          data-tab-active={active === index}
          key={`${index}${item.label}-element`}
          className={styles.tab}
          style={{
            width: `${100 / filteredItems.length}%`,
            maxHeight: `${active === index ? `${99999}px` : '0'}`
          }}>
          {item.content}
        </div>)
  }

  return <div className={clsx(
    styles.tabs
  )}>
    <header className={styles.header}>
      {(filteredItems.length >= 2 || filter || showTitleAlways) && <ul className={clsx(
        styles.list,
        align === 'right' && styles.right,
        filterPosition === FilterPosition.Left && styles.filterLeft
      )}>
        {
          title
          && (filteredItems.length >= 1 || showTitleAlways)
          && <li className={styles.titleItem}>
            {titleBefore}
            <Title size={'Large'} className={styles.title} tag={TitleElement.H2}>{title}</Title>
          </li>
        }
        {label && filteredItems.length >= 1 &&
          <li><Caption weight={'Medium'} className={styles.label}>{label}</Caption></li>}
        {filter && <li className={clsx(
          styles.filter
        )}><Filter onSelect={handleFilter} {...filter} /></li>}
        {Buttons()}
      </ul>}
    </header>
    <div className={styles.container}>
      {filteredItems.length > 0 ?
        <div className={styles.wrapper} style={{
          width: `${filteredItems.length * 100}%`,
          transform: `translateX(-${active * 100 / filteredItems.length}%)`
        }}>
          {Elements()}
        </div> : emptyPlaceholder
      }
    </div>
  </div>
}

export default Tab

export type {
  TabProps,
  TabOption
}

import clsx from 'clsx'
import { FunctionComponent, HTMLAttributes, useState } from 'react'

import Icon from '../../../../../atoms/icon'
import Paragraph from '../../../../../atoms/paragraph'
import Title, { TitleSize } from '../../../../../atoms/title'
import Image, { ImageLayout, ImageProps } from '../../../../../molecules/image'
import Mediaplayer, { MediaplayerProps } from '../../../../mediaplayer'

import styles from './option.module.scss'

interface OptionOptionProps {
  title: string
  subtitle: string
  shortTitle: string
  description?: string
  interactionOptionId?: string
  display: MediaplayerProps | ImageProps | any
  selected?: boolean
}

interface OptionProps extends HTMLAttributes<HTMLDivElement> {
  option: OptionOptionProps
  toggleOption?: (option: OptionOptionProps) => void
  id?: string
  aggregatorUrl?: string
}

enum DisplayType {
  Video = 'VIDEO',
  Image = 'IMAGE'
}

const Option: FunctionComponent<OptionProps> = (
  {
    style,
    option,
    className,
    toggleOption,
    aggregatorUrl
  }
) => {
  const {
    subtitle,
    title,
    display = {},
    description,
    selected
  } = option || {}

  const [hover, setHover] = useState(false)

  const { type } = display as any

  const interactionHandler = {
    onClick: () => toggleOption && toggleOption(option),
    onMouseOver: () => setHover(true),
    onMouseOut: () => setHover(false),
  }
  const checked = selected

  return <div
    style={style}
    className={clsx(
      styles.option,
      hover ? styles.isHover : '',
      selected ? styles.isSelected : '',
      selected === false ? styles.isNotSelected : '',
      className
    )}
  >
    <div className={styles.media}>
      {selected ?
        <div className={styles.isSelectedIcon}>
          <Icon name="check-alt"/>
        </div>
        : null}

      {type === DisplayType.Image ?
        <div className={styles.image}
          {...interactionHandler}>
          <Image
            src={display.url}
            profiles={[{ name: 'w960hx', view: '100w' }]}
            layout={ImageLayout.Portrait9x16}
            fallbackImage={'https://design-system.sporza.be/images/placeholders/player.svg'}
            priority={true}
          />
        </div>
        : <div className={styles.video}>
            <Mediaplayer
              showTitle={false}
              aggregatorUrl={aggregatorUrl}
              mediaReferences={[display.externalVideoId]}
              posterImageUrl={display.posterImageUrl} />
            {/*<div {...interactionHandler} className={styles.videoDescription}>{display.title}</div>*/}
        </div>}
    </div>
    <a
      aria-label={`kies ${title} ${subtitle}, ${description}`}
      aria-selected={selected}
      className={styles.content}
      {...interactionHandler}
      >
      <Title size={TitleSize.XSmall}><Icon className={clsx(
        styles.checkIcon,
        checked || (selected === undefined && hover) ? styles.checked : undefined
      )} name={checked || (selected === undefined && hover) ? 'check-on-solid' : 'check-off'} /> {title} {subtitle}</Title>
      <Paragraph className={styles.description}>{description}</Paragraph>
    </a>
  </div>
}
Option.displayName = 'Option'

export {
  Option,
  DisplayType
}

export type {
  OptionProps,
  OptionOptionProps
}


import type { FunctionComponent, PropsWithChildren, ReactElement, ReactNode } from 'react'
import type { Icons } from '../../../design-tokens/iconography/icons'

import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import { Children, cloneElement, useState } from 'react'
import SwiperCore from 'swiper'
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import Button from '../../../molecules/button'
import { NavigationIcons } from '../../section/layouts/slider'

import styles from './slider.module.scss'

type ImageCarouselSliderProps = PropsWithChildren

const ImageCarouselSlider: FunctionComponent<ImageCarouselSliderProps> = (
  {
    children
  }
) => {
  const [controlledSwiper, setControlledSwiper] = useState<SwiperCore>()
  const [prevIcon, setPrevIcon] = useState<Icons>(NavigationIcons.LeftDisabled)
  const [nextIcon, setNextIcon] = useState<Icons>(NavigationIcons.Right)

  const actionButtons = () => <div className={styles.actionButtons}>
    <Button
      variant="tertiary"
      iconBefore={prevIcon}
      onClick={() => controlledSwiper?.slidePrev()}
      darkMode={true}
    />

    <Button
      variant="tertiary"
      iconBefore={nextIcon}
      onClick={() => controlledSwiper?.slideNext()}
      darkMode={true}
    />
  </div>

  return <Swiper
    loop={true}
    effect={'coverflow'}
    coverflowEffect={{
      rotate: 0,
      stretch: 1200,
      depth: 500,
      scale: .7,
      modifier: .2,
      slideShadows: false,
    }}
    modules={[Navigation, Pagination, EffectCoverflow]}
    grabCursor={true}
    centeredSlides={true}
    spaceBetween={0}
    parallax={true}
    slidesPerView={'auto'}
    pagination={{
      type: 'fraction'
    }}
    onSwiper={(swiper) => setControlledSwiper(swiper)}
    onRealIndexChange={() => {
      Children
        .toArray(children)
        .forEach((item) => {
          item.props?.innerRef?.current?.firstChild?.api?.pause()
        })
    }}
    className={styles.slider}
  >
    {
      Children
        .toArray(children as ReactNode)
        .map((item) =>
          <SwiperSlide
            key={`slide-${(item as ReactElement).key}`}>
            {cloneElement(item as ReactElement)}
          </SwiperSlide>
        )
    }
    <div className={styles.bottom}>
      <div className={styles.scrollbarNavigation}>
        {actionButtons()}
      </div>
    </div>
  </Swiper>
}

export type {
  ImageCarouselSliderProps
}

export {
  ImageCarouselSlider
}

import type { FunctionComponent } from 'react'

import Icon from '../../../atoms/icon'
import Button from '../../../molecules/button'
import styles from '../mediaplayer.module.scss'

interface SimplePlayerProps {
  playerRef: any
  title?: string,
  playing: boolean,
  setPlaying: (playing: boolean) => void,
  muted: boolean,
  setMuted: (muted: boolean) => void,
}

const SimplePlayer: FunctionComponent<SimplePlayerProps> = (
  {
    playerRef,
    title,
    playing,
    setPlaying,
    muted,
    setMuted,
  }
) => <div className={styles.simplePlayer}>
  <Button darkMode={false} iconBefore={playing ? 'pause-video-solid' : 'play-video-solid'} onClick={() => {
    playerRef.current.children[0]?.api?.togglePlay()
    setPlaying(!playing)
  }}>
    {title || 'luister live'}
  </Button>
  <button className={styles.pauseButton} onClick={() => {
    playerRef.current.children[0]?.api?.toggleMute()
    setMuted(!muted)
  }}>
    <Icon className={styles.pauseIcon} name={muted ? 'volume-mute-solid' : 'volume-on-solid'}/>
  </button>
</div>

export {
  SimplePlayer
}

import type { FunctionComponent } from 'react'
import type { CountdownApi, CountdownProps as CWProps } from 'react-countdown'

import { useEffect, useState } from 'react'
// react-countdown has bad support for es module so we need to import it like this
import { default as CountdownComponentModule } from 'react-countdown'

import BaseCountdown from './layouts/base'
import styles from './countdown.module.scss'

// Weird bug where CountdownComponentModule.default is the component on serverside rendering but not on clientside rendering
const CountdownComponent = CountdownComponentModule.default || CountdownComponentModule

interface CountdownProps extends Omit<CWProps, 'now'> {
  title?: string
  now?: number
  forceStart?: boolean
  className?: string
  darkMode?: boolean
  freeze?: boolean
}

const Countdown: FunctionComponent<CountdownProps> = (
  {
    title,
    date = Date.now() + 1000 * 60 * 60,
    now,
    forceStart,
    onComplete,
    className,
    darkMode,
    freeze
  }
) => {
  const nowTime = now || Date.now()
  const [countdownApi, setCountdownApi] = useState<CountdownApi | null>(null)

  const setRef = (countdown: CountdownComponent | null): void => {
    if (countdown) {
      setCountdownApi(countdown.getApi())
    }
  }

  useEffect(() => {
    if (countdownApi && freeze) {
      countdownApi.stop()
    }
  }, [freeze, countdownApi])

  return <div className={className}>
    <CountdownComponent
      ref={setRef}
      className={styles.countdown}
      date={date}
      now={forceStart ? () => nowTime : undefined}
      renderer={(props) => BaseCountdown({ ...props })}
      onComplete={onComplete}
    />
  </div>
}

export default Countdown

export type {
  CountdownProps
}

import type { FunctionComponent } from 'react'

import clsx from 'clsx'
import { useEffect, useState } from 'react'

import Teamname, { TeamnameSize } from '../../../molecules/teamname'
import { DrawItemType } from '../index'

import styles from './result-item.module.scss'

interface ResultItemProps {
  startingGroup?: string
  type?: DrawItemType
  name?: string
  shortName?: string
  country?: string
  countryShort?: string
  logoUrl?: string
  logoOnly?: boolean
  url?: string
  sport?: string
  align?: string
}

const ResultItem: FunctionComponent<ResultItemProps> = (
  {
    type = DrawItemType.Team,
    name = '',
    shortName = '',
    country,
    countryShort,
    logoUrl,
    url,
    sport,
    logoOnly = false,
    align
  }
) => {
  const [isNewItem, setIsNewItem] = useState<boolean>(false)
  const [previousLogoUrl, setPreviousLogoUrl] = useState<string | undefined>(undefined)

  const isBelgian = shortName === 'BEL' // only national team for now, if belgian team is needed use countryShort === 'BEL'

  useEffect(() => {
    if (logoUrl !== previousLogoUrl) {
      setIsNewItem(true)
    }

    setPreviousLogoUrl(logoUrl || 'default')

    const timerId = setTimeout(() => {
      setIsNewItem(false)
    }, 1000)

    return () => clearTimeout(timerId)
  }, [])

  const drawItemLayouts = {
    [DrawItemType.Team]: <Teamname
      team={{
        name: logoOnly ? undefined : name,
        shortName: logoOnly ? undefined :shortName,
        logoUrl: isNewItem ? undefined : logoUrl,
        href: logoOnly ? undefined : url,
      }}
      size={TeamnameSize.Large}
      showLinks={true}
      sport={sport}
      imageIcon={logoUrl && isNewItem ? 'check-alt' : undefined}
      logoPosition={align === 'right' ? 'right' : 'left'}
      className={clsx(
        styles.logo,
        (!logoUrl || isNewItem) && styles.showFallbackLogo,
        logoUrl && isNewItem && styles.highlightLogo,
        name === '---' && styles.disabled
      )}
    />,
  }

  return <div className={clsx(
    styles.resultItem,
    isBelgian && styles.highlight
  )}>
    {drawItemLayouts[type] || drawItemLayouts[DrawItemType.Team]}
  </div>
}

export {
  ResultItem
}

export type {
  ResultItemProps
}

import type { FunctionComponent, ReactElement } from 'react'
import type { FilterOption } from '../../molecules/filter'
import type { MatchdayProps } from '../matchday'
import type { TabSectionProps } from '../tab-section'

import clsx from 'clsx'

import Title, { TitleElement, TitleSize } from '../../atoms/title'

import ResultDefault from './layout'
import styles from './result.module.scss'

enum ResultLayoutTypes {
  Default = 'default'
}

interface ResultProps {
  title?: string
  subTitle?: string
  link?: string
  options?: FilterOption[]
  layout?: ResultLayoutTypes
  matchday?: MatchdayProps
  ranking?: TabSectionProps
  isGrouped?: boolean
}

const Layout: FunctionComponent<ResultProps> = ({
  matchday,
  ranking,
  options,
  layout = ResultLayoutTypes.Default,
  isGrouped = false
}) => {
  const resultLayout: {[Key in ResultLayoutTypes]: ReactElement} = {
      'default': <ResultDefault matchday={matchday} ranking={ranking} options={options} isGrouped={isGrouped} />
  }

  return resultLayout[layout]
    ? resultLayout[layout]
    : null
}

const Result: FunctionComponent<ResultProps> = (
  props
) => {
  const { title, subTitle, link } = props

  const titleElement = title
    && <Title
      size={TitleSize.Large}
      link={link}
      tag={TitleElement.H2}
    >{title}</Title>

  const subTitleElement = subTitle
    && <Title
      size={TitleSize.XSmall}
    >{subTitle}</Title>

  return <div
    className={clsx(
      styles.resultWrapper
    )}>
    <div className={styles.titleWrapper}>
      <div>
        {titleElement}
        {subTitleElement}
      </div>
    </div>
    <Layout {...props} />
  </div>
}

export default Result

export type {
  ResultProps,
  ResultLayoutTypes
}

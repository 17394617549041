import { useIntersectionObserver } from '@sporza/hooks'
import clsx from 'clsx'
import { addHours, addMinutes, format, isBefore, isSameDay, isToday, isWithinInterval, setDefaultOptions } from 'date-fns'
import { nl } from 'date-fns/locale'
import React, { FunctionComponent, PropsWithChildren, useEffect, useRef, useState } from 'react'

import Button from '../button'
import Pill from '../pill'

import styles from './hourpicker.module.scss'
setDefaultOptions({ locale: nl })

interface HourpickerProps extends PropsWithChildren {
  setActive?: any
  setSportApiUrl?: any
  itemWidth?: number
  labels: Date[],
  date?: Date,
  now?: Date,
  sportApiUrl?: string
}

const CONFIG = {
  dayNameFormat: 'iiiiii',
  timeFormat: 'HH:mm',
  itemWidth: 10,
  amountOfMinutesToScroll: 60
}

const getOffsetPercentageByMinutes = (date: any, start: Date, end: Date) => {
  const diff = end.getTime() - start.getTime()
  const current = date.getTime() - start.getTime()
  return (current / diff) * 100
}

const Hourpicker: FunctionComponent<HourpickerProps> = (props) => {
  const ref = useRef(null)
  const entry = useIntersectionObserver(ref, { threshold: 0.1 })
  const isLiveVisible = !!entry?.isIntersecting

  const { now = new Date() } = props

  const {
    setActive = () => void 0,
    setSportApiUrl = () => void 0,
    itemWidth = CONFIG.itemWidth,
    labels,
    date = now,
    sportApiUrl
  } = props

  const start = new Date(format(date, 'yyyy-MM-dd 00:00:00'))
  const end = new Date(format(date, 'yyyy-MM-dd 23:59:59'))

  const [current, setCurrent] = useState<Date>(now)

  const handleNext = () => {
    const newDate = addMinutes(date, CONFIG.amountOfMinutesToScroll)
    if (isWithinInterval(newDate, { start, end: addHours(end, -2) })) {
      setActive(newDate)
    }
  }

  const handlePrevious = () => {
    const newDate = addMinutes(date, -CONFIG.amountOfMinutesToScroll)
    if (isWithinInterval(newDate, { start, end })) {
      setActive(newDate)
    }
  }

  // live ticker
  useEffect(() => {
    setInterval( () => {
      setCurrent(now)
    }, 1000 * 60)
  }, [])

  const handlePillClick = () => {
    if (isToday(date)) setActive(now)
    // else setSportApiUrl(`${sportApiUrl}?date=${format(now, 'yyyy-MM-dd')}`)
  }

  const liveNavigationElement = () => {
    switch (true) {
      case !isLiveVisible && date && isBefore(current, date) && isSameDay(current, date):
        return <span className={clsx(styles.live, styles.liveLeft)}>
          <Pill onClick={handlePillClick} iconBefore={'chevron-left'}>{format(now, CONFIG.timeFormat)}</Pill>
        </span>
      case !isLiveVisible && date && isBefore(date, current) && isSameDay(current, date):
        return <span className={clsx(styles.live, styles.liveRight)}>
          <Pill onClick={handlePillClick} iconAfter={'chevron-right'}>{format(now, CONFIG.timeFormat)}</Pill>
        </span>
    }
  }

  return <div className={styles.hourpicker}>
    <span className={styles.navigationLeft}>
      <Button variant={'tertiary'} iconBefore={'chevron-left'} onClick={() => handlePrevious()}/>
    </span>
    <span className={styles.navigationRight}>
      <Button variant={'tertiary'} iconBefore={'chevron-right'} onClick={() => handleNext()} />
    </span>
    <ol className={styles.items} style={{
      width: itemWidth * labels.length + 'rem',
      transform: `translateX(-${getOffsetPercentageByMinutes(date, start, end)}%) translateX(-${itemWidth / 2}rem)`,
      left: '50%',
      transition: 'transform .2s ease-in-out'
    }}>
      {labels.map((item, index) => {
        const displayTime = format(item, CONFIG.timeFormat)
        return <li
          key={`${index}-${item}`}
          style={{ flex: `0 1 ${itemWidth}rem` }}
          onClick={() => setActive(item)} className={clsx(
          styles.item
        )}
        >
          <time dateTime={item.toISOString()} className={styles.time}>
            <span>{displayTime}</span>
          </time>
        </li>
      })}
      <li
        ref={ref}
        className={clsx(
          styles.live,
          styles.stripe
        )}
        style={{
          width: `${itemWidth}rem`,
          left: `${getOffsetPercentageByMinutes(current, start, end)}%`
        }}
      >
        <Pill>{format(now, CONFIG.timeFormat)}</Pill>
      </li>
    </ol>
    {liveNavigationElement()}
  </div>
}

export default Hourpicker

export type {
  HourpickerProps
}

export {
  getOffsetPercentageByMinutes
}

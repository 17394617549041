import type { FunctionComponent } from 'react'
import type { StoryCardProps } from '../story-card'

import { Theme } from '@sporza/config'

import SpotlightDefault from './layouts'

interface SpotlightProps {
  title?: string
  items: StoryCardProps[]
  theme?: Theme
}

const Spotlight: FunctionComponent<SpotlightProps> = (
  {
    title,
    items,
    theme
  }
) => {

  return items?.length
    ? <SpotlightDefault
      title={title}
      items={items}
      theme={theme}
    />
    : null
}

export default Spotlight

export type {
  SpotlightProps
}

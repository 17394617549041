import type { StoryCardProps } from '../../story-card'
import type { SectionItem, SectionProps } from '../section'

import { TitleSize } from '../../../atoms/title'
import { Breakpoints } from '../../../design-tokens/breakpoints'
import { ImageLayout } from '../../../molecules/image'
import { StoryCardLayout } from '../../story-card'
import { SectionLayout } from '../config'
import SectionHybrid from '../layouts/hybrid'
import SectionStatic from '../layouts/section-grid'
import SectionSlider from '../layouts/slider'

const layoutToDefaultImageLayout = (layout: SectionProps['layout']) => {
  switch (layout) {
    case SectionLayout.Grid2ColumnSquare:
    case SectionLayout.Grid5ColumnSquare:
      return ImageLayout.Square
    default:
      return ImageLayout.Widescreen
  }
}

const getImageLayout = (
  item: StoryCardProps,
  layout: SectionProps['layout'],
  imageLayout: SectionProps['imageLayout']
) =>
  item.imageLayout
    ? item.imageLayout
    : imageLayout
      ? imageLayout
      : layoutToDefaultImageLayout(layout)

const layoutToDefaultItemLayout = (index: number, layout: SectionProps['layout']) => {
  switch (true) {
    case layout === SectionLayout.Grid5Column:
    case layout === SectionLayout.Grid5ColumnSquare:
    case layout === SectionLayout.Grid4ColumnSlider:
    case layout === SectionLayout.Grid4ColumnSliderBottom:
    case layout === SectionLayout.Grid3Column && index === 0:
    case layout === SectionLayout.Grid3ColumnSlider:
    case layout === SectionLayout.Grid3x3Column && index === 0:
    case layout === SectionLayout.Grid3x3ColumnAds && index === 0:
    case layout === SectionLayout.Grid3x4Column && index === 0:
    case layout === SectionLayout.Grid2x4ColumnMatches && index === 0:
      return StoryCardLayout.Vertical
    case layout === SectionLayout.Grid4ColumnSliderOnMobile4Rows:
    case layout === SectionLayout.Grid3ColumnOnMobileSlider:
    case layout === SectionLayout.Grid2Column:
    case layout === SectionLayout.Grid2ColumnSquare:
    case layout === SectionLayout.Grid2ColumnOnMobileSlider:
    case layout === SectionLayout.Grid1ColumnOverflow:
    case layout === SectionLayout.Grid1Column:
    default:
      return StoryCardLayout.Horizontal
  }
}

const layoutToDefaultItemDesktopLayout = (index: number, layout: SectionProps['layout']) => {
  switch (true) {
    case layout === SectionLayout.Grid5Column:
    case layout === SectionLayout.Grid5ColumnSquare:
    case layout === SectionLayout.Grid4ColumnSlider:
    case layout === SectionLayout.Grid4ColumnSliderBottom:
    case layout === SectionLayout.Grid4ColumnSliderOnMobile4Rows:
    case layout === SectionLayout.Grid3Column:
    case layout === SectionLayout.Grid3ColumnSlider:
    case layout === SectionLayout.Grid3ColumnOnMobileSlider:
    case layout === SectionLayout.Grid2ColumnOnMobileSlider:
    case layout === SectionLayout.Grid3x3Column && index === 0:
    case layout === SectionLayout.Grid3x3ColumnAds && index === 0:
    case layout === SectionLayout.Grid3x4Column && index === 0:
    case layout === SectionLayout.Grid2x4ColumnMatches && index === 0:
      return StoryCardLayout.Vertical
    case layout === SectionLayout.Grid2Column:
    case layout === SectionLayout.Grid2ColumnSquare:
    case layout === SectionLayout.Grid1ColumnOverflow:
    case layout === SectionLayout.Grid1Column:
    default:
      return StoryCardLayout.Horizontal
  }
}

const getItemLayout = (index: number, item: StoryCardProps, layout: SectionProps['layout']) =>
  item.layout
    ? item.layout
    : layoutToDefaultItemLayout(index, layout)

const getItemDesktopLayout = (index: number, item: StoryCardProps, layout: SectionProps['layout']) =>
  item.layout
    ? item.layout
    : layoutToDefaultItemDesktopLayout(index, layout)


const getTitleSize = (index: number, layout: SectionProps['layout'], desktop = false) => {
  switch (true) {
    case SectionLayout.Grid3x3Column === layout && index === 0:
    case SectionLayout.Grid3x3ColumnAds === layout && index === 0:
    case SectionLayout.Grid2x4ColumnMatches === layout && index === 0:
      return desktop ? TitleSize.Large : TitleSize.Medium
    default:
      return undefined
  }
}

const getSectionElementTag = (layout: SectionProps['layout']) => {
  switch (layout) {
    case SectionLayout.Grid4ColumnSliderOnMobile4Rows:
    case SectionLayout.Grid3ColumnOnMobileSlider:
    case SectionLayout.Grid2ColumnOnMobileSlider:
      return SectionHybrid
    case SectionLayout.Grid4ColumnSlider:
    case SectionLayout.Grid4ColumnSliderBottom:
    case SectionLayout.Grid3ColumnSlider:
    case SectionLayout.Grid5ColumnSlider:
    case SectionLayout.Grid6ColumnSlider:
    case SectionLayout.Grid11ColumnSlider:
      return SectionSlider
    case SectionLayout.Grid2Column:
    case SectionLayout.Grid2ColumnSquare:
    case SectionLayout.Grid3Column:
    case SectionLayout.Grid4Column:
    case SectionLayout.Grid5Column:
    case SectionLayout.Grid5ColumnSquare:
    case SectionLayout.Grid3x3Column:
    case SectionLayout.Grid3x3ColumnAds:
    case SectionLayout.Grid3x4Column:
    case SectionLayout.Grid2x4ColumnMatches:
    default:
      return SectionStatic
  }
}

const getHybridOptions = (layout: SectionProps['layout'], items = 0) => {
  switch (layout) {
    case SectionLayout.Grid4ColumnSliderOnMobile4Rows:
      return {
        [Breakpoints.small]: {
          tag: SectionStatic,
          props: {
            layout: SectionLayout.Grid1Column,
            gridCssModuleClassNames: [
              `grid${SectionLayout.Grid4Column}FallbackOnMedium`
            ],
            isExpandable: true
          }
        },
        [Breakpoints.medium]: {
          tag: SectionSlider,
          props: {
            layout: SectionLayout.Grid4ColumnSlider
          }
        }
      }
    case SectionLayout.Grid3ColumnOnMobileSlider:
      return {
        [Breakpoints.small]: {
          tag: SectionSlider,
          props: {
            layout: SectionLayout.Grid3ColumnSlider,
            gridCssModuleClassNames: items > 3
              ? [
                `grid${SectionLayout.Grid3Column}FallbackOnMedium`
              ]
              : null
          }
        },
        [Breakpoints.medium]: {
          tag: items > 3 ? SectionSlider : SectionStatic,
          props: {
            layout: items > 3 ? SectionLayout.Grid3ColumnSlider : SectionLayout.Grid3Column
          }
        }
      }
    case SectionLayout.Grid2ColumnOnMobileSlider:
      return {
        [Breakpoints.small]: {
          tag: SectionSlider,
          props: {
            layout: SectionLayout.Grid3ColumnSlider
          }
        },
        [Breakpoints.medium]: {
          tag: SectionStatic,
          props: {
            layout: SectionLayout.Grid2Column
          }
        }
      }
    default:
      return undefined
  }
}

export {
  getHybridOptions,
  getImageLayout,
  getItemDesktopLayout,
  getItemLayout,
  getSectionElementTag,
  getTitleSize,
  layoutToDefaultImageLayout,
  layoutToDefaultItemDesktopLayout,
  layoutToDefaultItemLayout
}

export * from './media-cards'

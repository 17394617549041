import type { FunctionComponent } from 'react'
import type { ScoreScoreProps } from '../default'

import Caption from '../../../../atoms/caption'
import Meta from '../../../../atoms/meta'
import Title from '../../../../atoms/title'
import styles from '../../score.module.scss'

const BannerScore: FunctionComponent<ScoreScoreProps> = (
  {
    score,
    label,
    date,
    title
  }
) => {
  return <>
    {title && <Caption className={styles.title}>{title}</Caption>}
    {date && <Title className={styles.date} size={'Small'}>{date}</Title>}
    {score && <div className={styles.numbers}>
      <>
        {score?.home}
        {score?.shootout?.home !== undefined && <sup className={styles.shootout}>{score?.shootout?.home}</sup>}
      </>
      <Meta className={styles.dash}>-</Meta>
      <>
        {score?.away}
        {score?.shootout?.away !== undefined  && <sup className={styles.shootout}>{score?.shootout?.away}</sup>}
      </>
    </div>}
    {label && <Caption className={styles.label}>{label}</Caption>}
  </>
}

export default BannerScore

import type { FunctionComponent } from 'react'
import type { ScoreSetsProps } from '../default'

import clsx from 'clsx'

import Caption from '../../../../atoms/caption'
import Paragraph from '../../../../atoms/paragraph'
import Title, { TitleSize } from '../../../../atoms/title'
import styles from '../../score.module.scss'

const DetailedSets: FunctionComponent<ScoreSetsProps> = (
  {
    sets,
    label,
    sport
  }
) => {
  const setLabel = sport === 'basketball' ? 'q' : <>set&nbsp;</>

  return <>
    {label && <Paragraph className={styles.tennisLabel}>{label}</Paragraph>}
    {sets?.map((set, index: number) => <span className={clsx(
        styles.set,
        set.label === 'score' && styles.darker,
        set.label === 'einde' && styles.darker,
        set.label === 'fouten' && styles.fouls,
        set.label !== 'fouten' && set.label !== 'score' && index === sets?.length-1 && styles.setStyle,
        sets[index+1]?.label === 'fouten' && styles.setStyle,
        sets[index+1]?.label === 'score' && styles.setStyle,
      )} key={index}>
      <Title desktopSize={TitleSize.Medium} size={TitleSize.Small} className={styles.setNumber}>
        {set?.home}
        {set?.tiebreak && <sup className={styles.tiebreak}>
          {set?.tiebreak && set?.tiebreak?.home}
        </sup>}
      </Title>
      <Caption className={styles.number}>
        {set?.number ? <><span className={styles.setLabel}>{setLabel}</span>{set?.number}</> : set?.label}
      </Caption>
      <Title desktopSize={TitleSize.Medium} size={TitleSize.Small} className={styles.setNumber}>
        {set?.away}
        {set?.tiebreak && <sup className={styles.tiebreak}>
          {set?.tiebreak && set?.tiebreak?.away}
        </sup>}
      </Title>
    </span>
    )}
  </>
}

export default DetailedSets

export type {
  ScoreSetsProps
}

import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import Meta, { MetaWeight } from '../../../atoms/meta'
import Title, { TitleSize } from '../../../atoms/title'
import Button, { ButtonVariant } from '../../../molecules/button'

import styles from './substitution.module.scss'

interface SubstitutionProps {
  in?: any
  out?: any
  team?: any
}

const Substitution: FunctionComponent<SubstitutionProps> = (
  {
    in: inbound,
    out: outbound,
    team
  }
) => {
  return <div
    className={clsx(
      styles.substitution,
      'sw-timeline-item'
    )}
  >
    <div className={styles.header}>
      <Meta weight={MetaWeight.Medium}>wissel</Meta>
    </div>
    <div className={styles.content}>
      <div className={styles.outbound}>
        <Button
          variant={ButtonVariant.tertiary}
          iconAfter="arrow-circle-right"
        >
          <Title
            size={TitleSize.Small}
            link={outbound.href}
          >{outbound.name}</Title>
        </Button>
      </div>

      <div className={styles.inbound}>
        <Button
          variant={ButtonVariant.tertiary}
          iconBefore="arrow-circle-left"
        >
          <Title
            size={TitleSize.Small}
            link={inbound.href}
          >{inbound.name}</Title>
        </Button>
      </div>
    </div>
    <div className={styles.footer}>
      {team.name}
    </div>
  </div>
}

export default Substitution

export type {
  SubstitutionProps,
}

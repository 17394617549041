import type { FunctionComponent } from 'react'
import type { ButtonProps } from '../../../molecules/button'

import clsx from 'clsx'

import Button, { ButtonSize, ButtonVariant } from '../../../molecules/button'

import styles from './custom.cell.module.scss'

interface CellWithButtonsProps {
  buttons: ButtonProps[],
  ebaData?: any
}

const CellWithButtons: FunctionComponent<CellWithButtonsProps> = (
  {
    buttons = [],
    ebaData
  }
) => {
  return <div className={styles.buttons}>
    {
      buttons?.length > 0 ? buttons.map((item, index) => <Button
        ebaData={ebaData}
        ariaLabel={item.ariaLabel}
        key={`${index}-${item.title}-${item.iconBefore}`}
        variant={ButtonVariant.secondary}
        size={ButtonSize.small}
        className={clsx(
          !item.href && styles.noLink,
          item.iconBefore && item.iconBefore.indexOf('play-6') > -1 && styles.cellButtonPlay6,
          item.iconBefore && item.iconBefore.indexOf('play-sports') > -1 && styles.cellButtonPlaySports,
        )}
        {...item}
        title={item.ariaLabel}
      >{item.title}</Button>) : <div className={styles.placeholder}>---</div>
    }
  </div>
}

export default CellWithButtons

import type { FunctionComponent } from 'react'
import type { ScoreboardRoundsProps } from '../default'

import clsx from 'clsx'

import Caption from '../../../../atoms/caption'
import Icon from '../../../../atoms/icon'
import Paragraph from '../../../../atoms/paragraph'
import Title, { TitleSize } from '../../../../atoms/title'
import Score, { ScoreLayoutTypes } from '../../../../molecules/score'

import styles from './banner.module.scss'

const BannerRoundsBoard: FunctionComponent<ScoreboardRoundsProps> = (
    {
        sport,
        competitionName,
        title,
        date,
        status,
        type,
        typeIcon,
        rounds,
        roundsToGo,
        winner,
        label,
        startLabel,
        location,
        darkMode
    }
) => {
    return <div className={clsx(
        styles.scoreboard,
        styles.rounds,
        darkMode ? styles.darkMode : undefined
    )}>
        {title && <Caption className={styles.title}>{title}</Caption>}
        <Title darkMode={darkMode} size={TitleSize.XSmall} desktopSize={TitleSize.Medium}>{competitionName}</Title>
        <Score layout={ScoreLayoutTypes.Banner}
               sport={sport}
               startLabel={startLabel}
               location={location}
               roundsToGo={roundsToGo}
               status={status}
               date={date}
               darkMode={darkMode}
               label={label} />
        <div className={styles.list}>
            {type && <Paragraph darkMode={darkMode} className={styles.listItem}>{typeIcon && <Icon name={typeIcon} />}{type}</Paragraph>}
            {location && <Paragraph darkMode={darkMode} className={styles.listItem}><Icon name={'location'} />{location}</Paragraph>}
            {rounds && <Paragraph darkMode={darkMode} className={styles.listItem}><Icon name={'formula1-laps'} />{rounds} rondes</Paragraph>}
        </div>
        {winner && <div className={styles.winners}>
          <Paragraph className={clsx(styles.winner, styles.winnerFirst)} key={winner.name} darkMode={darkMode}>
              <Icon name={'cycling-hotseat'}/> {winner.name}
          </Paragraph>
        </div>
        }
    </div>
}

export default BannerRoundsBoard

export type {
    ScoreboardRoundsProps
}

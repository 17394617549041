import type { PanHandlers } from 'framer-motion'
import type { CSSProperties, FunctionComponent } from 'react'
import type { IconProps } from '../../atoms/icon'
import type { MediaplayerProps } from '../mediaplayer'

import { getThemeColorFromValue, Theme } from '@sporza/config'
import { motion } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import Title, { TitleSize } from '../../atoms/title'
import { Avatar } from '../../molecules/avatar'
import Button, { ButtonVariant } from '../../molecules/button'
import { Modal } from '../../molecules/modal'
import Mediaplayer from '../mediaplayer'

import styles from './media-modal.module.scss'

interface MediaModalProps {
  title?: string
  iconBefore?: IconProps
  open?: boolean
  media?: MediaplayerProps
  selectedMediaIndex?: number
  theme?: Theme
  onClose?: () => void
  onPrev?: () => void
  onNext?: () => void
}

const MediaModal: FunctionComponent<MediaModalProps> = (
  {
    title,
    iconBefore,
    open,
    media,
    selectedMediaIndex,
    theme,
    onClose,
    onPrev,
    onNext,
  }
) => {
  const modalRef = useRef<HTMLDialogElement>(null)
  const playerRef = useRef(null)

  const handlePanEnd: PanHandlers['onPanEnd'] = (event, { offset }) => {
    if (offset.x > 4) {
      // swipe right
      (playerRef.current as any)?.firstChild?.api?.previous()
    } else if (offset.x < -4) {
      // swiper left
      (playerRef.current as any)?.firstChild?.api?.next()
    }
  }

  useEffect(() => {
    open
      ? modalRef.current?.showModal()
      : modalRef.current?.close()
  }, [open])

  useHotkeys('tab+shift', () => onPrev ? onPrev() : null)
  useHotkeys('tab', () => onNext ? onNext() : null)
  useHotkeys('left', () => (playerRef.current as any)?.firstChild?.api?.previous())
  useHotkeys('right', () => (playerRef.current as any)?.firstChild?.api?.next())

  return <Modal
    innerRef={modalRef}
    className={styles.modal}
    onClose={onClose}
  >
    <div
      className={styles.modalInner}
      style={
        theme === Theme.OlympischeSpelen2024 || theme === Theme.ParalympischeSpelen2024
          ? getThemeColorFromValue(title ? title : (iconBefore as unknown as string)) as CSSProperties
          : undefined
      }
    >
      <div className={styles.buttonContainer}>
        {
          onPrev
            ? <Button
              iconBefore="previous"
              variant={ButtonVariant.secondary}
              onClick={onPrev}
              className={styles.prev}
            />
            : null
        }
        <Button
          iconBefore="chevron-left"
          variant={ButtonVariant.secondary}
          onClick={() => {
            playerRef ? (playerRef.current as any)?.firstChild?.api?.previous() : null
          }}
          className={styles.prevInPlaylist}
        />
      </div>
      <motion.div
        onPanEnd={handlePanEnd}
        className={styles.playerContainer}
      >
        <div className={styles.playerHeader}>
          {
            iconBefore
            && <Avatar icon={iconBefore.name} className={styles.icon}/>
          }
          {title && <Title size={TitleSize.XSmall}>{title}</Title>}
        </div>
        {
          media
            ? <Mediaplayer
              {...media}
              playlistIndexOrRef={selectedMediaIndex}
              showPosterImage={false}
              className={styles.player}
              innerRef={playerRef}
            />
            : null
        }
      </motion.div>
      <div className={styles.buttonContainer}>
        <Button
          iconBefore="chevron-right"
          variant={ButtonVariant.secondary}
          onClick={() => {
            playerRef ? (playerRef.current as any)?.firstChild?.api?.next() : null
          }}
          className={styles.nextInPlaylist}
        />
        {
          onNext
            ? <Button
              iconBefore="next"
              variant={ButtonVariant.secondary}
              onClick={onNext}
              className={styles.next}
            />
            : null
        }
      </div>
    </div>
    <Button
      iconBefore="close"
      variant={ButtonVariant.secondary}
      onClick={() => {
        onClose
          ? onClose()
          : modalRef.current?.close()
      }}
      className={styles.close}
    />
  </Modal>
}

export {
  MediaModal
}

export type {
  MediaModalProps,
}

import type { FunctionComponent, PropsWithChildren } from 'react'

import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'

import Button, { ButtonVariant } from '../button'

import styles from './filter.module.scss'

interface FilterProps extends PropsWithChildren {
  title?: string
  options: FilterOption[]
  onSelect?: (option: FilterOption) => void
  type?: FilterType,
  multiple?: boolean
  hideWhenSingle?: boolean
  className?: string
  size?: FilterSize,
  style?: FilterStyle
}

interface FilterOption {
  label?: string,
  value?: string,
  active?: boolean,
  items?: FilterOption[]
}

enum FilterType {
  List = 'List',
  Dropdown = 'Dropdown'
}

enum FilterSize {
  Small = 'Small',
  Medium = 'Medium'
}

enum FilterStyle {
  Primary = 'Primary',
  Secondary = 'Secondary'
}

const Filter: FunctionComponent<FilterProps> = (
  {
    title,
    options = [],
    onSelect = () => void 0,
    type = FilterType.List,
    multiple = false,
    hideWhenSingle = true,
    className,
    size = FilterSize.Medium,
    style = FilterStyle.Primary
  }
) => {
  const [hover, setHover] = useState<boolean>(false)
  const [optionsData, setOptionsData] = useState<FilterOption[]>(options)
  const [dropdownWidth, setDropdownWidth] = useState<string>('auto')
  const dropdownRef = useRef<null | HTMLSpanElement>(null)

  const setFixedWidths = () => {
    setDropdownWidth('auto')
    if (dropdownWidth === 'auto'){
      const width = dropdownRef?.current?.clientWidth
      if (width) setDropdownWidth(`${width}px`) // reason: inner list is positioned absolute on hover so it loses width
    }
  }

  useEffect(() => {
    setFixedWidths()
    if (getFirstActiveItem() === undefined && optionsData?.length > 0){
      optionsData[0].active = true
    }
  }, [optionsData])

  useEffect(() => {
    setOptionsData(options)
  }, [options])

  const getFirstActiveItem = () => {
    let result

    const recursive = (items: FilterOption[]) => {
      items.forEach(item => {
        if (item.active){
          result = item
        }

        if (item.items && item.items.length > 0) recursive(item.items)
      })
    }

    recursive(optionsData)

    return result
  }

  const handleClick = (item: FilterOption) => {
    // if (item.value) {
      if (!multiple){
        const deactivate = (items: FilterOption[]) => {
          items && items.forEach(item => {
            item.active = false
            if (item.items) deactivate(item.items)
          })
        }
        deactivate(optionsData)
        setHover(false)
      }
      item.active = !item.active

      // setOptionsData([...optionsData])
      onSelect(getFirstActiveItem())
    }
  // }

  const variant = style === FilterStyle.Primary ? ButtonVariant.primary : ButtonVariant.tertiary

  switch (true) {
    case hideWhenSingle && optionsData.length < 2:
      return null
    case type === FilterType.List:
      return <span className={clsx(
        styles.filter,
        size === FilterSize.Small && styles.small
      )}>
        <ul className={styles.list}>
          {title && <li className={styles.label}>{title}: </li>}
          {optionsData.map((item: FilterOption) => <li key={item.label}>
            <Button onClick={() => handleClick(item)} iconAfter={item.active ? 'check-alt' : undefined}>{item.label}</Button>
          </li>)}
        </ul>
      </span>

    case type === FilterType.Dropdown:
      return <span
        style={{ width: (parseInt(dropdownWidth) > 0) ? parseInt(dropdownWidth) + 40 : dropdownWidth }}
        ref={dropdownRef}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={clsx(
          styles.filter,
          size === FilterSize.Small && styles.small,
          style === FilterStyle.Secondary && styles.secondary,
          className
        )}
      >
        <Button className={clsx(
          styles.dropdownButton,
          hover && styles.activeButton
        )}
          iconAfter={'caret-down'}
          variant={variant}
        >{title || getFirstActiveItem()?.label}</Button>
        <ul className={clsx(
          styles.dropdownList,
          hover && styles.active
        )}>
          {optionsData.map((item: FilterOption) => <li key={item.label}>
            <Button onClick={() => handleClick(item)} variant={variant} iconAfter={item.active ? 'check-alt' : undefined}>{item.label}</Button>
            {item.items && item.items.length > 0 && <ul className={styles.dropdownSubList}>
              {item.items.map((item: FilterOption) =>
                <li key={item.label}>
                  <Button onClick={() => handleClick(item)} iconAfter={item.active ? 'check-alt' : undefined}>{item.label}</Button>
                </li>
              )}
            </ul>}
          </li>)}
        </ul>
      </span>
  }
}

export default Filter

export {
  FilterType,
  FilterSize,
  FilterStyle
}

export type {
  FilterProps,
  FilterOption
}

import type { FunctionComponent } from 'react'
import type { DataCardProps } from '../../index'

import clsx from 'clsx'

import Title, { TitleSize } from '../../../../atoms/title'
import DataList from '../../../../molecules/data-list'
import Pill, { PillSize } from '../../../../molecules/pill'
import Card from '../../../card'
import styles from '../../data-card.module.scss'

const DataCardHorizontal: FunctionComponent<DataCardProps> = (
  {
    subTitle,
    title,
    dataList
  }
) => {
  return <Card
    className={clsx(
      styles.dataCard,
      styles.dataCardHorizontal
    )}
  >
    <div className={styles.headerWrapper}>
      {title && <Title desktopSize={TitleSize.Small} size={TitleSize.XSmall}>{title}</Title>}
      {subTitle && <Pill size={PillSize.Medium} className={styles.pill}>{subTitle}</Pill>}
    </div>
    <div className={styles.dataListContainer}>
      {dataList && dataList.map((item, index) => <div className={styles.dataListWrapper} key={`${title}${subTitle}${index}`}>
        {Array.isArray(item) && item.map((dataList, index2) => <DataList
          key={`${title}${subTitle}${index}${index2}`}
          className={styles.dataList}
          {...dataList.componentProps}
        />)}
      </div>)}
    </div>
  </Card>
}

export {
  DataCardHorizontal
}

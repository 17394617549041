
import type { FunctionComponent } from 'react'
import type { ScoreRoundsProps } from '../default'

import clsx from 'clsx'

import commonStyles from '../../score.module.scss'

import styles from './simple.module.scss'

type ScoreSimpleRoundsProps = ScoreRoundsProps

const SimpleRounds: FunctionComponent<ScoreSimpleRoundsProps> = ({
  roundsToGo
}) => {
  return <>
    {roundsToGo && <span className={clsx(commonStyles.label, styles.simpleLabel)}>
      nog {roundsToGo} {`ronde${roundsToGo === 1 ? '' : 's'}`}
    </span>}
  </>
}

export default SimpleRounds

export type {
  ScoreSimpleRoundsProps
}

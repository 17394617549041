import type { ErrorInfo, ReactNode } from 'react'

import { Component } from 'react'

import { Notification, NotificationType } from '../../molecules/notification'

interface ErrorBoundaryProps {
  componentId?: string;
  fallback?: ReactNode;
  children?: ReactNode;
  author?: boolean
}

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    hasError: false,
    error: undefined
  }

  public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {
      hasError: true,
      error
    }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    const {
      fallback ,
      componentId,
      author = false
    } = this.props

    const {
      hasError,
      error
    } = this.state

    if (hasError) {
      return <div>
        {fallback}
        {
          error
          && author
            ? <Notification type={NotificationType.Error} label={`Error: ${componentId}`} text={error.message} />
            : <span
            className="sw-error"
            dangerouslySetInnerHTML={{ __html: `<!--\n${componentId}\n${error.message}\n${error.stack}-->` }}></span>
        }
      </div>
    }

    return this.props.children
  }
}

export {
  ErrorBoundary
}

import clsx from 'clsx'
import { FunctionComponent } from 'react'

import { DataListProps } from '../index'

import styles from './ring.module.scss'

enum MedalTypes {
  gold = 'gold',
  silver = 'silver',
  bronze = 'bronze'
}

enum MedalColors {
  gold = 'var(--color-event-gold)',
  silver = 'var(--color-event-silver)',
  bronze = 'var(--color-event-bronze)'
}

const DataListRing: FunctionComponent<DataListProps> = (
  {
    items,
    className
  }
) => {
  const total = items.reduce((sum, item) => sum + parseFloat(item.value), 0)

  const percentages = items.map(item => (parseFloat(item.value) / total) * 100)

  const gradientStops: string[] = []
  gradientStops.push('var(--color-background-000) 0deg 1deg') // start with a white separator

  let currentAngle = 0
  percentages.forEach((percentage, index) => {
    const nextAngle = currentAngle + percentage * 3.6
    const title = items[index].title
    const color = MedalColors[title as keyof typeof MedalTypes]
    gradientStops.push(`${color} ${currentAngle}deg ${nextAngle}deg`)
    gradientStops.push(`var(--color-background-000) ${nextAngle}deg ${nextAngle+1}deg`) // add a white separator
    currentAngle = nextAngle
  })

  const gradient = `conic-gradient(${gradientStops.join(', ')})`

  return <div className={clsx(className, styles.ring)}
    style={{
      background: gradient
    }}
  />
}

export default DataListRing

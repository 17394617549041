import { addScriptToDOM } from '@sporza/utils/dom'
import clsx from 'clsx'
import { FunctionComponent, useEffect } from 'react'

import styles from './newsletter-subscription.module.scss'

interface NewsletterSubscriptionProps {
  code?: string
}

const NewsletterSubscription: FunctionComponent<NewsletterSubscriptionProps> = (
  {
    code
  }
) => {
  useEffect(() => {
    if (!code)
      return

    const id = 'newsletter-subscription-script'

    if (!document.getElementById(id)) {
      addScriptToDOM({
        // warning! this script loads a css file from `/optinWidget.min.css` on storybook because the script recognized a localhost
        src: 'https://sso.vrt.be/optinWidget.min.js',
        id
      })
    }
  }, [code])

  return code
    ? <div className={
      clsx(
        'vrt-newsletter-form',
        styles.newsletterSubscription
      )
    }>
      <div className="vrt-newsletter-form__widget">
        <div
          className="optinWidgetRoot"
          aria-live="polite"
          data-widget={
            `{
                "action": "https://crm.profiel.vrt.be/api/v2/public/optins",
                "method": "post",
                "optins": "${code}",
                "fields": [
                  {
                    "name": "firstname",
                    "type": "text",
                    "value": "",
                    "required": "true",
                    "label": "Voornaam",
                    "error": "Controleer je naam"
                  },
                  {
                    "name": "email",
                    "type": "email",
                    "value": "",
                    "required": "true",
                    "label": "E-mailadres",
                    "error": "Controleer je e-mailadres",
                    "pattern": "^\\\\w+([\\\\.-]?\\\\w+)*@\\\\w+([\\\\.-]?\\\\w+)*(\\\\.\\\\w{2,3})+$"
                  }
                ],
                "submitButtonText": "Schrijf me in!",
                "submitButtonTextSending": "Bezig met inschrijven",
                "error": "Wegens een fout hebben we jouw verzoek niet kunnen verzenden.",
                "success": "Bedankt om je in te schrijven op onze nieuwsbrief!"
            }`
          }
        ></div>
      </div>
    </div>
    : null
}

export {
  NewsletterSubscription
}

export type {
  NewsletterSubscriptionProps
}


import type { FunctionComponent } from 'react'
import type { BracketMatchProps } from './bracket-match'

import clsx from 'clsx'

import commonStyles from '../bracket.module.scss'

import BracketMatch from './bracket-match'
import styles from './bracket-round.module.scss'

interface BracketRoundProps {
  label?: string
  items: BracketMatchProps[]
  hideOnActive?: boolean
  className?: string
}

const BracketRound: FunctionComponent<BracketRoundProps> = (
  {
    label,
    items,
    className,
  }
) => {
  return <li className={className}>
    <div className={styles.roundLabel}>
      {label}
    </div>
    <ul className={clsx(
      styles.round,
      commonStyles.resetList
    )}>
      {
        items.map((match: any, index: number) => {
          return <li
            key={match.matchId || index}
            className={styles.match}
          >
            <BracketMatch {...match} />
          </li>
        })
      }
    </ul>
  </li>
}

export default BracketRound

export {
  BracketRound
}

export type {
  BracketRoundProps
}

import type { FunctionComponent } from 'react'

import clsx from 'clsx'
import { Fragment } from 'react'

import styles from './description-list.module.scss'

type DefinitionListItem = {
  name: string,
  value: string
}

interface DescriptionListProps {
  items: DefinitionListItem[]
  className?: string
  darkMode?: boolean
}

const DescriptionList: FunctionComponent<DescriptionListProps> = (
  {
    items,
    className,
    darkMode
  }
) => {
  return <dl className={clsx(
    styles.descriptionList,
    darkMode && styles.descriptionListDark,
    className
  )}>
    {
      items.map((item, index) => {
        return <Fragment key={index}>
        <dt className={styles.name}>{item.name}</dt>
        <dd className={styles.value}>{item.value}</dd>
      </Fragment>
      })
    }
  </dl>
}

export {
  DescriptionList
}

export type {
  DescriptionListProps
}

const getSearchData = () => {
  const { search } = window.ebaData || {}
  const {
    context_search = {}
  } = search || {}

  return {
    '$seste': context_search.searchTerm,
    '$sesst': context_search.suggestSearchTerm,
    '$sesfi': context_search.searchFilter,
    '$sesty': context_search.searchType,
    '$sesco': parseInt(context_search.searchCount),
  }
}

export {
  getSearchData
}

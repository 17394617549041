import type { FunctionComponent, ReactElement, ReactNode } from 'react'
import type { Swiper as SwiperClass } from 'swiper/types'
import type { SectionProps } from '../index'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import clsx from 'clsx'
import { Children, cloneElement, useState } from 'react'
import SwiperCore from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Breakpoints } from '../../../design-tokens/breakpoints'
import { Icons } from '../../../design-tokens/iconography/icons'
import Button from '../../../molecules/button'
import { SectionBackground, SectionLayout, SectionSpacing } from '../index'

import SectionCommon from './common'
import commonStyles from './common.module.scss'
import gridStyles from './section-grid.module.scss'
import styles from './slider.module.scss'

interface SectionSliderProps extends Omit<SectionProps, 'items' | 'imageLayout'> {
  breakpointBase?: 'window' | 'container'
}

export enum NavigationIcons {
  Left = 'chevron-left',
  LeftDisabled = 'chevron-left-disabled',
  Right = 'chevron-right',
  RightDisabled = 'chevron-right-disabled'
}

const SectionSlider: FunctionComponent<SectionSliderProps> = (
  {
    title,
    quickLinkTitle,
    itemsAmount,
    text,
    link,
    linkText,
    layout = SectionLayout.Grid2Column,
    background = SectionBackground.Default,
    breakpointBase = 'window',
    darkMode,
    children,
    analyticsId,
    className,
    innerRef,
    spacing,
    filter,
    handleFilter,
    ariaLabelledBy
  }
) => {
  const isDarkMode = darkMode || background === SectionBackground.Dark
  const [controlledSwiper, setControlledSwiper] = useState<SwiperCore>()
  const [prevIcon, setPrevIcon] = useState<Icons>(NavigationIcons.LeftDisabled)
  const [nextIcon, setNextIcon] = useState<Icons>(NavigationIcons.Right)
  const [showButtons, setShowButtons] = useState(true)

  const checkSlides = (swiper: SwiperClass) => {
    if (swiper.isEnd && swiper.isBeginning) {
      setShowButtons(false)
    }
  }

  const handleSlideToEdge = (swiper: SwiperClass) => {
    if (swiper.isBeginning) {
      setPrevIcon(NavigationIcons.LeftDisabled)
    } else {
      setNextIcon(NavigationIcons.RightDisabled)
    }
  }

  const handleSlideAwayFromEdge = () => {
    if (prevIcon === NavigationIcons.LeftDisabled) {
      setPrevIcon(NavigationIcons.Left)
    } else if (nextIcon === NavigationIcons.RightDisabled) {
      setNextIcon(NavigationIcons.Right)
    }
  }

  const slidesAmount = () => {
    switch (layout) {
      case SectionLayout.Grid11ColumnSlider:
        return 3.5
      case SectionLayout.Grid5ColumnSlider:
      case SectionLayout.Grid6ColumnSlider:
        return 1.75
      case SectionLayout.Grid3ColumnSlider:
      default:
        return 1.05
    }
  }

  const slidesAmountMedium = () => {
    switch (layout) {
      case SectionLayout.Grid3ColumnSlider:
      case SectionLayout.Grid5ColumnSlider:
      case SectionLayout.Grid6ColumnSlider:
        return 3
      case SectionLayout.Grid11ColumnSlider:
        return 11
      default:
        return 4
    }
  }

  const slidesAmountLarge = () => {
    switch (layout) {
      case SectionLayout.Grid3ColumnSlider:
        return 3
      case SectionLayout.Grid5ColumnSlider:
        return 5
      case SectionLayout.Grid6ColumnSlider:
        return 6
      case SectionLayout.Grid11ColumnSlider:
        return 11
      default:
        return 4
    }
  }

  const spaceBetween = () => {
    switch (true) {
      case spacing === SectionSpacing.Narrow:
        return 1
      case layout === SectionLayout.Grid3ColumnSlider:
      case layout === SectionLayout.Grid4ColumnSlider:
        return 0
      case layout === SectionLayout.Grid4ColumnSliderBottom:
        return 26
      case layout === SectionLayout.Grid5ColumnSlider:
      case layout === SectionLayout.Grid6ColumnSlider:
        return 8
      default:
        return 1
    }
  }

  const spaceBetweenMedium = () => {
    switch (true) {
      case spacing === SectionSpacing.Narrow:
        return 1
      case layout === SectionLayout.Grid3ColumnSlider:
      case layout === SectionLayout.Grid4ColumnSlider:
        return 0
      case layout === SectionLayout.Grid4ColumnSliderBottom:
        return 26
      case layout === SectionLayout.Grid5ColumnSlider:
        return 15
      default:
        return 8
    }
  }

  const spaceBetweenLarge = spaceBetweenMedium

  const actionButtons = () => <>
    <Button
      variant={hasScrollbar() ? 'primary' : 'tertiary'}
      iconBefore={prevIcon}
      onClick={() => controlledSwiper?.slidePrev()}
      darkMode={isDarkMode}
      tabIndex={-1}
    />
    <Button
      variant={hasScrollbar() ? 'primary' : 'tertiary'}
      iconBefore={nextIcon}
      onClick={() => controlledSwiper?.slideNext()}
      darkMode={isDarkMode}
      tabIndex={-1}
    />
  </>

  const hasScrollbar = () => {
    return layout === 'Grid4ColumnSliderBottom'
  }

  return <SectionCommon
    innerRef={innerRef}
    analyticsId={analyticsId}
    title={title}
    itemsAmount={itemsAmount}
    quickLinkTitle={quickLinkTitle}
    text={text}
    link={link}
    linkText={linkText}
    headerActions={!hasScrollbar() && showButtons ? actionButtons() : undefined}
    className={clsx(
      className,
      commonStyles.section,
      styles.slider,
      background === SectionBackground.Dark && commonStyles.backgroundDark,
      background === SectionBackground.Light && commonStyles.backgroundLight,
      background === SectionBackground.Grey && commonStyles.backgroundGrey,
      isDarkMode && commonStyles.dark,
      className
    )}
    filter={filter}
    handleFilter={handleFilter}
    ariaLabelledBy={ariaLabelledBy}
  >
    {
      <Swiper
        a11y={{
          enabled: false
        }}
        pagination={!hasScrollbar() && {
          clickable: true,
          dynamicBullets: true
        }}
        scrollbar={hasScrollbar() && {
          el: '.swiper-scrollbar',
          draggable: true
        }}
        slidesPerView={slidesAmount()}
        spaceBetween={spaceBetween()}
        breakpointsBase={
          layout === SectionLayout.Grid5ColumnSlider || layout === SectionLayout.Grid6ColumnSlider
            ? 'container'
            : breakpointBase
        }
        breakpoints={{
          [Breakpoints.medium]: {
            slidesPerView: slidesAmountMedium(),
            spaceBetween: spaceBetweenMedium(),
            slidesPerGroup: slidesAmountMedium()
          },
          [Breakpoints.large]: {
            slidesPerView: slidesAmountLarge(),
            spaceBetween: spaceBetweenLarge(),
            slidesPerGroup: slidesAmountLarge()
          }
        }}
        modules={[Navigation, Pagination]}
        cssMode={true}
        onInit={checkSlides}
        onToEdge={handleSlideToEdge}
        onFromEdge={handleSlideAwayFromEdge}
        onSwiper={(swiper) => setControlledSwiper(swiper)}
        className={clsx(
          layout === SectionLayout.Grid4ColumnSlider || layout === SectionLayout.Grid4ColumnSliderBottom && gridStyles.gridGrid4ColumnSlider,
          layout === SectionLayout.Grid3ColumnSlider && gridStyles.gridGrid3ColumnSlider,
          layout === SectionLayout.Grid5ColumnSlider && gridStyles.gridGrid5ColumnSlider,
          layout === SectionLayout.Grid6ColumnSlider && gridStyles.gridGrid6ColumnSlider,
          layout === SectionLayout.Grid11ColumnSlider && gridStyles.gridGrid11ColumnSlider
        )}>
        {
          Children
            .toArray(children as ReactNode)
            .map((item) =>
              <SwiperSlide
                className={styles.sliderItem}
                key={`slide-${(item as ReactElement).key}`}>
                {cloneElement(item as ReactElement)}
              </SwiperSlide>
            )
        }
        {hasScrollbar() && <div className={styles.bottom}>
          <div className={clsx(
            'swiper-scrollbar',
            styles.scrollbar,
            darkMode && styles.scrollbarDark
          )}></div>
          <div className={styles.scrollbarNavigation} aria-hidden={true}>
            {actionButtons()}
          </div>
        </div>}
      </Swiper>
    }
  </SectionCommon>
}

export type {
  SectionSliderProps
}

export default SectionSlider

import type { FunctionComponent } from 'react'
import type { CellWithImageProps } from './cell-with-image'

import { CellWithImage } from './index'

interface CellWithFlagProps extends CellWithImageProps {
  flagUrl?: string
}
const CellWithFlag: FunctionComponent<CellWithFlagProps> = ({ flagUrl, ...props }) => {

  return <CellWithImage imageUrl={flagUrl} noCrop setBoxShadow {...props}/>
}
export default CellWithFlag

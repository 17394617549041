import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import { VotingLayout, VotingPhase } from './config'
import { Eddies, IedereenBondscoach } from './layout'
import styles from './voting.module.scss'

interface VotingProps {
  [key: string]: any

  slug?: string
  phase?: VotingPhase | string
  endDateTime?: string
  layout?: VotingLayout
  votingBaseUrl: string
  designSystemBaseUrl?: string
  handlePhaseChange?: (newPhase: VotingPhase, vote?: any, players?: any) => void
}

const layoutComponents: Record<VotingLayout, any> = {
  [VotingLayout.Default]: IedereenBondscoach,
  [VotingLayout.IedereenBondscoach]: IedereenBondscoach,
  [VotingLayout.Eddies]: Eddies
}

const Voting: FunctionComponent<VotingProps> = (props) => {
  const Layout = layoutComponents[props.layout as VotingLayout] || layoutComponents[VotingLayout.Default]

  return <Layout
    {...props}
    designSystemBaseUrl={props.designSystemBaseUrl}
    className={clsx(
      styles.voting,
      props.layout == VotingLayout.Eddies && styles.votingEddies,
      props.layout == VotingLayout.IedereenBondscoach && styles.votingIedereenBondscoach,
      props.layout == VotingLayout.Default && styles.votingIedereenBondscoach
    )}
  />
}

export {
  Voting
}

export type {
  VotingProps
}


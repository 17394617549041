const splitInEqualParts = (arr: any[], parts = 2) => {
  const result = []
  const chunkSize = Math.ceil(arr.length / parts)
  let i = 0

  while (i < arr.length) {
    result.push(arr.slice(i, i += chunkSize))
  }

  return result
}

const shuffle = (array: any[]): any[] => {
  let currentIndex = array.length, randomIndex

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]]
  }

  return array
}

enum Order {
  descending = 'descending',
  ascending = 'ascending',
}

const sortByKey = (key: string, order: Order | string) =>
  (itemA: Record<any, any>, itemB: Record<any, any>) => {
    if (itemA[key] < itemB[key]) {
      return order === 'ascending' ? -1 : 1
    }

    if (itemA[key] > itemB[key]) {
      return order === 'ascending' ? 1 : -1
    }

    return 0
  }

const splitByKey = (arr: any[], key = '') => {
  if (!key) {
    return {
      all: arr
    }
  }

  return arr?.reduce((accumulator: any, item: any) => {
    const property = item[key]
    const propertyValue = accumulator.find((it: any) => it[key] === property)

    if (propertyValue) {
      propertyValue.items.push(item)
    } else {
      accumulator.push({
        label: property.toLowerCase(),
        items: [item]
      })
    }

    return accumulator
  }, []) || []
}

export {
  splitInEqualParts,
  splitByKey,
  shuffle,
  sortByKey
}

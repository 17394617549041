import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import Paragraph from '../../atoms/paragraph'
import Title, { TitleElement, TitleSize } from '../../atoms/title'
import Pill, { PillSize } from '../../molecules/pill'

import styles from './nutshell.module.scss'

interface NutshellProps {
  title?: string,
  items: any[]
}

const Nutshell: FunctionComponent<NutshellProps> = (
  {
    title,
    items
  }
) => {
  return <div
    className={clsx(
      styles.nutshell
    )}>
    {title && <Title size={TitleSize.Small} tag={TitleElement.H2}>{title}</Title>}
    <ol className={styles.list}>
      {items && items.length > 0 && items.map((item, index) => <li
        key={`list-${index}${item.label}`}
        className={styles.item}
      >
        <Pill className={styles.pill}
              size={PillSize.Small}>
          {item.label}
        </Pill>
        <Paragraph>{item.text}</Paragraph>
      </li>)}
    </ol>
  </div>
}

export default Nutshell

export type {
  NutshellProps
}

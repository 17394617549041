import type { FunctionComponent } from 'react'

import Caption from '../../../../atoms/caption'
import Icon from '../../../../atoms/icon'
import styles from '../../score.module.scss'
import { ScoreDistanceProps } from '../default'

const DetailedDistance: FunctionComponent<ScoreDistanceProps> = ({
  distanceToGo,
  label,
  typeIcon
}) => {
  return <>
    {distanceToGo && <Caption className={styles.label}>nog <span className={styles.bigNumber}>{distanceToGo} km</span></Caption>}
    {label && <Caption className={styles.label}>{label}</Caption>}
    {typeIcon === 'cycling-stopwatch' && <Icon name={'cycling-stopwatch'} />}
  </>
}

export default DetailedDistance

export type {
  ScoreDistanceProps
}

import clsx from 'clsx'
import { Fragment, FunctionComponent } from 'react'

import Caption from '../../../atoms/caption'
import Icon from '../../../atoms/icon'
import Title, { TitleElement, TitleSize } from '../../../atoms/title'
import styles from '../data-list.module.scss'
import { DataListLayout, DataListProps, DataListSize } from '../index'

const DataListDefault: FunctionComponent<DataListProps> = (
  {
    items,
    size = DataListSize.Medium,
    layout = DataListLayout.Border,
    shadow,
    className
  }
) => {
  return <div
    className={clsx(
      className,
      styles.dataList,
      layout === DataListLayout.LabelTop && styles.labelTop,
      layout === DataListLayout.Border && styles.border,
      shadow && styles.shadow
    )}>
    <ul className={styles.list}>
      {items && items.length > 0 && items.map((item, index) => <li
        key={`list-${index}${item.title}`}
        className={styles.itemWrapper}
      >
        <div className={styles.item}>
          <Title className={styles.title}
                 size={size === DataListSize.Large ? TitleSize.Small : TitleSize.XSmall}
                 desktopSize={size === DataListSize.Large ? TitleSize.Large : TitleSize.XSmall}
                 tag={TitleElement.SPAN}>
            {
              item.value?.toString().split('\n').map((val, index, orig) => {
                return <Fragment key={`${val}-${index}`}>
                  {val}
                  {index !== orig.length - 1 && <br/>}
                </Fragment>
              })
            }
            <sub className={styles.sub}>{item.subValue}</sub>
          </Title>
          {item.title && <Caption className={styles.caption}>{item.title}</Caption>}
          {item.icon && <Icon name={item.icon} />}
        </div>
      </li>)}
    </ul>
  </div>
}

export default DataListDefault

import type { FunctionComponent, PropsWithChildren } from 'react'
import type { Icons } from '../../design-tokens/iconography/icons'
import type {
  ScoreDistanceProps,
  ScoreRoundsProps,
  ScoreScoreProps,
  ScoreSetsProps
} from './layout'

import clsx from 'clsx'

import {
  BannerDistance,
  BannerRounds,
  BannerScore,
  BannerSets,
  DefaultDistance,
  DefaultRounds,
  DefaultScore,
  DefaultSets,
  DetailedDistance,
  DetailedRounds,
  DetailedScore,
  DetailedSets,
  ResultDistance,
  ResultRounds,
  SimpleDistance,
  SimpleRounds,
  SimpleScore,
  SimpleSets
} from './layout'
import styles from './score.module.scss'

enum ScoreLayoutTypes {
  Default =   'default',
  Simple =    'simple',
  Detailed =  'detailed',
  Result =    'result',
  Banner =    'banner'
}

enum StatusTypes {
  AfterToday =            'AFTER_TODAY',
  NotStarted =            'NOT_STARTED',
  PostPoned =             'POSTPONED',
  Live =                  'LIVE',
  Suspended =             'SUSPENDED',
  SuspendedIndefinitely = 'SUSPENDED_INDEFINITELY',
  Cancelled =             'CANCELLED',
  End =                   'END',
  Shootouts =             'SHOOTOUTS',
}

// TODO mode these types somewhere global
enum SportTypes {
  Soccer =      'soccer',
  Tennis =      'tennis',
  Cycling =     'cycling',
  Basketball =  'basketball',
  Hockey =      'hockey',
  Handball =    'handball',
  Volleyball =  'volleyball',
  Formula1 =    'formula1',
  Formule1 =    'formule1'
}

const scoreLayoutStyles: {
  [Key in ScoreLayoutTypes]: string
} = {
  default: styles.default,
  simple: styles.simple,
  detailed: styles.detailed,
  result: styles.result,
  banner: styles.banner
}

interface ScoreProps extends PropsWithChildren, ScoreScoreProps, ScoreSetsProps, ScoreDistanceProps, ScoreRoundsProps {
  layout: string | ScoreLayoutTypes
  typeIcon?: string | Icons
  sport?: string | SportTypes
  label?: string
  title?: string
  date?: string
  status?: string | StatusTypes
  darkMode?: boolean
  winner?: Record<string, string>
  winners?: [Record<string, string>]
  startLabel?: string
  endLabel?: string
  location?: string
  url?: string
}

const Layout: FunctionComponent<ScoreProps> = ({
  status,
  score,
  sets,
  distance,
  distanceToGo,
  roundsToGo,
  sport,
  label,
  title,
  date,
  typeIcon,
  layout = ScoreLayoutTypes.Default,
  winner,
  winners,
  startLabel,
  endLabel,
  url,
  darkMode
}) => {
  const scoreLayout: {[Key in SportTypes]: {[Key in ScoreLayoutTypes]: JSX.Element} } = {
    soccer: {
      default: <DefaultScore score={score} label={label} />,
      simple: <SimpleScore score={score} label={label} />,
      detailed: <DetailedScore score={score} label={label} />,
      result: <></>,
      banner: <BannerScore score={score} label={label} title={title} date={date} />
    },
    hockey: {
      default: <DefaultScore score={score} label={label} />,
      simple: <SimpleScore score={score} label={label} />,
      detailed: <DetailedScore score={score} label={label} />,
      result: <></>,
      banner: <BannerScore score={score} label={label} title={title} date={date} />
    },
    handball: {
      default: <DefaultScore score={score} label={label} />,
      simple: <SimpleScore score={score} label={label} />,
      detailed: <DetailedScore score={score} label={label} />,
      result: <></>,
      banner: <BannerScore score={score} label={label} title={title} date={date} />
    },
    tennis: {
      default: <DefaultSets sport={sport} sets={sets} label={label} />,
      simple: <SimpleSets sport={sport} sets={sets} label={label} />,
      detailed: <DetailedSets sport={sport} sets={sets} label={label} />,
      result: <></>,
      banner: <BannerSets sport={sport} sets={sets} label={label} title={title} date={date} darkMode={darkMode} />
    },
    basketball: {
      default: <DefaultSets sport={sport} sets={sets} label={label} />,
      simple: <SimpleSets sport={sport} sets={sets} label={label} />,
      detailed: <DetailedSets sport={sport} sets={sets} label={label} />,
      result: <></>,
      banner: <BannerSets sport={sport} sets={sets} label={label} title={title} date={date} darkMode={darkMode} />
    },
    volleyball: {
      default: <DefaultSets sport={sport} sets={sets} label={label} />,
      simple: <SimpleSets sport={sport} sets={sets} label={label} />,
      detailed: <DetailedSets sport={sport} sets={sets} label={label} />,
      result: <></>,
      banner: <BannerSets sport={sport} sets={sets} label={label} title={title} date={date} darkMode={darkMode} />
    },
    cycling: {
      default: distance
        ? <DefaultDistance
              distance={distance}
              distanceToGo={distanceToGo}
              label={label}
              typeIcon={status === StatusTypes.Live ? typeIcon : undefined}
          />
        : <DefaultRounds roundsToGo={roundsToGo} label={label} />,
      simple: distance
        ? <SimpleDistance
              distance={distance}
              distanceToGo={distanceToGo}
              label={label}
              typeIcon={status === StatusTypes.Live ? typeIcon : undefined}
          />
        : <SimpleRounds roundsToGo={roundsToGo} label={label} />,
      detailed: distance
        ? <DetailedDistance
              distance={distance}
              distanceToGo={distanceToGo}
              label={label}
              typeIcon={status === StatusTypes.Live ? typeIcon : undefined}
          />
        : <DetailedRounds roundsToGo={roundsToGo} label={label} />,
      result: distance
        ? <ResultDistance winner={winner} distance={distance} distanceToGo={distanceToGo} startLabel={startLabel} status={status}/>
        : <ResultRounds winner={winner} roundsToGo={roundsToGo} startLabel={startLabel} status={status} url={url} />,
      banner: distance
          ? <BannerDistance
              date={date}
              winners={winners}
              distance={distance}
              distanceToGo={distanceToGo}
              startLabel={startLabel}
              endLabel={endLabel}
              darkMode={darkMode}
          />
          : <BannerRounds
              date={date}
              roundsToGo={roundsToGo}
              startLabel={startLabel}
              status={status}
              darkMode={darkMode}
          />
    },
    formula1: {
      default: <DefaultRounds roundsToGo={roundsToGo} label={label} />,
      simple: <SimpleRounds roundsToGo={roundsToGo} label={label} />,
      detailed: <DetailedRounds roundsToGo={roundsToGo} label={label} />,
      result: <ResultRounds winner={winner} roundsToGoIcon={'formula1-laps'} roundsToGo={roundsToGo} startLabel={startLabel} status={status}/>,
      banner: <BannerRounds
          date={date}
          roundsToGo={roundsToGo}
          startLabel={startLabel}
          status={status}
          darkMode={darkMode}
      />
    },
    formule1: {
      default: <DefaultRounds roundsToGo={roundsToGo} label={label} />,
      simple: <SimpleRounds roundsToGo={roundsToGo} label={label} />,
      detailed: <DetailedRounds roundsToGo={roundsToGo} label={label} />,
      result: <ResultRounds winner={winner} roundsToGoIcon={'formula1-laps'} roundsToGo={roundsToGo} startLabel={startLabel} status={status}/>,
      banner: <BannerRounds
          date={date}
          roundsToGo={roundsToGo}
          startLabel={startLabel}
          status={status}
          darkMode={darkMode}
      />
    }
  }

  return sport && scoreLayout[sport as SportTypes]
    ? scoreLayout[sport as SportTypes][layout as ScoreLayoutTypes]
    : null
}

const scoreClass: { [Key in SportTypes]: string } = {
  soccer: styles.score,
  hockey: styles.score,
  handball: styles.score,
  tennis: styles.sets,
  basketball: styles.sets,
  volleyball: styles.sets,
  cycling: styles.distanceOrRounds,
  formula1: styles.distanceOrRounds,
  formule1: styles.distanceOrRounds
}

const Score: FunctionComponent<ScoreProps> = (
  props
) => {
  const { status, layout, darkMode, sport, children } = props

  const notStarted = () => {
    return status === StatusTypes.NotStarted || status === StatusTypes.AfterToday
  }

  const ended = () => {
    return status === StatusTypes.End || status === StatusTypes.SuspendedIndefinitely || status === StatusTypes.Cancelled
  }

  return <div className={clsx(
    scoreClass[sport as SportTypes],
    status === StatusTypes.Live && styles.live,
    ended() && styles.end,
    notStarted() && styles.notStarted,
    scoreLayoutStyles[layout as ScoreLayoutTypes],
    darkMode ? styles.darkMode : undefined,
  )}>
    <Layout {...props} />
    {children}
  </div>
}

export default Score

export {
  SportTypes,
  StatusTypes,
  ScoreLayoutTypes
}

export type {
  ScoreProps,
}

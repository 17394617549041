import type { FunctionComponent, PropsWithChildren, ReactElement, ReactNode } from 'react'

import clsx from 'clsx'
import { Children, cloneElement } from 'react'

import styles from './list.module.scss'

enum ListDirection {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical'
}

enum ListAlignment {
  Left = 'Left',
  Center = 'Center',
  Right = 'Right'
}

interface ListProps extends PropsWithChildren {
  direction?: string | ListDirection
  align?: string | ListAlignment
  className?: string
  darkMode?: boolean
}

const childrenWithExtraProps = (children: ReactNode) => {
  const childrenArr = Children.toArray(children)

  if (!childrenArr.length) {
    return null
  }

  let firstChild = childrenArr[0]

  if (firstChild) {
    firstChild = childrenArr[0] = cloneElement(firstChild as ReactElement, { className: clsx(firstChild.props.className, styles.firstChild) })

    const secondChild = childrenArr[1] as ReactElement

    if (
      secondChild
      && (
        (firstChild.props.desktopOnly && !secondChild.props.desktopOnly)
        || (firstChild.props.mobileOnly && !secondChild.props.mobileOnly)
      )
    ) {
      childrenArr[1] = cloneElement(
        secondChild as ReactElement,
        {
          className: secondChild.props.className
            ? clsx(secondChild.props.className, styles.firstChild)
            : styles.firstChild
        }
      )
    }
  }

  return childrenArr
}

const List: FunctionComponent<ListProps> = (
  {
    direction= ListDirection.Horizontal,
    align = ListAlignment.Left,
    children,
    className,
    darkMode
  }
) => {
  return <span className={clsx(
    styles.list,
    direction === ListDirection.Horizontal && styles.listHorizontal,
    direction === ListDirection.Vertical && styles.listVertical,
    align === ListAlignment.Left && styles.listAlignLeft,
    align === ListAlignment.Center && styles.listAlignCenter,
    align === ListAlignment.Right && styles.listAlignRight,
    className,
    darkMode && styles.tagDark
  )}>
    {childrenWithExtraProps(children)}
  </span>
}

export default List

export {
  ListDirection,
  ListAlignment
}

export type {
  ListProps
}

import type { FunctionComponent, PropsWithChildren, ReactElement } from 'react'
import type { LinkListComponentProps } from '../link-list'
import type { ScoreboardComponentProps } from '../scoreboard'

import clsx from 'clsx'

import styles from './calendar.module.scss'
import Default from './layout'

type CalendarLayoutTypes =
  'default'

type CalendarItemType = {
  label: string,
  type?: string,
  icon?: string,
  items: CalendarItemType[] | ScoreboardComponentProps[] | LinkListComponentProps[]
}

type CalendarDataType = CalendarItemType[]

enum CalendarType {
  Full = 'full',
  Today = 'today'
}

interface CalendarProps extends PropsWithChildren {
  sportApiUrl: string,
  data: CalendarDataType,
  layout?: CalendarLayoutTypes,
  date?: string
  title?: string
  dates?: string[]
  type?: CalendarType
}

const Layout: FunctionComponent<CalendarProps> = ({
  sportApiUrl,
  data,
  layout= 'default',
  date,
  title,
  dates,
  type = CalendarType.Full
}) => {
  const calendarLayout: {[Key in CalendarLayoutTypes]: ReactElement} = {
      'default': <Default title={title} data={data} date={date} dates={dates} type={type} sportApiUrl={sportApiUrl} />
  }

  return calendarLayout[layout]
}

const Calendar: FunctionComponent<CalendarProps> = (
  props
) => {
  return <div
    className={clsx(
        styles.calendar
    )}>
    <Layout {...props} />
  </div>
}

export default Calendar

export type {
  CalendarProps,
  CalendarDataType,
  CalendarItemType
}

export {
  CalendarType
}

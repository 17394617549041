import type { FunctionComponent } from 'react'
import type { BracketRoundProps } from './bracket-round'

import { ArrayUtils } from '@sporza/utils'
import clsx from 'clsx'
import { useState } from 'react'

import commonStyles from '../bracket.module.scss'
import { isRoundRobin } from '../helpers'

import BracketNavigation from './bracket-navigation'
import styles from './bracket-phase.module.scss'
import BracketRound from './bracket-round'

interface BracketPhaseProps {
  label?: string
  items?: BracketRoundProps[]
  activeItem?: number
}

const BracketPhase: FunctionComponent<BracketPhaseProps> = (
  {
    items= [],
    activeItem = 0
  }
) => {
  const [activeRound, setActiveRound] = useState<Record<any, any>>({ index: activeItem, subIndex: 0 })

  return <>
    <BracketNavigation
      rounds={items}
      activeRound={activeRound.index}
      activeSubRound={activeRound.subIndex}
      onNavigate={(index: number, subIndex?: number) => setActiveRound({ index, subIndex })}
    />
    <ul className={clsx(
      commonStyles.resetList,
      styles.phase,
    )}>
      {
        items
          .reduce((acc: any[], round, index, origArr) => {
            const isRoundRobinRound = isRoundRobin(round, origArr[index + 1])
            const isPrevRoundRobinRound = isRoundRobin(origArr[index - 1], round)
            const shouldSplitMatches = round.items?.length > 8
            const shouldSplitPrevMatches = origArr[index - 1]?.items?.length > 8

            if (
              shouldSplitMatches
              && index === activeRound.index
              || (
                !isPrevRoundRobinRound
                && shouldSplitPrevMatches
                && index - 1 === activeRound.index // if previous round is active, show next one (which is current iteration)
              )
            ) {
              const itemParts = ArrayUtils.splitInEqualParts(round.items, 2)

              acc.push(
                <BracketRound
                  key={`${round.label}-${index}-${activeRound.subIndex}`}
                  {...round}
                  label={round.label + ' deel ' + (activeRound.subIndex + 1)}
                  items={itemParts[activeRound.subIndex]}
                  className={
                    clsx({
                      [commonStyles.hideOnMobile]: acc.length > 0,
                      [styles.roundRobin]: isRoundRobinRound
                    })
                  }
                />
              )
            } else if (
              !shouldSplitMatches
              && (
                index === activeRound.index
                || index - 1 === activeRound.index
              )
            ) {
              acc.push(
                <BracketRound
                  key={`${round.label}-${index}`}
                  {...round}
                  className={
                    clsx({
                      [commonStyles.hideOnMobile]: acc.length > 0,
                      [styles.roundRobin]: isRoundRobinRound
                      || index - 1 === activeRound.index
                      && isPrevRoundRobinRound
                    })
                  }
                />
              )
            }

            return acc
          }, [])
      }
    </ul>
  </>
}

export default BracketPhase

export {
  BracketPhase
}

export type {
  BracketPhaseProps
}

type WielermanagerRankings = {
  previousRank: number,
  currentRank: number,
  currentScore: number,
  previousScore: number,
  rankDelta: number,
  scoreDelta: number,
  winners: string[]
}

class PersonalisationService {
  protected readonly baseUrl: string

  constructor(apiHost?: string) {
    this.baseUrl = `${apiHost}/sps/1`
  }

  public fetchWielermanagerRankings = (
    source = 'TOUR',
    year: string | number = new Date().getFullYear(),
    stage?: string,
    marketingId?: string
  ): WielermanagerRankings => {
    const fetchURl = new URL(`${this.baseUrl}/cycling-manager/rankings?source=${source}&year=${year}`)

    stage && fetchURl.searchParams.set('match', stage)
    marketingId && fetchURl.searchParams.set('marketingId', marketingId)

    return this.fetchPersonalisation(fetchURl.toString()) as unknown as WielermanagerRankings
  }

  public fetchPersonalisation = async (url: string) => {
    return fetch(url)
      .then(response => response.json())
  }
}

export default PersonalisationService

export type {
  WielermanagerRankings
}

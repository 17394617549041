import type { FunctionComponent } from 'react'
import type { EmbedQualifioComponentProps } from '../../embed-qualifio'
import type { BaseEventProps } from './base-event'

import clsx from 'clsx'

import Category from '../../../atoms/category'
import Meta from '../../../atoms/meta'
import Pill from '../../../molecules/pill'
import EmbedQualifio from '../../embed-qualifio'
import { TimelineMode } from '../config'

import styles from './events.module.scss'

interface QualifioEventProps extends BaseEventProps {
  qualifio: EmbedQualifioComponentProps
  autoLoad?: boolean
}

const QualifioEvent: FunctionComponent<QualifioEventProps> = (
  {
    id,
    timestamp,
    label,
    subLabel,
    qualifio,
    autoLoad = false,
    isEditable,
    mode = TimelineMode.Default
  }
) => {
  const isDefault = mode === TimelineMode.Default
  const isTeaser = mode === TimelineMode.Teaser

  return <div
    className={clsx(
      isDefault && styles.event,
      isTeaser && styles.eventTeaser,
      'sw-timeline-item'
    )}
    data-event-id={id}
    data-is-editable={isEditable ? true : undefined}
  >
    <span className={styles.eventAnchor} id={`timeline-${timestamp}`}></span>

    {
      isDefault
        ?
        <div className={styles.labelContainer}>
          {label && <Pill {...label} />}
        </div>
        : null
    }

    <div className={styles.eventContent}>
      {
        subLabel
          && isDefault
          && <Category className={styles.subLabel}>{subLabel.label}</Category>
      }
      {qualifio && <EmbedQualifio {...qualifio.componentProps} autoLoad={autoLoad} />}
    </div>

    {
      isTeaser
        ?
        <div className={styles.eventFooter}>
          {label?.label && <Meta iconBefore={label.iconBefore}>{label.label}</Meta>}
          {subLabel && <Pill {...subLabel} iconBefore={undefined} />}
        </div>
        : null
    }
  </div>
}

export default QualifioEvent

export type {
  QualifioEventProps,
}

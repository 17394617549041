import type { FunctionComponent } from 'react'

import Title from '../../atoms/title'
import Table from '../../organisms/table'
import { ColumnDefWithCellType } from '../../organisms/table/layouts/table'

import styles from './intermediates.module.scss'

interface IntermediateProps {
  label: string,
  data: any[],
}

interface IntermediatesProps {
  items: IntermediateProps[],
  columns: ColumnDefWithCellType[]
  limit?: number
}

const Intermediates: FunctionComponent<IntermediatesProps> = ({ items, columns, limit = 3 }) =>
    <ol className={styles.intermediates}>
        {items && items.map((item, index) => <li key={`${index}-${item.label}`} className={styles.intermediate}>
            <Title>{item.label}</Title>
            <Table data={item.data} columns={columns} expandable={true} limit={limit} />
        </li>)}
    </ol>

export default Intermediates

export type {
  IntermediateProps,
  IntermediatesProps
}

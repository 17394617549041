import type { FunctionComponent } from 'react'
import type { FilterProps } from '../../molecules/filter'
import type { UseApiProps } from '@sporza/hooks/use-api'

import { Theme } from '@sporza/config'
import clsx from 'clsx'

import { StatusTypes } from '../../molecules/score'

import { TimelineDefaultComponent } from './layout/default'
import { TimelineTeaserComponent } from './layout/teaser'
import styles from './timeline.module.scss'

enum TimelineType {
  Default = 'DEFAULT',
  Full = 'FULL'
}

enum TimelineLayouts {
  Default = 'default',
  Teaser = 'teaser'
}

interface TimelineProps extends UseApiProps {
  timeline: {
    label?: string
    type?: TimelineType | string
    filter?: FilterProps
    items?: any[]
    autoLoad?: boolean
    sport?: string
  }
  author?: boolean
  status?: StatusTypes
  layout?: TimelineLayouts | string
  link?: string
  linkText?: string
  theme?: Theme
  className?: string
}

const layout = {
  [TimelineLayouts.Default]: TimelineDefaultComponent,
  [TimelineLayouts.Teaser]: TimelineTeaserComponent
}

const Timeline: FunctionComponent<TimelineProps> = (props) => {
  const Layout = layout[props.layout as keyof typeof layout] || layout[TimelineLayouts.Default]
  const { theme } = props

  return <Layout
    {...props}
    className={
      clsx(
        theme === Theme.OlympischeSpelen2024 && styles.os2024,
        theme === Theme.ParalympischeSpelen2024 && styles.os2024,
      )
    }
  />
}

export type {
  TimelineProps,
}

export {
  Timeline,
  TimelineType,
  TimelineLayouts
}

import type { FunctionComponent } from 'react'
import type { AZListListType, AZListProps } from '../index'

import { Fragment, useEffect, useState } from 'react'

import Title from '../../../atoms/title'
import Cell from '../../cell'
import Grid from '../../grid'
import LinkList from '../../link-list'
import Shortcuts from '../../shortcuts'
import Tab from '../../tab'

const Default: FunctionComponent<AZListProps> = (
  {
    data
  }
) => {
  const getLetters: any = (arr: []) => arr.map((item: any) => item?.title?.toLowerCase() )

  const [letters, setLetters] = useState<[]>(getLetters(data[0].items))
  const [active, setActive] = useState<number>(0)

  useEffect(() => {
    setLetters(getLetters(data[active].items))
  }, [data, active])

  const LetterList: FunctionComponent<{data: AZListListType}> = ({ data }) => {
    return <>
      <Title size='Large' link={data.href}>{data.title}</Title>
      <LinkList direction='Horizontal' buttonSize='medium' items={data.items} />
    </>
  }

  const items: any = () => {
    return data?.map((item) => {
      return {
        label: item.label,
        content: item.items.map((item, index) => <Fragment key={`${index}-${item.title}`}>
          <LetterList data={item}/>
          </Fragment>
        )
      }
    })
  }

  return <Grid collapseX>
    <Cell size={3}>
      <Tab label='toon' items={items()} callback={setActive} activeIndex={active} />
    </Cell>
    <Cell size={1}>
      <Shortcuts items={letters} />
    </Cell>
  </Grid>
}

export default Default

export type {
  AZListProps
}

import type { CSSProperties, FunctionComponent } from 'react'

import clsx from 'clsx'

import Icon from '../../../atoms/icon'
import Meta from '../../../atoms/meta'
import Image from '../../../molecules/image'
import customCellStyles from '../components/custom.cell.module.scss'

import styles from './../layouts/table.module.scss'

interface CellWithImageProps {
  name?: string
  shortName?: string
  href?: string
  imageUrl?: string
  column?: any
  options?: any
  hideImages?: boolean
  noCrop?: boolean
  setBoxShadow?: boolean,
  icon?: string
  subName?: string
}

const CellWithImage: FunctionComponent<CellWithImageProps> = (
  {
    name,
    shortName,
    href,
    imageUrl,
    column = {},
    options = {},
    hideImages = false,
    noCrop,
    setBoxShadow,
    icon,
    subName
  }
) => {
  const ElementTag = href ? 'a' : 'div'
  const width = options.imageSize ? options.imageSize / 10 + 'rem' : undefined
  const displayName = name && shortName
    ? <>
      <span className={styles.hideOnMobile}>{name}</span>
      <span className={styles.hideOnDesktop}>{shortName}</span>
    </>
    : <div className={styles.name}>
      <span>{name || shortName}</span>
      <span className={clsx(styles.hideOnDesktop, styles.subName)}>{subName}</span>
    </div>

  return (
    <ElementTag
      href={href}
      className={clsx(
        styles.customCell,
        styles.customCellWithImage,
        href && styles.customCellWithLink,
        setBoxShadow && styles.boxShadow,
        options.vertical && styles.vertical,
        options.fontMedium && customCellStyles.fontMedium
      )}
      style={{ '--table-cell-image-width': width } as CSSProperties}
    >
      {
        icon && !hideImages
          ? <Icon ariaHidden name={icon} style={{ '--icon-size': width } as CSSProperties}/>
          : imageUrl && !hideImages
            ? <Image
              className={styles.image}
              src={imageUrl}
              noCrop={noCrop || options?.noCrop}
              priority={true}
            />
            : <div style={{ width: width }}/>
      }
      {
        options.vertical
          ? <Meta>{displayName}</Meta>
          : displayName
      }
    </ElementTag>
  )
}

export type { CellWithImageProps }
export default CellWithImage

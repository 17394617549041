import type { FunctionComponent } from 'react'
import type { ScoreSetsProps } from '../default'

import clsx from 'clsx'

import commonStyles from '../../score.module.scss'

import styles from './simple.module.scss'

type ScoreSimpleSetsProps = ScoreSetsProps
const SimpleSets: FunctionComponent<ScoreSimpleSetsProps> = (
  {
    sets,
    label,
  }
) => {
  return <>
    {
      label
      && <div className={clsx(commonStyles.label, styles.simpleLabel)}>{label}</div>
    }
    {
      sets
        ?.map((set, index: number) =>
          <span className={commonStyles.numbers} key={index}>
            <span className={clsx(commonStyles.number, commonStyles.withSeparator)}>{set?.home}</span>
            <span className={commonStyles.number}>{set?.away}</span>
          </span>
        )
    }
  </>
}

export default SimpleSets

export type {
  ScoreSimpleSetsProps
}

import type { FunctionComponent, PropsWithChildren } from 'react'

import clsx from 'clsx'

import styles from './cell.module.scss'

interface CellProps extends PropsWithChildren {
  tag?: string
  className?: string
  size?: number
  align?: 'center' | 'left' | 'right'
}

const Cell: FunctionComponent<CellProps> = (
  {
    tag,
    children,
    className,
    size = 4,
    align = 'left'
  }
) => {

  const Tag = tag || 'div'

  return <Tag
    className={clsx(
      size === 1 && styles.cell1,
      size === 2 && styles.cell2,
      size === 3 && styles.cell3,
      size === 4 && styles.cell4,

      size === 1.5 && styles.cell15,
      size === 2.5 && styles.cell25,

      align === 'center' && styles.center,

      className
    )}
  >
    {children}
  </Tag>
}

export default Cell

export type {
  CellProps
}

import type { FunctionComponent } from 'react'
import type { TabOption } from '../../../tab'
import type { BracketPhaseProps } from './components'

import Title, { TitleElement, TitleSize } from '../../../../atoms/title'
import Tab from '../../../tab'

import styles from './bracket.module.scss'
import { BracketPhase } from './components'

interface MatchdayBracketProps {
  title?: string
  items?: BracketPhaseProps[]
}

const MatchdayBracket: FunctionComponent<MatchdayBracketProps> = (
  {
    title,
    items = []
  }
) => {
  const titleElement = title
    && <Title size={TitleSize.Large} tag={TitleElement.H2}>{title}</Title>

  const tabs: TabOption[] = items.map(
    (bracketPhase) => {
      return {
        label: bracketPhase.label,
        content: <BracketPhase {...bracketPhase} />
      }
    }
  )

  return <div className={styles.bracket}>
    <div className={styles.titleWrapper}>
      <div>
        {titleElement}
      </div>
    </div>
    <div className={styles.matchday}>
      <Tab items={tabs}/>
    </div>
  </div>
}

export default MatchdayBracket

export type {
  MatchdayBracketProps
}

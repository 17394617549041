import type { FunctionComponent } from 'react'
import type { BaseEventProps } from './base-event'

import { EventType } from '../config'
import { TimelineType } from '../timeline'

import ArticleEvent from './article-event'
import BaseEvent from './base-event'
import InstagramEvent from './instagram-event'
import MediaplayerEvent from './mediaplayer-event'
import PlayerEvent from './player-event'
import QualifioEvent from './qualifio-event'
import QuoteEvent from './quote-event'
import ScoreboardEvent from './scoreboard-event'
import SeparatorEvent from './separator-event'
import SubstitutionEvent from './substitution-event'
import ThreadsEvent from './threads-event'
import TwitterEvent from './twitter-event'

type EventProps = BaseEventProps & {
  id: string
  timestamp: number
  type: EventType
  timelineType: TimelineType
}

const eventMap: Record<EventType, FunctionComponent<any>> = {
  [EventType.ARTICLE]: ArticleEvent,
  [EventType.BASE]: BaseEvent,
  [EventType.INSTAGRAM]: InstagramEvent,
  [EventType.MEDIAPLAYER]: MediaplayerEvent,
  [EventType.PLAYER]: PlayerEvent,
  [EventType.QUOTE]: QuoteEvent,
  [EventType.SCOREBOARD]: ScoreboardEvent,
  [EventType.SEPARATOR]: SeparatorEvent,
  [EventType.SUBSTITUTION]: SubstitutionEvent,
  [EventType.THREADS]: ThreadsEvent,
  [EventType.TWITTER]: TwitterEvent,
  [EventType.QUALIFIO]: QualifioEvent,
}

const DynamicEvent: FunctionComponent<EventProps> = (
  {
    type,
    timelineType,
    autoLoad = false,
    ...props
  }
) => {
  const SelectedComponent = eventMap[type] || eventMap[EventType.BASE]

  return <SelectedComponent
    type={type}
    timelineType={timelineType}
    autoLoad={autoLoad}
    {...props}
  />
}

export default DynamicEvent

export type {
  EventProps
}

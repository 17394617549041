import { useEffect,useState } from 'react'

interface FeatureToggle {
  enabled: boolean;
  [key: string]: any;
}

const useFeatureToggle = (name: string): [FeatureToggle, (value: boolean, extras?: Record<string, any>) => void] => {
  const prefix = 'sporza.feature.'
  const [featureToggle, setFeatureToggle] = useState<FeatureToggle>(() => {
    const featureString = typeof localStorage !== 'undefined' && localStorage.getItem(prefix + name)
    return featureString ? JSON.parse(featureString) : { enabled: false }
  })

  useEffect(() => {
    localStorage.setItem(prefix + name, JSON.stringify(featureToggle))
  }, [name, featureToggle])

  const toggleFeature = (value: boolean, extras: Record<string, any> = {}) => {
    setFeatureToggle({
      ...featureToggle,
      ...extras,
      enabled: value,
    })
  }

  return [featureToggle, toggleFeature]
}

export default useFeatureToggle

import type { FunctionComponent } from 'react'
import type { PlayernameProps } from '../../../../molecules/playername'
import type { TeamnameProps } from '../../../../molecules/teamname'
import type { ScoreboardScoreProps } from '../default'

import clsx from 'clsx'

import Caption from '../../../../atoms/caption'
import Icon from '../../../../atoms/icon'
import Meta, { MetaWeight } from '../../../../atoms/meta'
import Playername from '../../../../molecules/playername'
import Score, { ScoreLayoutTypes } from '../../../../molecules/score'
import Teamname from '../../../../molecules/teamname'

import styles from './common.event.module.scss'

interface ScoreboardEventScoreProps extends ScoreboardScoreProps {
  title?: string
  player?: PlayernameProps['player']
}

const EventScoreBoard: FunctionComponent<ScoreboardEventScoreProps> = (
  {
    sport,
    title,
    home,
    away,
    score,
    player,
    label,
    status,
    designSystemBaseUrl
  }
) => {
  return <div className={styles.scoreboard}>
    {
      title
      && <Meta
        weight={MetaWeight.Medium}
        className={styles.title}
      >{title}</Meta>
    }

    <div className={styles.score}>
      <Teamname
        size="Large"
        fallbackImage={`${designSystemBaseUrl}/images/placeholders/team.svg`}
        sport={sport}
        team={home as TeamnameProps['team']}
        showLogos={true}
        showLinks={true}
        logoPosition="top"
      />

      <Score layout={ScoreLayoutTypes.Default}
             score={score}
             sport={sport}
             status={status}
             label={label}
      />

      <Teamname
        size="Large"
        fallbackImage={`${designSystemBaseUrl}/images/placeholders/team.svg`}
        sport={sport}
        team={away as TeamnameProps['team']}
        showLogos={true}
        showLinks={true}
        logoPosition="top"
        isAway={true}
      />
    </div>
    {
      player
      && <div className={clsx(
        styles.player,
        player.isAway && styles.isAway
      )}>
        <Playername
          player={player}
          showLinks={true}
          className={styles.playerName}
        />
        <Caption className={styles.playerTeam}>{player.isAway ? away.name : home.name}</Caption>
        {player.iconBefore && <Icon name={player.iconBefore} className={styles.playerIcon} />}
      </div>

    }
  </div>
}

export {
  EventScoreBoard
}

export type {
  ScoreboardEventScoreProps
}

import type { ClassValue } from 'clsx'
import type { FunctionComponent, ReactNode } from 'react'

import { DataListComponentProps } from '../../molecules/data-list'

import { DataCardHorizontal } from './layout/horizontal'
import { DataCardWithNumber } from './layout/with-number'
import { DataCardDefault, DataCardWithHeader } from './layout'

enum DataCardLayouts {
  Default = 'default',
  WithHeader = 'withHeader',
  Horizontal = 'horizontal',
  WithNumber = 'withNumber'
}

interface DataCardComponentProps {
  componentType: string
  componentProps: DataCardProps
}

interface DataCardProps {
  header?: string
  team?: any
  layout?: DataCardLayouts
  title?: string
  img?: string
  imageChildren?: ReactNode
  subTitle?: string
  nationality?: {
    flagUrl?: string
    name?: string
  }
  dataList?: DataListComponentProps[][]
  link?: string
  className?: ClassValue
  square?: boolean
}

const layoutComponents = {
  [DataCardLayouts.Default]: DataCardDefault,
  [DataCardLayouts.WithHeader]: DataCardWithHeader,
  [DataCardLayouts.Horizontal]: DataCardHorizontal,
  [DataCardLayouts.WithNumber]: DataCardWithNumber
}

const DataCard: FunctionComponent<DataCardProps> = (
  props
) => {
  const Layout = layoutComponents[props.layout as keyof typeof layoutComponents] || layoutComponents[DataCardLayouts.Default]

  return <Layout {...props} />
}

export default DataCard

export type {
  DataCardComponentProps,
  DataCardProps
}

export {
  DataCardLayouts
}

import type { FunctionComponent } from 'react'
import type {
  IedereenBondscoachSelectionProps,
  PotsGroupsProps,
  PotsMatchesProps,
  SelectionProps
} from './index'

import { useApi } from '@sporza/hooks'

import {
  DrawType,
  IedereenBondscoachSelection,
  PotsGroups,
  PotsMatches,
  Selection } from './index'

interface DrawProps extends PotsGroupsProps, PotsMatchesProps, SelectionProps, IedereenBondscoachSelectionProps {
  [key: string]: any
  type?: DrawType
  url?: string
  status?: string
  apiBaseUrl?: string
}

const layoutComponents = {
  [DrawType.PotsGroups]: PotsGroups,
  [DrawType.PotsMatches]: PotsMatches,
  [DrawType.Selection]: Selection,
  [DrawType.IedereenBondscoachSelection]: IedereenBondscoachSelection,
  default: PotsGroups
}

const Draw: FunctionComponent<DrawProps> = (props) => {
  const { data } = useApi(
    props,
    {
      enableByStatus: true,
      sportApiUrlKey: 'url',
      defaultBaseUrl: props.apiBaseUrl
    }
  )

  const Layout = layoutComponents[props.type as keyof typeof layoutComponents] || layoutComponents.default

  return <Layout {...data} designSystemBaseUrl={props.designSystemBaseUrl} />
}

export default Draw

export type {
  DrawProps
}


import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import styles from './../layouts/table.module.scss'


interface CellTextProps {
  name: string,
  href?: string
  options?: any
}

const CellText: FunctionComponent<CellTextProps> = (
  {
    name,
    href,
    options = {}
  }
) => {
  const ElementTag = href ? 'a' : 'span'
  return <ElementTag
    className={clsx(options.hideOnMobile && styles.hideOnMobile)}
    style={{
      color: options.color,
      fontWeight: options.fontWeight,
      fontSize: options.fontSize ? `${options.fontSize / 10}rem` : undefined
    }}>
    {name}
  </ElementTag>


}
export default CellText

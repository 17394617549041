import type { FunctionComponent } from 'react'
import type { ScoreScoreProps } from '../default'

import clsx from 'clsx'

import Caption from '../../../../atoms/caption'
import commonStyles from '../../score.module.scss'

import styles from './simple.module.scss'

type ScoreSimpleScoreProps = ScoreScoreProps

const SimpleScore: FunctionComponent<ScoreSimpleScoreProps> = (
  {
    label,
    score
  }
) => {
  return <>
    {label && <Caption className={clsx(commonStyles.label, styles.simpleLabel)}>{label}</Caption>}
    {score && <div className={commonStyles.numbers}>
      <span className={clsx(commonStyles.number, commonStyles.withSeparator)}>
        {score?.home}
        {score?.shootout?.home !== undefined && <sup className={commonStyles.shootout}>{score?.shootout?.home}</sup>}
      </span>
      <span className={commonStyles.number}>
        {score?.away}
        {score?.shootout?.away !== undefined && <sup className={commonStyles.shootout}>{score?.shootout?.away}</sup>}
      </span>
    </div>}
  </>
}

export default SimpleScore

export type {
  ScoreSimpleScoreProps
}

import type { FunctionComponent, PropsWithChildren } from 'react'

import clsx from 'clsx'

import styles from './category.module.scss'

interface CategoryProps extends PropsWithChildren {
  className?: string
}

const Category: FunctionComponent<CategoryProps> = (
  {
    children,
    className
  }
) => {
  const TagElement = 'div'

  return <TagElement
    className={clsx(
      styles.category,
      className
    )}
  >
    {children}
  </TagElement>
}

export default Category

import type { FunctionComponent } from 'react'
import type { CellWithImageProps } from './cell-with-image'

import { CellWithImage } from './index'

interface CellWithLogoProps extends CellWithImageProps {
  logoUrl?: string
}
const CellWithLogo: FunctionComponent<CellWithLogoProps> = ({ logoUrl, ...props }) => {

  return <CellWithImage imageUrl={logoUrl} {...props}/>
}
export default CellWithLogo

import type { FunctionComponent } from 'react'

import Icon from '../../../atoms/icon'
import styles from '../layouts/table.module.scss'

interface CellRankProps {
  current: string,
  diffToPrevious?: number
}

const getIconConfig = (diffToPrevious?: number) => {
  if (!diffToPrevious)
    return

  switch (true) {
    case diffToPrevious < 0:
      return {
        name: 'arrow-down',
        className: styles.diffToPreviousDown
      }
    case diffToPrevious > 0:
      return {
        name: 'arrow-up',
        className: styles.diffToPreviousUp
      }
    default:
      return
  }
}

const CellRank: FunctionComponent<CellRankProps> = ({ current, diffToPrevious }) => {
  const iconConfig = getIconConfig(diffToPrevious)

  return (
    <>
      {current}
      {
        diffToPrevious
          ? <span className={styles.diffToPrevious}>
            {iconConfig && <Icon name={iconConfig.name} className={iconConfig.className} />}
            {Math.abs(diffToPrevious)}
          </span>
          : false
      }
    </>
  )
}
export default CellRank

import type { FunctionComponent } from 'react'

import clsx from 'clsx'
import { useEffect, useState } from 'react'

import Title, { TitleElement } from '../../../atoms/title'
import Button from '../../../molecules/button'
import Pill from '../../../molecules/pill'
import { DrawItemType } from '../config'

import { ResultItem } from './index'
import styles from './result-matches.module.scss'

interface ResultMatchProps {
  type?: DrawItemType
  name?: string
  objects?: any[]
  availableSlots?: number
  sport?: string
  open?: boolean
}

function fillGroups(groups: any[] = [], availableSlots?: number) {
  if (!availableSlots)
    return groups

  const existingPositions = new Set(groups.map(obj => obj.position))
  let emptyPosition = 0

  while (groups.length < availableSlots) {
    while (existingPositions.has(emptyPosition)) {
      emptyPosition++
    }

    groups.push({ position: emptyPosition, name: '---' })
    existingPositions.add(emptyPosition)
  }

  groups.sort((a, b) => a.position - b.position)

  return groups
}

const ResultMatch: FunctionComponent<ResultMatchProps> = (
  {
    type,
    name,
    objects,
    availableSlots,
    sport,
    open,
  }
) => {
  const [init, setInit] = useState<boolean>(false)
  const [active, setActive] = useState<boolean>(false)
  const [groups, setGroups] = useState(fillGroups(objects, availableSlots))
  const [hasNewItem, setHasNewItem] = useState<boolean>(false)
  const [previousLength, setPreviousLength] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (!init) {
      setInit(true)
    }
  }, [])

  useEffect(() => {
    setActive(open || false)
  }, [open])

  useEffect(() => {
    if (!objects || !init)
      return

    const currentLength = objects
      .filter((obj: any) => obj.name !== '---')
      .length

    if (previousLength === undefined) {
      setPreviousLength(currentLength)
    } else if (currentLength > previousLength) {
      setHasNewItem(true)
    }

    setPreviousLength(currentLength || 0)

    const timerId = setTimeout(() => {
      setHasNewItem(false)
    }, 1000)

    setGroups(fillGroups(objects, availableSlots))

    return () => clearTimeout(timerId)
  }, [objects, availableSlots])

  return <div
    key={name}
    className={clsx(
      styles.match,
      hasNewItem && styles.hasNewGroupItem
    )}
  >
    <Button
      variant={'tertiary'}
      className={styles.resultMatchTitle}
    >
      <Title
        className={styles.resultMatch}
        size="Small"
        tag={TitleElement.H3}
      >{name}</Title>
    </Button>

    <div className={clsx(
      styles.content,
      active && styles.contentIsActive
    )}>
      {
        groups?.map((group: any, index) => {

          return <>
            <ResultItem
              key={`${group.name}-${index}`}
              {...group}
              align={index % 2 === 0 ? 'left' : 'right'}
              type={type}
              sport={sport}
            />
            {
              index % 2 === 0
              ? <div className={styles.matchVersus}>
                  <Pill>vs.</Pill>
                </div>
              : false
            }
          </>
        })
      }
    </div>
  </div>
}

export {
  ResultMatch
}

export type {
  ResultMatchProps
}

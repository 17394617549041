import type { FunctionComponent } from 'react'

import Pill, { PillColor } from '../../../molecules/pill'

import styles from './custom.cell.module.scss'

type Tag = {
  label?: string,
  href?: string,
  color?: string
}

interface CellWithTagsProps {
  tags: Tag[],
  column?: any,
  options?: Record<string, any>
}

const CellWithTags: FunctionComponent<CellWithTagsProps> = (
  {
    tags,
    column = {},
    options = {}
  }
) => {
  return <div className={styles.tags}>
    {
      tags.map((tag, index) => {
        return <Pill
          key={`${index}-${tag.label}`}
          text={tag.label}
          color={tag.color as PillColor}
        />
      })
    }
  </div>
}

export default CellWithTags

import type { FunctionComponent } from 'react'
import type { ScoreDistanceProps } from '../default'

import clsx from 'clsx'

import Icon from '../../../../atoms/icon'
import commonStyles from '../../score.module.scss'

import styles from './simple.module.scss'

type ScoreSimpleDistanceProps = ScoreDistanceProps

const SimpleDistance: FunctionComponent<ScoreSimpleDistanceProps> = ({
  distanceToGo,
  typeIcon
}) => {
  return <>
    {distanceToGo && <span className={clsx(commonStyles.label, styles.simpleLabel)}>
      nog {distanceToGo} km
    </span>}
    {typeIcon === 'cycling-stopwatch' && <span className={clsx(commonStyles.label, styles.simpleLabel)}>
      <Icon name={'cycling-stopwatch'} />
    </span>}
  </>
}

export default SimpleDistance

export type {
  ScoreSimpleDistanceProps
}

import type { FunctionComponent } from 'react'

import clsx from 'clsx'
import { useEffect, useState } from 'react'

import Button from '../../../molecules/button'
import Tab from '../../tab'
import { DrawItemType } from '../config'
import commonStyles from '../draw.module.scss'

import { StartItem } from './index'
import styles from './start-groups.module.scss'

interface StartGroupsProps {
  hidden?: boolean
  type?: DrawItemType
  name?: string
  groups?: any[]
  sport?: string
  isLive?: boolean
}

const mapToTabItems = (groups: any[], sport?: string, type?: DrawItemType) => {
  return groups.map((group: any) =>
    ({
      label: group.name,
      content: <div className={styles.group}>
        {
          group?.objects.map((object: any) =>
            <StartItem
              key={object.id}
              {...object}
              type={type}
              sport={sport}
            />
          )
        }
      </div>
    })
  )
}

const getDefaultActiveIndex = (groups?: any[]) => {
  return groups?.reduce((acc: number, group: any, index: number) => {
    if (group?.objects?.some((obj: any) => !!obj.resultGroup)) {
      return index
    }

    return acc
  }, 0) || 0
}

const StartGroups: FunctionComponent<StartGroupsProps> = (
  {
    name,
    groups,
    sport,
    isLive
  }
) => {
  const [activeIndex, setActiveIndex] = useState<number>(0)

  useEffect(() => {
    if (isLive) {
      setActiveIndex(getDefaultActiveIndex(groups))
    } else {
      setActiveIndex(0)
    }
  }, [isLive, JSON.stringify(groups)])

  return <div className={styles.startGroups}>
    {
      groups
        ? <Tab
          title={name}
          titleBefore={
            isLive
              ? <Button
                variant="live"
                iconBefore="live-alt"
                className={clsx(
                  commonStyles.liveButtonDraw,
                  commonStyles.hideOnDesktop
                )}
              >live</Button>
              : false
          }
          showTitleAlways={true}
          activeIndex={activeIndex}
          callback={(activeIndex: number) => setActiveIndex(activeIndex)}
          items={mapToTabItems(groups, sport)}
        />
        : false
    }
  </div>
}

export {
  StartGroups
}

export type {
  StartGroupsProps
}

import type { ColumnDetails } from './layouts/table'
import type { ColumnDef, ColumnPinningState } from '@tanstack/react-table'

import { Dispatch, SetStateAction } from 'react'

const getPinnedColumns = (columns?: ColumnDef<ColumnDetails, any>[]) => {
  return columns?.filter((col: any) => col.pin)
}

const handlePinnedColumns = (
  setState: Dispatch<SetStateAction<ColumnPinningState>>,
  columns?: ColumnDef<ColumnDetails, any>[]
) => {
  if (!columns) {
    return
  }

  const pinnedColumns = getPinnedColumns(columns)

  if (Array.isArray(pinnedColumns)) {
    pinnedColumns
      .forEach((col: any) => {
        switch (col.pin) {
          case 'left':
            setState(oldState => ({
              ...oldState,
              left: Array.isArray(oldState.left)
                ? oldState.left.concat(col.id)
                : [col.accessorKey]
            }))
            break
          case 'right':
            setState(oldState => ({
              ...oldState,
              right: Array.isArray(oldState.right)
                ? oldState.right.concat(col.id)
                : [col.accessorKey]
            }))
            break
          default:
            // invalid pin value
            break
        }
      })
  }
}

export {
  getPinnedColumns,
  handlePinnedColumns
}

import type { FunctionComponent, PropsWithChildren } from 'react'

import clsx from 'clsx'
import { addDays, format, isSameDay, isToday, setDefaultOptions,subDays } from 'date-fns'
import { nl } from 'date-fns/locale'
import { useEffect, useRef, useState } from 'react'

import Button from '../button'

import styles from './datepicker.module.scss'
setDefaultOptions({ locale: nl })

interface DatepickerProps extends PropsWithChildren {
  onSelect?: any
  date?: string
  dates?: string[]
}

const CONFIG = {
  dayNameFormat: 'iiiiii',
  largeDayNameFormat: 'eeee',
  dayFormat: 'd/MM',
  daysLeft: 3,
  daysRight: 3
}

const Datepicker: FunctionComponent<DatepickerProps> = (
  {
    onSelect = () => void 0,
    date,
    dates
  }
) => {
  const [current, setCurrent] = useState<Date>(date ? new Date(date) : new Date())
  const [active, setActive] = useState<Date>(date ? new Date(date) : new Date())
  const [offset, setOffset] = useState<number>(1)
  const isMounted = useRef(false)

  const nextDay = () => {
    setOffset(offset+1)
    setTimeout(() => {
      setCurrent(addDays(current, 1))
      setOffset(1)
    }, 200)
  }

  const previousDay = () => {
    setOffset(offset-1)
    setTimeout(() => {
      setCurrent(subDays(current, 1))
      setOffset(1)
    }, 200)
  }

  useEffect(() => {
    if (isMounted.current){
      onSelect(active)
      setOffset(1)
      setCurrent(active)
    } else {
      isMounted.current = true
    }
  }, [active])

  // we create extra offscreen days for animation
  const startDay = subDays(current, CONFIG.daysLeft + 1)
  const startDate = subDays(startDay, 1)
  // const days = dates ? dates.map((date: string) => new Date(date)) : Array(1 + CONFIG.daysLeft + CONFIG.daysRight + 2)
  const days = Array(1 + CONFIG.daysLeft + CONFIG.daysRight + 2)
    .fill(0)
    .map((value, index) => addDays(startDate, index+1))

  const itemWidth = 100 / (1 + CONFIG.daysLeft + CONFIG.daysRight)
  const width = 100 + itemWidth * 2

  const isClickable = (day: any) => {
    return dates ? dates.includes(format(day, 'yyyy-MM-dd')) : true
  }

  return <div className={styles.datepicker}>
    <span className={styles.navigationLeft}><Button variant={'tertiary'} iconBefore={'chevron-left'} onClick={() => previousDay()} /></span>
    <span className={styles.navigationRight}><Button variant={'tertiary'} iconBefore={'chevron-right'} onClick={() => nextDay()} /></span>
    <ol className={styles.days} style={{ width: `${width}%`, left: `-${offset * itemWidth}%`, transition: offset !== 1 ? 'left .2s ease-in-out' : '' }}>
      {days.map((day, index) => {
          const dayName = isToday(day) ? 'vandaag' : format(day, CONFIG.dayNameFormat)
          const largeDayName = isToday(day) ? 'vandaag' : format(day, CONFIG.largeDayNameFormat)
          return <li key={`${index}-${day}`} onClick={() => isClickable(day) && setActive(day)} className={clsx(
            styles.day,
            isClickable(day) && styles.clickable,
            isToday(day) && styles.today,
            isSameDay(day, active) && styles.active
          )}>
            <time className={styles.date}>
              <span className={styles.showMobile}>{dayName}</span>
              <span className={styles.showDesktop}>{largeDayName}</span>
              <span className={styles.dateFormat}>{format(day, CONFIG.dayFormat)}</span>
            </time>
          </li>
        })}
    </ol>
  </div>
}

export default Datepicker

export type {
  DatepickerProps
}

import type { ClassValue } from 'clsx'
import type { FunctionComponent, PropsWithChildren, RefObject } from 'react'

import { getCookie } from '@sporza/utils/cookies'
import { addScriptToDOM } from '@sporza/utils/dom'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'

import { getConfig } from '../../../../apps/frontend-web/app/features/sso/helpers'
import { getPlayerLibrary } from '../mediaplayer'

import styles from './multilive.module.scss'

interface MultiliveComponentProps {
  componentName: string
  componentType: string
  componentProps: MultiliveProps
}

interface MultiliveItem {
  name: string;
  description?: string;
  posterImageUrl?: string;
  showOss24Icon?: boolean;

  mediaRef: string;
  aggregatorUrl?: string;

  default?: boolean;
}

interface MultiliveProps extends PropsWithChildren {
  items: MultiliveItem[]
  autoplay?: boolean
  aggregatorUrl?: string
  innerRef?: RefObject<any>
  className?: ClassValue
}

const Multilive: FunctionComponent<MultiliveProps> = (
  {
    autoplay = false,
    items,
    innerRef,
    className
  }
) => {
  const playerRef = innerRef ? innerRef : useRef(null)
  const [bootstrapped, setBootstrapped] = useState(false)

  useEffect(() => {
    const id = 'newvrtplayer'
    if (!document.getElementById(id)) {
      addScriptToDOM({
        src: getPlayerLibrary(),
        id
      })

      window.addEventListener('vrtPlayerBootstrapped', () => {
        setBootstrapped(true)
      })
    } else {
      setBootstrapped(true)
    }
  }, [])

  useEffect(() => {
    const player: any = document.createElement('vrt-multiviewer')

    player.availableStreams = items
    player.playerMinWidth = 320
    player.autoplay = autoplay
    player.identityToken = getCookie(getConfig().videoTokenCookie)

    playerRef.current.replaceChild(player, playerRef.current.firstChild)
  }, [bootstrapped])

  return <div className={clsx(
    styles.wrapper,
    className
  )}>
    <div ref={playerRef}>
      <div></div>
    </div>
  </div>
}

export default Multilive

export type {
  MultiliveProps,
  MultiliveComponentProps
}

import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import Pill from '../../../molecules/pill'
import Teamname, { TeamnameSize } from '../../../molecules/teamname'
import { DrawItemType } from '../index'

import styles from './start-groups.module.scss'

interface StartItemProps {
  resultGroup?: string
  type?: DrawItemType
  name?: string
  shortName?: string
  logoUrl?: string
  url?: string
  sport?: string
}

const StartItem: FunctionComponent<StartItemProps> = (
  {
    resultGroup,
    type = DrawItemType.Team,
    name = '',
    shortName = '',
    logoUrl,
    url,
    sport
  }
) => {
  const hasGroup = !!resultGroup

  const drawItemLayouts = {
    [DrawItemType.Team]: <Teamname
      team={{
        name,
        shortName,
        logoUrl,
        href: url
      }}
      size={TeamnameSize.Large}
      showLinks={true}
      sport={sport}
    />,
  }

  return <div className={clsx(
    styles.startItem,
    hasGroup && styles.hasGroup
  )}>
    {drawItemLayouts[type] || drawItemLayouts[DrawItemType.Team]}
    {resultGroup && <Pill className={styles.hideOnMobile}>{resultGroup}</Pill>}
  </div>
}

export {
  StartItem
}

export type {
  StartItemProps
}

import type { ClassValue } from 'clsx'
import type { CSSProperties, FunctionComponent, Ref } from 'react'

import clsx from 'clsx'

import Caption, { CaptionWeight } from '../../atoms/caption'
import Icon from '../../atoms/icon'
import Title, { TitleSize } from '../../atoms/title'
import Image, { ImageLayout } from '../../molecules/image'

import styles from './avatar.module.scss'

enum AvatarLayout {
  Default = 'default',
  Large = 'large'
}

enum AvatarTheme {
  Default = 'default',
  Gold = 'gold',
  Silver = 'silver',
  Bronze = 'bronze'
}

interface AvatarProps {
  layout?: AvatarLayout
  theme?: AvatarTheme
  image?: string
  fallbackImage?: string
  icon?: string
  title?: string
  text?: string
  label?: string
  href?: string
  className?: ClassValue
  style?: CSSProperties
  innerRef?: Ref<HTMLElement>
  onClick?: (args?: any) => void
}

const Avatar: FunctionComponent<AvatarProps> = (
  {
    layout = AvatarLayout.Default,
    theme= AvatarTheme.Default,
    image,
    fallbackImage,
    icon,
    title,
    text,
    href,
    label,
    className,
    style,
    innerRef,
    onClick
  }
) => {
  const Tag = href ? 'a' : 'div'

  const renderAvatarVisual = () => {
    switch (true){
      case !!image:
      case !!fallbackImage:
        return <Image
          src={image}
          alt={title ? `afbeelding van ${title}` : ''}
          fallbackImage={fallbackImage}
          layout={ImageLayout.Square}
          className={styles.visual}
        />
      case !!icon:
        return <div className={styles.visual}>
          <Icon name={icon} className={styles.icon} />
        </div>
      default:
        return <div className={styles.visual}></div>
    }
  }

  const renderTitle = () => {
    switch (true){
      case layout === AvatarLayout.Large && !!title:
        return <Title className={styles.title} size={TitleSize.XSmall} desktopSize={TitleSize.Small}>{title}</Title>
      case !!title:
        return <Caption className={styles.title} weight={CaptionWeight.Medium}>{title}</Caption>
      default:
        return null
    }
  }

  return <Tag
    className={clsx(
    styles.avatar,
    layout === AvatarLayout.Large && styles.large,
    theme === AvatarTheme.Gold && styles.gold,
    theme === AvatarTheme.Silver && styles.silver,
    theme === AvatarTheme.Bronze && styles.bronze,
    label && styles.withLabel,
    className,
  )}
    style={style}
    href={href}
    ref={innerRef}
    onClick={onClick}
  >
    <div className={styles.visualContainer}>
      <div className={styles.ring} />
      {renderAvatarVisual()}
      {label && <span className={styles.label}>{label}</span>}
    </div>
    {renderTitle()}
    {
      text && <Caption
        className={styles.text}
      >{text}</Caption>
    }
  </Tag>
}

export {
  Avatar,
  AvatarLayout,
  AvatarTheme
}

export type {
  AvatarProps
}

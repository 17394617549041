import type { FunctionComponent } from 'react'

import Button from '../../../molecules/button'
import AndroidCTA from '../assets/android-cta.svg'
import IOSCTA from '../assets/ios-cta.svg'

import styles from './app-cta.module.scss'

interface BannerAppCTAProps {
  iosUrl?: string
  androidUrl?: string
}

const BannerAppCTA: FunctionComponent<BannerAppCTAProps> = (
  {
    iosUrl= 'https://itunes.apple.com/be/app/sporza/id878339906?l=nl&mt=8',
    androidUrl = 'https://play.google.com/store/apps/details?id=com.fwc2014.vrt.and&hl=nl'
  }
) => {

  return <div className={styles.wrapper}>
    <Button href={iosUrl} variant={'tertiary'}>
      <IOSCTA />
    </Button>
    <Button href={androidUrl} variant={'tertiary'}>
      <AndroidCTA />
    </Button>
  </div>
}

export default BannerAppCTA

export type {
  BannerAppCTAProps
}

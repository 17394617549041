import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import Caption, { CaptionWeight } from '../../../atoms/caption'
import Icon from '../../../atoms/icon'
import Title, { TitleSize } from '../../../atoms/title'
import Image from '../../../molecules/image'
import Pill from '../../../molecules/pill'
import { StatusTypes } from '../../../molecules/score'
import Card from '../../card'
import Mediaplayer from '../../mediaplayer'
import { LivestreamCardProps } from '../index'

import styles from './vertical.module.scss'

const LivestreamCardVertical: FunctionComponent<LivestreamCardProps> = (
  {
    link,
    icon,
    title,
    subtitle,
    button,
    time,
    withHover = true,
    className,
    image,
    progress,
    livestream,
    ref
  }
) => {
  const ContentTag = link && livestream?.componentProps ? 'a' : 'div'

  return <Card
    link={!livestream?.componentProps ? link : undefined}
    className={clsx(
      styles.livestreamCardVertical,
      className
    )}
    withHover={withHover}
    innerRef={ref}
  >
    <div className={styles.image}>
      {
        livestream?.componentProps
          ? <Mediaplayer {...livestream?.componentProps} />
          : image
            ? <Image src={image}>
              {
                button
                && button.status === StatusTypes.Live
                && <Pill className={styles.liveButton} iconBefore={button.icon}>{button.title}</Pill>
              }
            </Image>
            : <Title size={TitleSize.Small} className={styles.message}>{button?.title}</Title>
      }
    </div>
    <ContentTag
      href={ContentTag === 'a' ? link : undefined}
      className={styles.content}
    >
      <Title className={styles.title} size={TitleSize.XSmall} darkMode={true}>
        {icon && <Icon className={styles.titleIcon} name={icon} />} {title}
      </Title>
      <div className={styles.text}>
        <Caption className={styles.time}>{time?.text}</Caption>
        <Caption weight={CaptionWeight.Medium} className={styles.subTitle}>{subtitle}</Caption>
        <div className={clsx(
          styles.progressBar,
          progress === undefined && styles.progressBarEmpty
        )}>
          <div className={styles.filler} style={{ width: `${progress}%` }} />
        </div>
      </div>
    </ContentTag>
  </Card>
}

export default LivestreamCardVertical

import type { FunctionComponent } from 'react'
import type { ButtonProps } from '../../molecules/button'
import type { StoryCardProps } from '../story-card'

import Title, { TitleElement, TitleSize } from '../../atoms/title'
import Button from '../../molecules/button'
import { ImageLayout } from '../../molecules/image'
import StoryCard from '../story-card'

import styles from './podcast-info.module.scss'

interface PodcastInfoProps {
  hosts: ButtonProps[],
  program: StoryCardProps,
}

const PodcastInfo: FunctionComponent<PodcastInfoProps> = (
  {
    hosts,
    program,
  }
) => {
  return <div className={styles.podcastInfo}>
    {
      hosts && !!hosts.length &&
      <div className={styles.host}>
        <Title size={TitleSize.Large} tag={TitleElement.H2}>
          {hosts.length > 1
            ? 'podcasthosts'
            : 'podcasthost'
          }
        </Title>
        <div className={styles.hostList}>
          {
            hosts.map((host: ButtonProps) =>
              <Button{...host} key={host.title} withIconBackground={true}>
                {host.title}
              </Button>)
          }
        </div>
      </div>
    }
    {
      program &&
      <div className={styles.program}>
        <Title size={TitleSize.Large} tag={TitleElement.H2}>
          podcastreeks
        </Title>
        <StoryCard {...program} imageLayout={ImageLayout.Square}/>
      </div>
    }
  </div>
}

export default PodcastInfo

export type { PodcastInfoProps }

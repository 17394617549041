import type { FunctionComponent } from 'react'
import type {
  ResultGroupsProps,
  StartGroupsProps
} from '../../index'

import clsx from 'clsx'

import { StatusTypes } from '../../../../molecules/score'
import commonStyles from '../../draw.module.scss'
import {
  ResultGroups,
  StartGroups
} from '../../index'

import styles from './pots-groups.module.scss'

interface PotsGroupsProps {
  name?: string
  status?: string
  availableSlots: number | null
  startingGroups: StartGroupsProps | null
  resultGroups: ResultGroupsProps
}

const PotsGroups: FunctionComponent<PotsGroupsProps> = (
  {
    name,
    status,
    startingGroups,
    resultGroups,
    availableSlots
  }
) => {
  const isLive = status?.toLowerCase() === StatusTypes.Live.toLowerCase()
  const isEnd = status?.toLowerCase() === StatusTypes.End.toLowerCase() || status?.toLowerCase() === 'ended'

  return <div
    className={clsx(
      commonStyles.draw,
      styles.potsGroups
    )}
    aria-label={name}
  >
    {
      startingGroups && !startingGroups.hidden && !isEnd
        ? <StartGroups
          {...startingGroups}
          isLive={isLive}
        />
        : false
    }
    {
      resultGroups && !resultGroups.hidden
        ? <ResultGroups
          {...resultGroups}
          isLive={isLive}
          availableSlots={availableSlots}
        />
        : false
    }
  </div>
}

export {
  PotsGroups
}

export type {
  PotsGroupsProps
}


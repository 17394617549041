const getRoundIcon = (rounds: any[], index: number) => {
  switch (rounds.length - index) {
    case 1:
      return 'bracket-round-of-2'
    case 2:
      return 'bracket-round-of-4'
    case 3:
      return 'bracket-round-of-8'
    case 4:
      return 'bracket-round-of-16'
    case 5:
      return 'bracket-round-of-20'
    case 6:
    default:
      return 'bracket-round-of-24'
  }
}

const isRoundRobin = (roundA?: any, roundB?: any) => {
  if (!roundA || !roundB)
    return false

  const roundAMatches = roundA.items?.length || 0
  const roundBMatches = roundB.items?.length || 0

  return roundAMatches / 2 > roundBMatches
}

const shortenRoundLabel = (roundLabel: string) => {
  if (roundLabel.toLowerCase() === 'kwartfinale')
    return 'KF'

  return roundLabel
    .split(' ')
    .map(word =>
      word
        .substring(0, 1)
        .toUpperCase()
    )
    .join('')
}

export {
  getRoundIcon,
  isRoundRobin,
  shortenRoundLabel,
}

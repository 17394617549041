import type { FunctionComponent, PropsWithChildren } from 'react'
import type { Icons } from '../../../../design-tokens/iconography/icons'

import Caption from '../../../../atoms/caption'
import Icon from '../../../../atoms/icon'
import styles from '../../score.module.scss'

interface ScoreDistanceProps extends PropsWithChildren {
  distance?: number
  distanceToGo?: number
  label?: string
  typeIcon?: string | Icons
  darkMode?: boolean
  startLabel?: string
  endLabel?: string
  date?: string
  winners?: [Record<string, string>]
}

const DefaultDistance: FunctionComponent<ScoreDistanceProps> = ({
  distanceToGo,
  label,
  typeIcon,
  winners
}) => {
  return <>
    {distanceToGo && <Caption className={styles.label}>nog <span className={styles.bigNumber}>{distanceToGo} km</span></Caption>}
    {label && <div className={styles.label}>{label}</div>}
    {typeIcon === 'cycling-stopwatch' && <Icon name={'cycling-stopwatch'} />}
  </>
}

export default DefaultDistance

export type {
  ScoreDistanceProps
}

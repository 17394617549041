import type { FunctionComponent } from 'react'
import type { DataCardProps } from '../../index'

import clsx from 'clsx'

import DataList from '../../../../molecules/data-list'
import Heading from '../../../../molecules/heading'
import Card from '../../../card'
import LinkList, { LinkListDirection, LinkListLayout } from '../../../link-list'
import styles from '../../data-card.module.scss'

const DataCardWithHeader: FunctionComponent<DataCardProps> = (
  {
    header,
    team,
    dataList
  }
) => {
  return <Card>
    <Heading>{header}</Heading>
    <div
      className={clsx(
        styles.dataCard,
        styles.dataCardWithHeader
      )}
    >
      {team && <LinkList className={styles.linkList} direction={LinkListDirection.Horizontal} layout={LinkListLayout.Grey} items={team} />}
      {dataList && dataList[0] && dataList[0].map((item, index) => <DataList
        key={`${header}${index}`}
        className={styles.dataList}
        {...item.componentProps}
      />)}
    </div>
  </Card>
}

export {
  DataCardWithHeader
}

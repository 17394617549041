import type { RefObject } from 'react'
import type { BannerImpressionArguments } from '../events'

import { useIntersectionObserver } from '@sporza/hooks'
import { useState } from 'react'

import { bannerImpressionEvent } from '../events'

interface UseBannerImpressionArguments extends BannerImpressionArguments {
  ref: RefObject<Element>,

}

const useBannerImpression = (
  {
    ref,
    ...bannerImpressionArgs
  }: UseBannerImpressionArguments
): void => {
  const [hasTriggeredEvent, setHasTriggeredEvent] = useState(false)

  const entry = useIntersectionObserver(
    ref,
    {
      threshold: 0.75,
      freezeOnceVisible: true
    }
  )
  const isVisible = !!entry?.isIntersecting

  if (!hasTriggeredEvent && isVisible) {
    bannerImpressionEvent(bannerImpressionArgs)
    setHasTriggeredEvent(true)
  }
}

export {
  useBannerImpression
}

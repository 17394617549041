import type { FunctionComponent } from 'react'
import type { MediaplayerComponentProps, MediaplayerProps } from '../mediaplayer'
import type { MediaCardAvatarProps, MediaCardVerticalProps } from './layout'

import { Theme } from '@sporza/config'
import { ComponentType } from '@sporza/services/component-mapper'
import { useListClick, useListImpression } from '@sporza/tracking'
import { useRef } from 'react'

import { MediaCardLayout } from './index'
import { MediaCardAvatar, MediaCardStory,MediaCardVertical } from './layout'

interface MediaCardComponentProps {
  componentType: ComponentType
  componentProps: MediaCardProps
}

interface MediaCardProps extends MediaCardVerticalProps, MediaCardAvatarProps {
  id?: string
  layout?: MediaCardLayout
  playlist?: MediaplayerComponentProps
  selectedMediaIndex?: number
  ebaData?: Record<any, any>
}

const layoutComponents = {
  [MediaCardLayout.Vertical]: MediaCardVertical,
  [MediaCardLayout.Avatar]: MediaCardAvatar,
  [MediaCardLayout.Story]: MediaCardStory,
}

const MediaCard: FunctionComponent<MediaCardProps> = (
  {
    layout,
    ebaData = {},
    ...props
  }
) => {
  const ref = useRef(null)
  const Layout = layoutComponents[layout as keyof typeof layoutComponents] || layoutComponents[MediaCardLayout.Vertical]

  useListClick({
    ref,
    ...ebaData
  })

  useListImpression(
    {
      ref,
      ...ebaData
    }
  )

  return <Layout
    {...props}
    innerRef={ref}
  />
}

export {
  MediaCard
}

export type {
  MediaCardProps,
  MediaCardComponentProps
}

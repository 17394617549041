import type { FunctionComponent } from 'react'
import type { ScoreProps } from '../../../molecules/score'

import Score, { ScoreLayoutTypes } from '../../../molecules/score'

type CellWithScoreResultProps = {
  componentProps: ScoreProps
}

const CellWithScoreResult: FunctionComponent<CellWithScoreResultProps> = (match) => {
  return (
      <Score
        {...match.componentProps}
        layout={ScoreLayoutTypes.Result}
      />
  )
}
export default CellWithScoreResult

import type { FunctionComponent } from 'react'
import type { FilterProps } from '../../molecules/filter'
import type { MatchDayComponentProps } from '../matchday'
import type { UseApiProps } from '@sporza/hooks'

import { useApi } from '@sporza/hooks'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

import Title, { TitleElement, TitleSize } from '../../atoms/title'
import Button from '../../molecules/button'
import { Notification, NotificationType } from '../../molecules/notification'
import Cell from '../cell'
import Matchday from '../matchday'
import { SectionLayout } from '../section'
import Section from '../section/section'
import Tab from '../tab'
import Table from '../table'

import BulletList from './layouts/bulletList'
import styles from './tab-section.module.scss'

enum TabSectionLayout {
  BulletList = 'BulletList',
  Table = 'Table',
  Matchday = 'Matchday'
}

enum FilterPosition {
  Left = 'left',
  Right = 'right'
}

interface TabSectionComponentProps {
  componentProps: TabSectionProps
}

interface TabSectionProps extends UseApiProps {
  layout: string | TabSectionLayout | SectionLayout
  label?: string
  subLabel?: string
  items?: any
  link?: string
  showLinkInTitle?: boolean
  isLive?: boolean
  hideImages?: boolean
  filter?: FilterProps,
  filterPosition?: FilterPosition,
  virtual?: boolean
  enableEba?: boolean
  author?: boolean
}

const TabSection: FunctionComponent<TabSectionProps> = (props) => {
  const {
    enableEba = false,
    author = false
  } = props
  const [state, setState] = useState<any>(props)

  useEffect(() => {
    setState(props)
  }, [props])

  const { data } = useApi(state, {
    keyPrefix: 'tabsection',
    fetchNow: state.fetchNow,
  })

  const {
    layout = SectionLayout.Grid1Column,
    label,
    subLabel,
    items,
    link,
    showLinkInTitle = false,
    isLive,
    hideImages,
    filterPosition = FilterPosition.Left,
    virtual
  } = data || state

  const hasItems = items?.length > 0
  const tabItems = items?.map((item: any) => {
    const getContent = (layout: string) => {
      switch (true) {
        case layout === TabSectionLayout.Matchday:
          return item.componentProps.items.map((matchday: MatchDayComponentProps, index: number) => <Matchday key={`${index}-${matchday.componentProps.title}`}
            {...matchday.componentProps}
          />)
        case layout === TabSectionLayout.BulletList:
          return <BulletList {...item.componentProps} />
        case layout === TabSectionLayout.Table:
          return <Table
            {...item.componentProps}
            label={null}
            hideImages={hideImages}
            enableEba={enableEba}
          />
        case layout in SectionLayout:
          return <Section
            className={styles.section}
            {...item.componentProps}
            layout={layout}
          />
        default:
          return false
      }
    }

    return {
      label: item.componentProps?.label,
      content: getContent(layout)
    }
  })

  const handleFilter = (filter: any) => {
    setState({
      ...state,
      sportApiUrl: filter.value,
      fetchNow: true
    })
  }

  const titleInTab = props.filter && filterPosition === FilterPosition.Left

  return hasItems
    ? <div className={clsx(
      styles.tabSection,
      isLive && styles.live
    )}>
      {
        isLive
          ? <Button
            variant="live"
            iconBefore="live-alt"
            className={clsx(
              styles.liveButton,
              virtual && styles.virtualButton
            )}
          >{virtual ? 'virtueel' : 'live'}</Button>
          : false
      }
      {
        label
        && !titleInTab
        && <Title
          className={styles.label}
          link={link}
          size={TitleSize.Medium}
          subTitle={subLabel}
          tag={TitleElement.H2}
        >
          {label}
        </Title>
      }
      {
        subLabel
        && !label
        && !titleInTab
        && <Title
          className={clsx(styles.label, styles.floatingLabel)}
          subTitle={subLabel}
          tag={TitleElement.H3}
        ></Title>
      }
      <Tab title={titleInTab ? label : undefined} filterPosition={filterPosition} filter={props.filter} handleFilter={handleFilter} items={tabItems}/>
      {
        link
        && !showLinkInTitle
        && <Cell
          align="center"
          size={4}
          className={styles.actionBar}
        >
          <Button darkMode={true}
                  variant={'primary'}
                  iconAfter={'arrow-right'}
                  href={link}>
            meer
          </Button>
        </Cell>
      }
    </div>
    : author
      ? <Notification
        type={NotificationType.Info}
        label="Tab Section"
        text="Tab Section does not have any items yet"
      />
      : null
}

export default TabSection

export {
  TabSectionLayout,
  FilterPosition
}

export type {
  TabSectionProps,
  TabSectionComponentProps
}

import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import Icon from '../../../atoms/icon'
import styles from '../f1-lineup.module.scss'

interface F1LineupItemProps {
  position: number,
  player: any,
  team: any,
  isMute?: boolean
}

const F1LineupItem: FunctionComponent<F1LineupItemProps> = (
  {
    position,
    player,
    team,
    isMute
  }
) => {
  return <div className={clsx(
    styles.f1LineupItem,
    isMute && styles.f1LineupItemMute
  )}>
    <div className={styles.f1LineupItemPosition}>
      <div className={styles.f1LineupItemPositionInner}>{position}</div>
    </div>
    <div
      className={styles.f1LineupItemCar}
      style={{
        '--f1-lineup-team-color': team?.colour || 'transparent'
      }}
    ><Icon name="formula1-car" /></div>
    <div className={styles.f1LineupItemContent}>
      <div className={styles.f1LineupItemPlayer}>{player?.lastName}</div>
      <div className={styles.f1LineupItemTeam}>
        <span className={styles.f1LineupItemTeamColor} style={{ backgroundColor: team?.colour || 'transparent' }}></span>
        {team?.name}
      </div>
    </div>
  </div>
}

export {
  F1LineupItem
}

export type {
  F1LineupItemProps
}

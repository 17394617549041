import type { BannerProps } from '../banner'

import clsx from 'clsx'
import { forwardRef } from 'react'

import Image from '../../../molecules/image'
import { BannerTheme } from '../config'

import styles from './image.module.scss'

const BannerImage = forwardRef<HTMLInputElement, BannerProps>((
  {
    image,
    link,
    className,
    click,
    theme,
    themeConfig,
    designSystemBaseUrl
  },
  ref
) => {
  const targetLink = themeConfig?.link ?? link
  const Tag = targetLink
    ? 'a'
    : 'div'

  if (!image && themeConfig?.theme === BannerTheme.GenericPlayPremierLeague) {
    image = themeConfig?.image ?? `${designSystemBaseUrl}/images/play/play-premier-league-image.svg`
  }

  return <Tag
    className={clsx(
      styles.wrapper,
      theme === BannerTheme.PlayPremierLeague && styles.themePlayPremierLeague,
      className
    )}
    onClick={() => click()}
    href={targetLink ? targetLink : undefined}
    target={targetLink ? '_blank' : undefined}
    ref={ref}
  >
    <Image
      src={image}
      className={styles.image}
      noCrop={true}
      priority={true}
    />
  </Tag>
})

BannerImage.displayName = 'BannerImage'

export {
  BannerImage
}

import type { FunctionComponent, HTMLAttributes } from 'react'

import clsx from 'clsx'

import Caption from '../../../../../atoms/caption'
import Icon from '../../../../../atoms/icon'
import Button, { ButtonVariant } from '../../../../../molecules/button'
import Image, { ImageLayout } from '../../../../../molecules/image'

import styles from './player-option.module.scss'

interface Option {
  shortName: string
  playerImageUrl?: string
  selected?: boolean
}

interface PlayerOptionProps extends HTMLAttributes<HTMLDivElement> {
  isReorderActive: boolean
  option: Option
  toggleOption?: (option: any) => void
  id?: string
  withOpacity?: boolean
  isDragging?: boolean
  isDisabled?: boolean
  setNodeRef?: (node: HTMLElement | null) => void
}

const PlayerOption: FunctionComponent<PlayerOptionProps> = (
  {
    isReorderActive,
    isDisabled,
    style,
    option,
    toggleOption,
    className
  }
) => {
  const {
    shortName,
    playerImageUrl,
    selected
  } = option || {}

  return <Button
    style={style}
    className={clsx(
      styles.playerOption,
      selected ? styles.isSelected : '',
      isReorderActive ? styles.reorderActive : '',
      isDisabled ? styles.isDisabled : '',
      className
    )}
    onClick={() => !isDisabled && toggleOption && toggleOption(option)}
    variant={ButtonVariant.tertiary}
  >
    {
      isReorderActive
      && <Icon
        className={styles.reorderIcon}
        name="soccer-substitute-mono"
      />
    }
    <div className={styles.playerImage}>
      {
        selected
          ? <div className={styles.isSelectedIcon}>
            <Icon name="check-alt"/>
          </div>
          : null
      }
      <Image
        src={playerImageUrl}
        layout={ImageLayout.Square}
        fallbackImage={'https://design-system.sporza.be/images/placeholders/player.svg'}
        priority={true}
        className={clsx(
          selected ? styles.isSelectedImage : ''
        )}
      />
    </div>
    <Caption>{shortName}</Caption>
  </Button>
}
PlayerOption.displayName = 'PlayerOption'

export {
  PlayerOption
}

export type {
  PlayerOptionProps
}


import type { FunctionComponent } from 'react'
import type { CardProps } from '../card'

import { useListClick, useListImpression } from '@sporza/tracking'
import { useRef } from 'react'

import { Icons } from '../../design-tokens/iconography/icons'
import { StatusTypes } from '../../molecules/score'
import { MediaplayerComponentProps } from '../mediaplayer'

import LivestreamCardCompact from './layout/compact'
import LivestreamCardVertical from './layout/vertical'
import LivestreamCardDefault from './layout'

interface LivestreamCardComponentProps {
  componentProps: LivestreamCardProps
  componentType: string
}

interface LivestreamCardProps extends CardProps {
  layout?: LivestreamCardLayout
  icon?: Icons
  title?: string
  subtitle?: string
  image?: string
  button?: {
    title?: string
    icon?: Icons
    status?: StatusTypes
  },
  time?: {
    text?: string
    icon?: Icons
    status?: StatusTypes
  },
  ref?: any
  progress?: number
  livestream?: MediaplayerComponentProps
}

enum LivestreamCardLayout {
  Default = 'default',
  Vertical = 'vertical',
  Compact = 'compact'
}

const layoutComponents = {
  [LivestreamCardLayout.Default]: LivestreamCardDefault,
  [LivestreamCardLayout.Vertical]: LivestreamCardVertical,
  [LivestreamCardLayout.Compact]: LivestreamCardCompact,
}

const LivestreamCard: FunctionComponent<LivestreamCardProps> = (
  props
) => {
  const ref = useRef(null)

  const {
    ebaData,
    layout = LivestreamCardLayout.Default
  } = props

  useListClick({
    ref,
    ...ebaData
  })

  useListImpression(
    {
      ref,
      ...ebaData
    }
  )

  const Layout = layoutComponents[layout as keyof typeof layoutComponents]

  return <Layout ref={ref} {...props} />
}

export type {
  LivestreamCardProps,
  LivestreamCardComponentProps
}

export {
  LivestreamCard,
  LivestreamCardLayout
}

import type { FunctionComponent } from 'react'
import type { IconProps } from '../../../atoms/icon'
import type { PillProps } from '../../../molecules/pill'
import type { CardProps } from '../../card'

import clsx, { type ClassValue } from 'clsx'

import Title, { TitleSize } from '../../../atoms/title'
import { Avatar } from '../../../molecules/avatar'
import Image, { ImageLayout } from '../../../molecules/image'
import Pill from '../../../molecules/pill'
import Card from '../../card'
import styles from '../media-card.module.scss'

interface MediaCardVerticalProps extends CardProps {
  image?: string
  label?: PillProps
  title?: string
  iconBefore?: IconProps
  className?: string
}

const MediaCardVertical: FunctionComponent<MediaCardVerticalProps> = (
  {
    link,
    label,
    target,
    image,
    title,
    onClick,
    className,
    darkMode,
    iconBefore,
    innerRef
  }
) => {
  return <Card
    link={link}
    target={target}
    className={clsx(
      styles.mediaCard,
      darkMode && styles.dark,
      className
    )}
    darkMode={darkMode}
    innerRef={innerRef}
    onClick={onClick}
  >
    {image
      && <Image
        src={image}
        layout={ImageLayout.Portrait}
        className={styles.mediaCardMedia}
      />
    }
    {
      label
      && <Pill
        className={styles.mediaCount}
        {...label}
      />
    }
    <div className={clsx(
      styles.mediaCardContent
    )}>
      {
        iconBefore
        && <Avatar icon={iconBefore.name} className={styles.icon} />
      }
      {title && <Title size={TitleSize.XSmall}>{title}</Title>}
    </div>
  </Card>
}

export {
  MediaCardVertical
}

export type {
  MediaCardVerticalProps,
}

import type { FunctionComponent, PropsWithChildren } from 'react'

import Caption from '../../../../atoms/caption'
import Meta from '../../../../atoms/meta'
import styles from '../../score.module.scss'

interface ScoreScoreProps extends PropsWithChildren {
  score?: {
    home: number
    away: number
    shootout?: {
      home: number
      away: number
    }
  }
  label?: string
  date?: string
  title?: string
}

const DefaultScore: FunctionComponent<ScoreScoreProps> = ({
  label,
  score
}) => {
  return <>
    {label && <Caption className={styles.label}>{label}</Caption>}
    {score && <div className={styles.numbers}>
      <span>{score?.home}</span>
      {typeof score?.shootout?.home === 'number' && <sup className={styles.shootout}>{score?.shootout?.home}</sup>}
      <Meta className={styles.dash}>-</Meta>
      <span>{score?.away}</span>
      {typeof score?.shootout?.away === 'number' && <sup className={styles.shootout}>{score?.shootout?.away}</sup>}
    </div>}
  </>
}

export default DefaultScore

export type {
  ScoreScoreProps
}

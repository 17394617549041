import type { ClassValue } from 'clsx'
import type { FunctionComponent } from 'react'

import { useGdpr } from '@sporza/hooks'
import { addScriptToDOM } from '@sporza/utils/dom'
import { Vendor } from '@sporza/utils/gdpr'
import clsx from 'clsx'
import { memo, useEffect, useState } from 'react'
import { Tweet } from 'react-twitter-widgets'

import styles from './embed-twitter.module.scss'

interface EmbedTwitterComponentProps {
  componentProps: EmbedTwitterProps
}

interface EmbedTwitterProps {
  className?: ClassValue
  twitterId?: string
  width?: string
  lang?: string
  showPreviews?: boolean
  showConversation?: boolean
  darkMode?: boolean
  autoLoad?: boolean
  isVideo?: boolean
}

const EmbedTwitter: FunctionComponent<EmbedTwitterProps> = (
  {
    className,
    twitterId,
    width = 400,
    lang = 'nl',
    showPreviews = true,
    showConversation,
    darkMode = false,
    autoLoad = false,
    isVideo = false
  }
) => {
  const [shouldLoadTwitterWidget, setShouldLoadTwitterWidget] = useState(false)
  const { gdprVendorAllowed, vendorNotAllowedComponent } = useGdpr(Vendor['𝕏'])

  useEffect(() => {
    setShouldLoadTwitterWidget(autoLoad || gdprVendorAllowed)
  }, [autoLoad, gdprVendorAllowed])

  useEffect(() => {
    if (shouldLoadTwitterWidget && isVideo){
      addScriptToDOM({
        src: 'https://platform.twitter.com/widgets.js',
        async: true
      })
    }
  }, [shouldLoadTwitterWidget, isVideo])

  const Element: FunctionComponent = () => {
    if (!twitterId)
      return null

    return isVideo ? <div>
      <blockquote className="twitter-tweet" data-media-max-width="100%">
        <a href={`https://twitter.com/x/status/${twitterId}?ref_src=twsrc%5Etfw`}></a>
      </blockquote>
    </div>
      :
      <Tweet tweetId={twitterId} options={{
        width,
        lang,
        cards: showPreviews ? null : 'hidden',
        conversation: showConversation ? null : 'none',
        theme: darkMode ? 'dark' : 'light'
      }} />
  }

  return <div
    className={clsx(
      styles.embedTwitter,
      className
    )}
  >
    {
      shouldLoadTwitterWidget
        ? <Element/>
        : !gdprVendorAllowed
          ? vendorNotAllowedComponent
          : null
    }
  </div>
}

const MemoizedEmbedTwitter = memo(EmbedTwitter)

export default MemoizedEmbedTwitter

export type {
  EmbedTwitterProps,
  EmbedTwitterComponentProps
}

import React, { Fragment, Suspense } from 'react'

import { ErrorBoundary } from '../../design-system/atoms/error-boundary'
import Paragraph from '../../design-system/atoms/paragraph'
import Title, { TitleElement } from '../../design-system/atoms/title'
import { Advertisement, AdvertisementGroup } from '../../design-system/molecules/advertisement'
import DataList from '../../design-system/molecules/data-list'
import GracenoteWidget from '../../design-system/molecules/gracenote-widget/gracenote-widget'
import { Image } from '../../design-system/molecules/image'
import { Quote } from '../../design-system/molecules/quote'
import { Accordion } from '../../design-system/organisms/accordion'
import AZList from '../../design-system/organisms/az-list'
import { Banner } from '../../design-system/organisms/banner'
import Bio from '../../design-system/organisms/bio'
import Calendar from '../../design-system/organisms/calendar'
import { DescriptionList } from '../../design-system/organisms/description-list'
import { Draw } from '../../design-system/organisms/draw'
import Embed from '../../design-system/organisms/embed'
import EmbedInstagram from '../../design-system/organisms/embed-instagram'
import EmbedQualifio from '../../design-system/organisms/embed-qualifio'
import EmbedThreads from '../../design-system/organisms/embed-threads'
import EmbedTwitter from '../../design-system/organisms/embed-twitter'
import Epg from '../../design-system/organisms/epg'
import Event from '../../design-system/organisms/event'
import ExperienceFragment from '../../design-system/organisms/experience-fragment'
import { F1Lineup } from '../../design-system/organisms/f1-lineup'
import { ImageCard } from '../../design-system/organisms/image-card'
import { ImageCarousel } from '../../design-system/organisms/image-carousel'
import LinkList from '../../design-system/organisms/link-list'
import LiveDashboard from '../../design-system/organisms/live-dashboard'
import { MasterMedia } from '../../design-system/organisms/mastermedia'
import Matchday from '../../design-system/organisms/matchday'
import MedalList from '../../design-system/organisms/medal-list'
import Mediaplayer from '../../design-system/organisms/mediaplayer'
import Multilive from '../../design-system/organisms/multilive'
import { NewsletterSubscription } from '../../design-system/organisms/newsletter-subscription'
import Nutshell from '../../design-system/organisms/nutshell'
import PlayerList from '../../design-system/organisms/player-list'
import PodcastInfo from '../../design-system/organisms/podcast-info/podcast-info'
import Result from '../../design-system/organisms/result'
import { Sailing } from '../../design-system/organisms/sailing'
import Scoreboard from '../../design-system/organisms/scoreboard'
import Search from '../../design-system/organisms/search'
import Section from '../../design-system/organisms/section/section'
import Spotlight from '../../design-system/organisms/spotlight'
import StoryCard from '../../design-system/organisms/story-card'
import TabSection from '../../design-system/organisms/tab-section'
import Table from '../../design-system/organisms/table'
import { Timeline } from '../../design-system/organisms/timeline'
import { Voting } from '../../design-system/organisms/voting'
import WielerManager, { WielerManagerLayout } from '../../design-system/organisms/wielermanager'
import { logger } from '../logger'

enum ComponentType {
  Accordion =                 'accordion',
  Advertisement =             'advertisement',
  AdvertisementGroup =        'advertisement-group',
  AzList =                    'az-list',
  AvatarCard =                'avatar-card',
  Banner =                    'banner',
  Bio =                       'bio',
  Calendar =                  'calendar',
  Competition =               'competition',
  DataCard =                  'data-card',
  DataList =                  'data-list',
  DescriptionList =           'description-list',
  Draw =                      'draw',
  Embed =                     'embed',
  EmbedInstagram =            'embed-instagram',
  EmbedThreads =              'embed-threads',
  EmbedTweet =                'embed-tweet',
  EmbedTwitter =              'embed-twitter',
  Epg =                       'epg',
  ExperienceFragment =        'experience-fragment',
  F1Lineup =                  'F1Lineup',
  GraceNoteWidget =           'gn-widget',
  ImageCard =                 'image-card',
  Links =                     'links',
  LiveDashboard =             'live-dashboard',
  LivestreamCard =            'livestream-card',
  LiveTeasers =               'live-teasers',
  Mastermedia =               'mastermedia',
  Matchdays =                 'matchdays',
  MedalList =                 'medal-list',
  Mediaplayer =               'mediaplayer',
  MediaCard =                 'media-card',
  MostRecentArticles =        'most-recent-articles',
  MostTrendingArticles =      'most-trending-articles',
  Multilive =                 'multilive',
  NewsletterSubscription =    'newsletter-subscription',
  Nutshell =                  'nutshell',
  Parsys =                    'parsys',
  PlayerList =                'player-list',
  PodcastInfo =               'podcast-info',
  PodcastEpisodeCard =        'podcast-episode-card',
  PodcastProgramCard =        'podcast-program-card',
  Qualifio =                  'qualifio',
  Quote =                     'quote',
  Ranking =                   'ranking',
  ReadMoreArticles =          'read-more-articles',
  RelatedArticle =            'related-article',
  ResponsiveImage =           'responsive-image',
  ResponsiveImageCarousel =   'responsive-image-carousel',
  Result =                    'result',
  Sailing =                   'sailing',
  ScoreBoard =                'score-board',
  Scoreboard =                'scoreboard',
  Search =                    'search',
  Section =                   'section',
  Statistics =                'statistics',
  StoryCard =                 'story-card',
  TabSection =                'tab-section',
  Table =                     'table',
  Text =                      'text',
  Timeline =                  'timeline',
  TimelineTeaser =            'timeline-teaser',
  Title =                     'title',
  VerticalVideoPlaylist =     'vertical-video-playlist',
  Voting =                    'voting',
  VrtmaxTeaser =              'vrtmax-teaser',
  WmCountdown =               'wm-countdown',
  WmRanking =                 'wm-ranking',
  WmStats =                   'wm-stats',
}

const mapToComponent = (
  item: any,
  siteConfig?: Record<string, any>,
  shouldReturnBareBone = false
): any => {
  const Element: any = {
    [ComponentType.Accordion]:                ['Accordion', Accordion],
    [ComponentType.Advertisement]:            ['Advertisement', Advertisement],
    [ComponentType.AdvertisementGroup]:       ['AdvertisementGroup', AdvertisementGroup],
    [ComponentType.AzList]:                   ['AZList', AZList],
    [ComponentType.Banner]:                   ['Banner', Banner],
    [ComponentType.Bio]:                      ['Bio', Bio],
    [ComponentType.Calendar]:                 ['Calendar', Calendar],
    [ComponentType.Competition]:              ['Table', Table],
    [ComponentType.DataList]:                 ['DataList', DataList],
    [ComponentType.DescriptionList]:          ['DescriptionList', DescriptionList],
    [ComponentType.Draw]:                     ['Draw', Draw],
    [ComponentType.Embed]:                    ['Embed', Embed],
    [ComponentType.EmbedInstagram]:           ['EmbedInstagram', EmbedInstagram],
    [ComponentType.EmbedThreads]:             ['EmbedThreads', EmbedThreads],
    [ComponentType.EmbedTweet]:               ['EmbedTwitter', EmbedTwitter],
    [ComponentType.EmbedTwitter]:             ['EmbedTwitter', EmbedTwitter],
    [ComponentType.Epg]:                      ['Epg', Epg],
    [ComponentType.ExperienceFragment]:       ['ExperienceFragment', ExperienceFragment],
    [ComponentType.F1Lineup]:                 ['F1Lineup', F1Lineup],
    [ComponentType.GraceNoteWidget]:          ['GracenoteWidget', GracenoteWidget],
    [ComponentType.ImageCard]:                ['ImageCard', ImageCard],
    [ComponentType.Links]:                    ['LinkList', LinkList],
    [ComponentType.LiveDashboard]:            ['LiveDashboard', LiveDashboard],
    [ComponentType.LiveTeasers]:              ['Section', Section],
    [ComponentType.Mastermedia]:              ['MasterMedia', MasterMedia],
    [ComponentType.Matchdays]:                ['Matchday', Matchday],
    [ComponentType.MedalList]:                ['MedalList', MedalList],
    [ComponentType.Mediaplayer]:              ['Mediaplayer', Mediaplayer],
    [ComponentType.MostRecentArticles]:       ['TabSection', TabSection],
    [ComponentType.MostTrendingArticles]:     ['Section', Section],
    [ComponentType.Multilive]:                ['Multilive', Multilive],
    [ComponentType.Nutshell]:                 ['Nutshell', Nutshell],
    [ComponentType.NewsletterSubscription]:   ['NewsletterSubscription', NewsletterSubscription],
    [ComponentType.PodcastInfo]:              ['PodcastInfo', PodcastInfo],
    [ComponentType.PlayerList]:               ['PlayerList', PlayerList],
    [ComponentType.Qualifio]:                 ['EmbedQualifio', EmbedQualifio],
    [ComponentType.Quote]:                    ['Quote', Quote],
    [ComponentType.Ranking]:                  ['TabSection', TabSection],
    [ComponentType.ReadMoreArticles]:         ['Section', Section],
    [ComponentType.RelatedArticle]:           ['Section', Section],
    [ComponentType.ResponsiveImageCarousel]:  ['ImageCarousel', ImageCarousel],
    [ComponentType.Result]:                   ['Result', Result],
    [ComponentType.Sailing]:                  ['Sailing', Sailing],
    [ComponentType.ScoreBoard]:               ['Scoreboard', Scoreboard],
    [ComponentType.Scoreboard]:               ['Scoreboard', Scoreboard],
    [ComponentType.Search]:                   ['Search', Search],
    [ComponentType.Statistics]:               ['Table', Table],
    [ComponentType.StoryCard]:                ['StoryCard', StoryCard],
    [ComponentType.Table]:                    ['Table', Table],
    [ComponentType.TabSection]:               ['TabSection', TabSection],
    [ComponentType.Text]:                     ['Paragraph', Paragraph],
    [ComponentType.Timeline]:                 ['Timeline', Timeline],
    [ComponentType.TimelineTeaser]:           ['Timeline', Timeline],
    [ComponentType.VerticalVideoPlaylist]:    ['Section', Section],
    [ComponentType.Voting]:                   ['Voting', Voting],
    [ComponentType.VrtmaxTeaser]:             ['StoryCard', StoryCard]
  }

  let component: any = false

  const componentProps = Object.keys(item?.componentProps || {}).length > 0 ? item.componentProps : item

  switch (item?.componentType) {
    case ComponentType.Section:
      switch (componentProps.layout) {
        case 'Event':
          component = shouldReturnBareBone
            ? ['Event', Event]
            : <Event key={item.componentName} {...componentProps} />
          break
        case 'Spotlight':
          component = shouldReturnBareBone
            ? ['Spotlight', Spotlight]
            : <Spotlight key={item.componentName} {...componentProps} />
          break
        default: {
          component = shouldReturnBareBone
            ? ['Section', Section]
            : <Section key={item.componentName} {...componentProps} />
        }
      }
      break
    case ComponentType.Parsys:
      return item.items?.length > 0 && <Fragment key={item.componentName}>
        {item.items.map((subItem: any) => mapToComponent(subItem, siteConfig))}
      </Fragment>
      break
    case ComponentType.WmRanking:
      component = shouldReturnBareBone
        ? ['WielerManager', WielerManager]
        : <WielerManager key={`${item.componentType}-${item.componentName}`} layout={WielerManagerLayout.Ranking}
                         data={componentProps} {...componentProps} {...siteConfig} />
      break
    case ComponentType.WmCountdown:
      component = shouldReturnBareBone
        ? ['WielerManager', WielerManager]
        : <WielerManager key={`${item.componentType}-${item.componentName}`} layout={WielerManagerLayout.Countdown}
                         data={componentProps} {...componentProps} {...siteConfig} />
      break
    case ComponentType.WmStats:
      component = shouldReturnBareBone
        ? ['WielerManager', WielerManager]
        : <WielerManager key={`${item.componentType}-${item.componentName}`} layout={WielerManagerLayout.Statistics}
                         data={componentProps} {...componentProps} {...siteConfig} />
      break
    case ComponentType.Title:
      component = shouldReturnBareBone
        ? ['Title', Title]
        : <Title key={`${item.componentType}-${item.componentName}`} {...componentProps} {...siteConfig} tag={TitleElement.H2} />
      break
    case ComponentType.ResponsiveImage:
      component = shouldReturnBareBone
        ? ['Image', Image]
        : <Image key={`${item.componentType}-${item.componentName}`} {...componentProps} {...siteConfig} columns={1} addToGallery={true}/>
      break
    default: {
      const element = Element[item.componentType]

      if (element){
        const Tag = Element[item.componentType][1]
        component = shouldReturnBareBone
          ? element
          : <Tag key={`${item.componentType}-${item.componentName}-${item.componentProps?.label}`} {...componentProps} {...siteConfig} />
      } else {
        logger.warn(`Component type ${item.componentType} not found`)
        component = false
      }
      break
    }
  }

  const componentKey = [item.componentType]
  item.componentName && componentKey.push(item.componentName)
  item.componentProps?.label && componentKey.push(item.componentProps.label)
  item.componentProps?.title && componentKey.push(item.componentProps.title)

  return shouldReturnBareBone
    ? component
    : <Suspense key={componentKey.toString()}>
      <ErrorBoundary author={item.author} componentId={`${item.componentType}${item.componentName ? `-${item.componentName}` : ''}`}>{component}</ErrorBoundary>
    </Suspense>
}

export {
  ComponentType,
  mapToComponent
}

import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import Caption, { CaptionWeight } from '../../../atoms/caption'
import Icon from '../../../atoms/icon'
import Title, { TitleSize } from '../../../atoms/title'
import Image from '../../../molecules/image'
import Pill from '../../../molecules/pill'
import { StatusTypes } from '../../../molecules/score'
import Card from '../../card'
import { LivestreamCardProps } from '../index'

import styles from './compact.module.scss'

const LivestreamCardCompact: FunctionComponent<LivestreamCardProps> = (
  {
    link,
    icon,
    title,
    subtitle,
    button,
    time,
    withHover = true,
    className,
    image,
    progress,
    ref
  }
) => {
  return <Card
    link={link}
    className={clsx(
      styles.livestreamCardCompact,
      className
    )}
    withHover={withHover}
    innerRef={ref}
  >
    <div className={styles.content}>
      <div className={styles.image}>
        {image ?
          <Image src={image} />
          :
          <Title size={TitleSize.Small} className={styles.message}>{button?.title}</Title>
        }
      </div>
      <div className={styles.textContent}>
        <Title className={styles.title} size={TitleSize.XSmall} darkMode={true}>
          {icon && <Icon className={styles.titleIcon} name={icon} />} {title}
        </Title>
        <Caption className={styles.time}>{time?.text}</Caption>
        <Caption weight={CaptionWeight.Medium} className={styles.subTitle}>{subtitle}</Caption>
      </div>
    </div>
    <div className={clsx(
      styles.progressBar,
      progress === undefined && styles.progressBarEmpty
    )}>
      <div className={styles.filler} style={{ width: `${progress}%` }} />
    </div>
  </Card>
}

export default LivestreamCardCompact

import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import Caption from '../../../atoms/caption'
import Title, { TitleSize } from '../../../atoms/title'

import styles from './custom.cell.module.scss'

interface CellWithHighlightTextProps {
  text?: string,
  textHighlight?: string,
  href?: string,
  column?: any,
  options?: Record<string, any>
}

const CellWithHighlightText: FunctionComponent<CellWithHighlightTextProps> = (
  {
    text,
    textHighlight,
    href,
    column,
    options = {}
  }
) => {
  const ElementTag = href ? 'a' : 'div'

  return <ElementTag
    href={href}
    className={clsx(
      styles.withHighlightText,
      options?.vertical && styles.vertical,
    )}
  >
    <Caption className={styles.text}>
      {text}&nbsp;
    </Caption>
    <Title
      size={TitleSize.XSmall}
      className={styles.textHighlight}
    >
      {textHighlight}
    </Title>
  </ElementTag>
}

export default CellWithHighlightText

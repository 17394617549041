import { CountdownRenderProps } from 'react-countdown/dist/Countdown'

type BaseCountdownProps = CountdownRenderProps

import styles from './base.module.scss'

export const BaseCountdown = (
  {
    days,
    hours,
    minutes,
    seconds
  }: BaseCountdownProps
) =>
  <div className={styles.countdown}>
    <div className={styles.timer}>
      <div className={styles.number}>{days}</div>
      <div className={styles.unit}>dagen</div>
    </div>
    <div className={styles.timer}>
      <div className={styles.number}>{hours}</div>
      <div className={styles.unit}>uur</div>
    </div>
    <div className={styles.timer}>
      <div className={styles.number}>{minutes}</div>
      <div className={styles.unit}>minuten</div>
    </div>
    <div className={styles.timer}>
      <div className={styles.number}>{seconds}</div>
      <div className={styles.unit}>seconden</div>
    </div>
  </div>

export default BaseCountdown

export type {
  BaseCountdownProps
}

import type { FunctionComponent } from 'react'
import type { StoryCardComponentProps } from '../../story-card'

import { TitleSize } from '../../../atoms/title'
import Cell from '../../cell'
import gridStyles from '../../grid/grid.module.scss'
import Section from '../../section/section'
import StoryCard from '../../story-card'

import styles from './articles.module.scss'

interface EventArticlesProps {
  items: StoryCardComponentProps[]
  logoUrl?: string
  link?: string
  darkMode?: boolean
  ebaData?: any
}

const EventArticles: FunctionComponent<EventArticlesProps> = (
  {
    items,
    logoUrl,
    link,
    darkMode = true,
    ebaData,
  }
) => {
  if (items?.length === 0) {
    return null
  }

  const itemsWithoutText = (items: StoryCardComponentProps[]) => {
    return items.map((item: any) => {
      return {
        ...item,
        text: undefined
      }
    })
  }

  const otherItems = itemsWithoutText(items).slice(1, 15)

  return <>
    <Cell size={4} className={gridStyles.collapse}>
      <StoryCard
        className={styles.hero}
        {...items[0].componentProps}
        layout='Horizontal'
        titleSize={TitleSize.Small}
        desktopTitleSize={TitleSize.XLarge}
        darkMode={darkMode}
        columns={1}
        ebaData={{
          listElementPosition: 1,
          elementTargetUrl: items[0]?.componentProps.link,
          elementTitle: items[0]?.componentProps.title,
          ...ebaData
        }}
      />
    </Cell>
    <Cell size={4} className={gridStyles.collapse}>
      <Section
        className={styles.small}
        items={otherItems.slice(0,4)}
        darkMode={darkMode}
        layout={'Grid4Column'}
        link={link}
        linkText="meer"
        ebaData={{
          ...ebaData,
          listElementOffset: 1,
        }}
      />
      <Section
        className={styles.medium}
        items={otherItems}
        darkMode={darkMode}
        logoUrl={logoUrl}
        link={link}
        linkText="meer"
        showMoreTile={otherItems?.length > 4}
        layout={otherItems?.length <= 4 ? 'Grid4Column' : 'Grid4ColumnSliderBottom'}
        ebaData={{
          ...ebaData,
          listElementOffset: 1,
        }}
      />
    </Cell>
  </>
}

export default EventArticles

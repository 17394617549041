enum AspectRatio {
  _16_9 = '16:9',
  _9_16 = '9:16',
  _4_5 = '4:5',
}

enum VideoType {
  Asset = 'asset',
  Live = 'live',
  Youtube = 'youtube'
}

enum AudioType {
  Asset = 'asset',
  Live = 'live',
  Podcast = 'podcast'
}

enum SkinVariant {
  Dark = 'dark',
  Light = 'light'
}

enum VideoErrorMessages {
  VIDEO_NOT_FOUND = 'deze inhoud werd niet gevonden', // why "inhoud"? also for audio
  // CONTENT_AVAILABLE_ONLY_IN_BE = 'deze video is enkel beschikbaar in België',
  // CONTENT_AVAILABLE_ONLY_FOR_BE_RESIDENTS_AND_EXPATS = 'je moet ingelogd zijn om deze video te bekijken buiten België',
}

enum LivestreamTitles {
  vualto_radio1 = 'Radio 1',
  vualto_radio2 = 'Radio 2',
  vualto_stubru = 'StuBru',
  vualto_klara = 'Klara',
  vualto_mnm = 'MNM',
  vualto_journaal = 'Journaal',
  vualto_nieuws = 'Nieuws',
  vualto_sporza = 'Sporza',
  vualto_een = 'een',
  vualto_canvas = 'Canvas',
  vualto_ketnet = 'Ketnet',
  vualto_radio_events = 'Radio Events',
  vualto_events1 = 'Events 1',
  vualto_events2 = 'Events 2',
  vualto_events3 = 'Events 3'
}

enum MediaplayerLayout {
  Regular = 'regular',
  Embed = 'embed',
  Mini = 'mini',
  Shortform = 'shortform',
  Seamless = 'seamless',
  Detached = 'detached',
}

export {
  AspectRatio,
  VideoType,
  AudioType,
  SkinVariant,
  VideoErrorMessages,
  LivestreamTitles,
  MediaplayerLayout
}

enum DrawType {
  PouleGroups = 'poule-groups', // deprecated, use PotsGroups instead
  PotsGroups = 'pots-groups',
  PotsMatches = 'pots-matches',
  Selection = 'selection',
  IedereenBondscoachSelection = 'iedereen-bondscoach-selection'
}

enum DrawItemType {
  Team = 'team',
  Player = 'player'
}

function isSelectionDrawType(drawType: string | undefined) {
  return [
    { id: 'iedereen-bondscoach-selection' },
    { id: 'selection' },
  ].find(type => type.id === drawType) !== undefined
}

function isIedereenBondscoach(drawType: string | undefined) {
  return [
    { id: 'iedereen-bondscoach-selection' }
  ].find(type => type.id === drawType) !== undefined
}

function hasExternalId(drawType: string | undefined) {
  return isIedereenBondscoach(drawType)
}

export {
  DrawType,
  DrawItemType,
  isSelectionDrawType,
  isIedereenBondscoach,
  hasExternalId
}

import type { FunctionComponent, PropsWithChildren } from 'react'
import type { Icons } from '../../design-tokens/iconography/icons'

import clsx from 'clsx'

import Icon from '../../atoms/icon'
import Title from '../../atoms/title'

import styles from './heading.module.scss'

type HeadingVariant = 'primary' | 'secondary'

enum HeadingAlignment {
  Left = 'Left',
  Center = 'Center',
  Right = 'Right'
}

interface HeadingProps extends PropsWithChildren {
  title?: string
  iconBefore?: string | Icons
  iconAfter?: string | Icons
  className?: string
  align?: string | HeadingAlignment
  darkMode?: boolean
  variant?: HeadingVariant
}

const headerStyles: { [Properties in HeadingVariant]: string } = {
  primary: styles.headingPrimary,
  secondary: styles.headingSecondary
}

const Heading: FunctionComponent<HeadingProps> = (
  {
    title,
    iconBefore,
    iconAfter,
    className,
    variant = 'primary',
    align = 'Left',
    darkMode,
    children
  }
) => {
  return <header
    className={clsx(
      variant ? headerStyles[variant] : headerStyles.primary,
      iconAfter && styles.headingAlignIconRight,
      (align === HeadingAlignment.Center && !iconAfter) && styles.headingAlignCenter,
      align === HeadingAlignment.Right && styles.headingAlignRight,
      !title && !children && styles.headingIsIconOnly,
      className
    )}>
    {iconBefore && <Icon name={iconBefore} className={styles.headingIconBefore} darkMode={!!darkMode}/>}
    <Title size={variant === 'primary' ? 'Small' : undefined}>{children}</Title>
    {iconAfter && <Icon name={iconAfter} className={styles.headingIconAfter} darkMode={!!darkMode}/>}
  </header>
}

export default Heading

export type {
  HeadingProps,
  HeadingVariant
}

export {
  HeadingAlignment
}

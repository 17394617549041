import { logger } from '@sporza/services/logger'
import { useEffect, useState } from 'react'

const useWebShareApi = () => {
  const [isSupported, setSupport] = useState(false)

  useEffect(
    () => {
      if ('share' in navigator) {
        setSupport(true)
      } else {
        setSupport(false)
      }
    },
    []
  )

  const share = async (shareData: ShareData) => {
    if (!isSupported) {
      logger.warn('Web Share API is not supported')
    }

    try {
      if (!(navigator.canShare(shareData))) {
        throw new Error('Can\'t share data.', data)
      }

      await navigator.share(shareData)
    } catch (error) {
      logger.error('Failed to share', error)
    }
  }

  return {
    isWebShareApiSupported: isSupported,
    share,
  }
}

export {
  useWebShareApi
}

import type { FunctionComponent, PropsWithChildren } from 'react'
import type { Icons } from '../../design-tokens/iconography/icons'

import clsx from 'clsx'

import Icon from '../../atoms/icon'
import Title, { TitleSize } from '../../atoms/title'

import styles from './notification.module.scss'

enum NotificationType {
  Default = 'default',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Success = 'success',
}

interface NotificationProps extends PropsWithChildren {
  type?: NotificationType
  label?: string
  text?: string
  iconBefore?: Icons
  hidden?: boolean
  className?: string
  darkMode?: boolean
}

const getIconFromType = (type: NotificationType) => {
  switch (type) {
    case NotificationType.Info:
      return 'info'
    case NotificationType.Warning:
    case NotificationType.Error:
      return 'error'
    case NotificationType.Success:
      return 'check-on'
    default:
      return
  }
}

const Notification: FunctionComponent<NotificationProps> = (
  {
    type = NotificationType.Default,
    label,
    text,
    iconBefore,
    hidden,
    className,
    darkMode
  }
) => {
  const icon = iconBefore || getIconFromType(type)

  return hidden
    ? <div
      dangerouslySetInnerHTML={{
        __html: `<div class="sw-notification"><!-- \n ${label} \n ${text}\n--></div>`
      }}
    />
    : <div
      className={clsx(
        styles.notification,
        darkMode && styles.darkMode,
        type === NotificationType.Info && styles.info,
        type === NotificationType.Warning && styles.warning,
        type === NotificationType.Error && styles.error,
        type === NotificationType.Success && styles.success,
        className
      )}
    >
      {icon && <i className={styles.iconBefore}><Icon name={icon}/></i>}
      <div className={styles.content}>
        {label && <Title size={TitleSize.XSmall}>{label}</Title>}
        {text}
      </div>
    </div>
}

export {
  Notification,
  NotificationType
}

export type {
  NotificationProps
}

import { AspectRatio, VideoType } from '../index'

const getAdConfig = async (
  {
    showAds,
    isAudio,
    type,
    aspectRatio,
    mediaReferences
  }: any
) => {
  if (!showAds || isAudio) return {}

  // wait for kaching, max 5 secs
  const getGamAdUrl = () => new Promise((resolve) => {
    let times = 0
    const check = async () => {
      const gamAdUrl = typeof window.kaching !== 'undefined' && window.kaching.getVideoUrl(mediaReferences[0])

      switch (true) {
        case typeof gamAdUrl === 'string':
          resolve(gamAdUrl)
          break
        case times > 4:
          resolve(undefined)
          break
        default:
          times++
          await new Promise(resolve => setTimeout(resolve, 1000))
          check()
      }
    }

    check()
  })

  const gamAdUrl = await getGamAdUrl()
  let gamAdUrlWithSporzaParams = gamAdUrl
    ? addParam(((type === VideoType.Live) ? addLivestream(gamAdUrl) : gamAdUrl), 'cmsid', '2644270')
    : undefined

  if (gamAdUrlWithSporzaParams && aspectRatio === AspectRatio._4_5 || aspectRatio === AspectRatio._9_16) {
    gamAdUrlWithSporzaParams = addParam(gamAdUrlWithSporzaParams, 'sz', '360x640')
  }

  return {
    gamAdUrl: gamAdUrlWithSporzaParams
  }
}

const getPlayerLibrary = (env?: string) => {
  let playerApiUrl

  switch (env) {
    case 'dev':
      playerApiUrl = 'https://player-dev.vrt.be/sporza/js/main.js'
      break
    case 'stag':
      playerApiUrl = 'https://player-stag.vrt.be/sporza/js/main.js'
      break
    default:
      playerApiUrl = 'https://player.vrt.be/sporza/js/main.js'
  }

  return playerApiUrl
}

const addLivestream = (url: any) => {
  if (url) {
    const newUrl = new URL(url)
    const iu = newUrl.searchParams.get('iu')
    if (iu !== null) newUrl.searchParams.set('iu', `${iu}/livestream`)

    return newUrl.href
  } else return undefined
}

const addParam = (url: any, key: string, value: string) => {
  if (url) {
    const newUrl = new URL(url)
    newUrl.searchParams.set(key, value)

    return newUrl.href
  } else return undefined
}

const getAutoPlayFromUrl = () => {
  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search)

  return params.get('autoplay') === 'true'
}

export {
  getPlayerLibrary,
  getAdConfig,
  addLivestream,
  addParam,
  getAutoPlayFromUrl
}

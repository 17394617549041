import type { ClassValue } from 'clsx'
import type { FunctionComponent, PropsWithChildren } from 'react'
import type { WielerManagerContainerProps } from './container'

import { useMatch } from '@sporza/hooks'
import { isObjectWithData } from '@sporza/utils/objects'
import { capitalize } from '@sporza/utils/strings'
import { getPathName, getPermalinkId } from '@sporza/utils/urls'
import clsx from 'clsx'
import { useState } from 'react'

import Paragraph from '../../../atoms/paragraph'
import Title, { TitleSize } from '../../../atoms/title'
import Button from '../../../molecules/button'
import Countdown from '../../../molecules/countdown'
import { getTitleFromMatch } from '../helpers'
import { trackBannerClick } from '../wielermanager'

import commonStyles from './common.module.scss'
import WielerManagerContainer from './container'
import containerStyles from './container.module.scss'
import styles from './countdown.module.scss'

interface WielerManagerCountdownProps extends WielerManagerContainerProps, PropsWithChildren {
  className?: ClassValue
  title?: string
  data?: any
  statisticsUrl?: string
  podcastUrl?: string
  year?: string
  freeze?: boolean
}

const getTitle = (defaultTitle?: string, isLive?: boolean) =>
  isLive && defaultTitle
    ? defaultTitle
    : 'Jouw ploeg mee aan de start?'

const WielerManagerCountdown: FunctionComponent<WielerManagerCountdownProps> = (
  {
    widgetName,
    source,
    innerRef,
    bannerContext,
    title ,
    data = {},
    year = new Date().getFullYear().toString(),
    statisticsUrl,
    podcastUrl,
    containerQueries,
    showSponsor,
    freeze
  }
) => {
  const { match = {} } = data
  const hasMatchData = isObjectWithData(match)
  const subTitle = getTitleFromMatch(hasMatchData && match, `Doe mee met de ${widgetName} ${year}!`)
  const { isLive: defaultIsLive } = useMatch(match)
  const [isLive, setIsLive] = useState(defaultIsLive)

  return <WielerManagerContainer
    widgetName={widgetName}
    innerRef={innerRef}
    containerQueries={containerQueries}
    className={clsx(
      isLive && styles.live,
      containerStyles.countdown
    )}
    source={source}
    isLive={isLive}
    ctaUrl={isLive ? match.externalUrl : undefined}
    showSponsor={showSponsor}
    bannerContext={bannerContext}
    match={match}
    year={year}
  >
    <div>
      <Title
        className={commonStyles.title}
        size={TitleSize.Large}
      >{getTitle(capitalize(widgetName) + ' ' + year, isLive)}</Title>
      {
        subTitle && <Title
          className={clsx(
            commonStyles.subtitle,
            isLive && commonStyles.isLive
          )}
          size={TitleSize.Small}
        >{subTitle}</Title>
      }
      {
        !isLive
          ? <>
            {
              !containerQueries?.medium
                ? <Countdown
                  className={styles.countdownCounter}
                  date={match.startTime}
                  onComplete={() => setIsLive(true)}
                  freeze={freeze}
                />
                : null
            }
            <Paragraph className={commonStyles.paragraph}>
              {`Stel op tijd jouw ploeg samen en word dé ${capitalize(widgetName)} van ${year}!`}
            </Paragraph>
            <Button
              iconAfter="chevron-right"
              href={`https://${widgetName}.sporza.be`}
              target="_blank"
              withBorder={true}
              onClick={() => {
                trackBannerClick({
                  ...bannerContext,
                  targetPageUrl: `https://${widgetName}.sporza.be`,
                  targetPageName: '/',
                  targetPageId: widgetName + '-stel-je-ploeg-samen'
                })
                return true
              }}
            >Stel je ploeg samen</Button>
          </>
          : null
      }
    </div>
    <div>
      {
        isLive
          ? <Button
            className={styles.liveButton}
            iconAfter="chevron-right"
            withBorder={true}
            onClick={() => {
              trackBannerClick({
                ...bannerContext,
                targetPageUrl: match.externalUrl,
                targetPageName: getPathName(match.externalUrl),
                targetPageId: getPermalinkId(match.externalUrl)
              })
              return true
            }}
          >Rit volgen</Button>
          : <>
            {
              containerQueries?.medium
                ? <Countdown
                  className={styles.countdownCounter}
                  date={match.startTime}
                  onComplete={() => setIsLive(true)}
                  freeze={freeze}
                />
                : null
            }
            <Button
              className={commonStyles.link}
              iconBefore="podcasts"
              variant="tertiary"
              href={podcastUrl}
              target="_blank"
              onClick={() => {
                trackBannerClick({
                  ...bannerContext,
                  targetPageUrl: podcastUrl,
                  targetPageName: getPathName(podcastUrl),
                  targetPageId: getPathName(podcastUrl)
                })
                return true
              }}
            >Beluister het advies van de Kopman</Button>
            <Button
              className={commonStyles.link}
              iconBefore="soccer-stats"
              variant="tertiary"
              href={statisticsUrl}
              target="_blank"
              onClick={() => {
                trackBannerClick({
                  ...bannerContext,
                  targetPageUrl: statisticsUrl,
                  targetPageName: getPathName(statisticsUrl),
                  targetPageId: getPathName(statisticsUrl)
                })
                return true
              }}
            >Bekijk statistieken</Button>
          </>
      }
    </div>
  </WielerManagerContainer>
}

export default WielerManagerCountdown

export type {
  WielerManagerCountdownProps
}

import type { FunctionComponent, PropsWithChildren } from 'react'

import MedalSummary, { MedalSummaryComponentProps } from '../medal-summary'
import MedalTable, { MedalTableComponentProps } from '../medal-table'

import styles from './medal-list.module.scss'

interface MedalListProps extends PropsWithChildren {
  summary?: MedalSummaryComponentProps
  table?: MedalTableComponentProps
}

const MedalList: FunctionComponent<MedalListProps> = (
  {
    summary,
    table
  }
) => {
  return <div className={styles.medalList}>
    {summary && <MedalSummary {...summary.componentProps} />}
    {table && <MedalTable {...table.componentProps} />}
  </div>
}

export default MedalList

export type {
  MedalListProps
}

import type { FunctionComponent } from 'react'
import type { DataCardProps } from '../../index'

import clsx from 'clsx'

import Caption from '../../../../atoms/caption'
import Meta from '../../../../atoms/meta'
import Title, { TitleSize } from '../../../../atoms/title'
import DataList from '../../../../molecules/data-list'
import Image, { ImageLayout } from '../../../../molecules/image'
import Card from '../../../card'
import styles from '../../data-card.module.scss'


const DataCardDefault: FunctionComponent<DataCardProps> = (
  {
    title,
    img,
    subTitle,
    nationality,
    dataList,
    link,
    className,
    square = true
  }
) => {
  return <Card
    link={link}
    className={clsx(
      styles.dataCard,
      className
    )}
  >
    <div className={clsx(
      styles.headerWrapper,
      dataList && dataList.length > 0 && styles.withDataList
    )}>
      <Image
        src={img}
        layout={square ? ImageLayout.Square : ImageLayout.Widescreen}
        className={square ? styles.image : styles.imageFlag}
      />
      <div className={styles.header}>
        <Title className={styles.title} size={TitleSize.Small}>{title}</Title>
        {subTitle && <Caption className={styles.subTitle}>{subTitle}</Caption>}
      </div>
      {nationality && <div className={styles.country}>
        {nationality.flagUrl && <Image noCrop src={nationality.flagUrl} />}
        {nationality.name && <Meta>{nationality.name}</Meta>}
      </div>}
    </div>
    {dataList && dataList[0] && dataList[0].map((item, index) => <DataList
      key={`${title}${index}`}
      className={styles.dataList}
      {...item.componentProps}
    />)}
  </Card>
}

export {
  DataCardDefault
}

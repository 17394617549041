import { cookieExists } from './cookies'

// source: https://bitbucket.org/vrt-prod/cookie-consent-widget/src/develop/app/vendorlistSP.json

enum Vendor {
  Disqus = 'Disqus',
  Facebook = 'Facebook',
  Flickr = 'Flickr',
  Giphy = 'Giphy',
  Imgur = 'Imgur',
  Instagram = 'Instagram',
  GoogleMaps = 'Google Maps',
  SbsBelgium = 'SBS Belgium',
  Qualifio = 'Qualifio',
  Reddit = 'Reddit, Inc.',
  Soundcloud = 'Soundcloud',
  Spotify = 'Spotify',
  Threads = 'Threads',
  TikTok = 'TikTok',
  '𝕏' = '𝕏',
  Youtube = 'Youtube',
  Yumpu = 'Yumpu',
  Vimeo = 'Vimeo',
}

enum VendorId {
  Disqus = '5f5b4728b8e05c71783b6167',
  Facebook = '5e716fc09a0b5040d575080f',
  Flickr = '5ec462c02330505ab89fbb38',
  Giphy = '5eb97863c9a2c12e5cfa5127',
  Imgur = '5eb97ac2ac00ef2553a4c532',
  Instagram = '5e717c8e69966540e4554f05',
  GoogleMaps = '5eb97b265852312e6a9fbf31',
  SbsBelgium = '6222392372cf7305b8dc2c87',
  Qualifio = '5f32c8fa89534d15fe308ebb',
  Reddit = '5e7f6927b8e05c4e491e7381',
  Soundcloud = '602e80f9a228631b7043546e',
  Threads = '64f98e53f82fce06c7570736',
  Spotify = '5e7ad2ef1affb21bd098ccef',
  Telegram = '6233165dbfb42506c3db07a6',
  TikTok = '5e7f6927b8e05c4e491e7380',
  '𝕏' = '5fab0c31a22863611c5f8764',
  Youtube = '5e7ac3fae30e7d1bc1ebf5e8',
  Yumpu = '5f432c5838862e3e0d530c81',
  Vimeo = '5eac148d4bfee33e7280d13b'
}

enum VendorPurposeId {
  Disqus = '622212e45ac46d06cefebfbe',
  Facebook = '622212e45ac46d06cefebfbe',
  Flickr = '622212e45ac46d06cefebfbe',
  Giphy = '622212e45ac46d06cefebfbe',
  Imgur = '622212e45ac46d06cefebfbe',
  Instagram = '622212e45ac46d06cefebfbe',
  GoogleMaps = '622212e45ac46d06cefebfbe',
  SbsBelgium = '622212e45ac46d06cefebfbe',
  Qualifio = '622212e45ac46d06cefebfac',
  Reddit = '622212e45ac46d06cefebfbe',
  Soundcloud = '622212e45ac46d06cefebfbe',
  Spotify = '622212e45ac46d06cefebfbe',
  Threads = '622212e45ac46d06cefebfbe',
  Telegram = '622212e45ac46d06cefebfbe',
  TikTok = '622212e45ac46d06cefebfbe',
  '𝕏' = '622212e45ac46d06cefebfbe',
  Youtube = '622212e45ac46d06cefebfbe',
  Yumpu = '622212e45ac46d06cefebfac',
  Vimeo = '622212e45ac46d06cefebfbe'
}

const isVendorAllowed = (vendor: string) => {
  if (window?.VRT?.CODRegulator?.getAllowedSocialMedia) {
    const allowedSocialMedia = window.VRT.CODRegulator.getAllowedSocialMedia()

    if (allowedSocialMedia && allowedSocialMedia.includes(vendor)) {
      return true
    }
  }

  return false
}

const addVendorTags = () => {
  window?.VRT?.CODRegulator?.addTags()
}

const isLoggedIn = () => window?.VRT?.SSOController?.getUserData() || cookieExists('sporza-site_profile_at')

type EnableConsentProps = {
  vendorId: string
  vendorPurposeId: string
  callback?: () => void
}

const enableConsent = (
  {
    vendorId,
    vendorPurposeId,
    callback
  }: EnableConsentProps
) => {
  const cb = () => {
    setTimeout(addVendorTags, 0)

    if (callback) {
      callback()
    }
  }

  window.__tcfapi('postCustomConsent', 2, cb, [vendorId], [vendorPurposeId], [])
}

export {
  Vendor,
  VendorId,
  VendorPurposeId,
  isVendorAllowed,
  addVendorTags,
  isLoggedIn,
  enableConsent
}

import type { FunctionComponent, PropsWithChildren } from 'react'

import clsx from 'clsx'

import styles from './well.module.scss'

interface WellProps extends PropsWithChildren {
  className?: string
}

const Well: FunctionComponent<WellProps> = (
  {
    className,
    children
  }
) => {
  return <section
    className={clsx(
      className,
      styles.well
    )}>
    {children}
  </section>
}

export default Well

export type {
  WellProps
}

import type { FunctionComponent } from 'react'

import Title, { TitleElement, TitleSize } from '../../atoms/title'

import { F1LineupGroup } from './components'
import styles from './f1-lineup.module.scss'

interface F1LineupProps {
  label?: string
  items?: any[]
}

const F1Lineup: FunctionComponent<F1LineupProps> = (
  {
    label,
    items
  }
) => {

  return <div className={styles.f1Lineup}>
    {
      label
      && <Title
        className={styles.label}
        size={TitleSize.Medium}
        tag={TitleElement.H2}
      >
        {label}
      </Title>
    }
    {
      items?.length
        ? items?.map((item: any, index: number) =>
          <F1LineupGroup key={index} {...item} />
        )
        : false
    }
  </div>
}

export {
  F1Lineup
}

export type {
  F1LineupProps
}

import { base64ToBlob } from '@sporza/utils/data'
import { createFileName, useScreenshot as useScreen } from 'use-react-screenshot'

enum ScreenshotType {
  PNG = 'image/png',
  JPEG = 'image/jpeg'
}

enum ScreenshotExtension {
  PNG = 'png',
  JPEG = 'jpeg'
}

const getExtension = (type: ScreenshotType) => {
  switch (type) {
    case ScreenshotType.JPEG:
      return ScreenshotExtension.JPEG
    default:
    case ScreenshotType.PNG:
      return ScreenshotExtension.PNG
  }
}

const useScreenshot = (
  ref: React.RefObject<any>,
  options: {
    type?: ScreenshotType
  } = {}
) => {
  const {
    type = ScreenshotType.PNG
  } = options

  const [image, takeScreenshot] = useScreen({
    type
  })

  const getImage = async () => {
    const style = document.createElement('style')
    document.head.appendChild(style)
    style.sheet?.insertRule('body > div:last-child img { display: inline-block; }')

    const result = await takeScreenshot(ref.current, {
      allowTaint: true, // Allow taint on cross-origin images
      useCORS: true, // Enable CORS for cross-origin images
      logging: true, // Enable logging for debugging
    })

    style.remove()

    return result
  }

  const getImageBlob = async () => {
    const base64Image = await getImage()

    return base64ToBlob(base64Image, type)
  }

  const downloadImage = async () => {
    const data = await getImage()

    download(data, { extension: getExtension(type) })
  }

  const download = (image: string, { name = 'img', extension = 'png' } = {}) => {
    const a = document.createElement('a')

    a.href = image
    a.download = createFileName(extension, name)
    a.click()
  }

  return {
    image,
    imageExtension: getExtension(type),
    takeScreenshot,
    getImage,
    getImageBlob,
    downloadImage
  }
}

export {
  useScreenshot
}

enum BannerTheme {
  light = 'light',
  dark = 'dark',
  vrtmax = 'vrtmax',
  OlympischeSpelen2024 = 'olympische-spelen-2024',
  ParalympischeSpelen2024 = 'olympische-spelen-2024',
  EKWielrennen2024 = 'ek-wielrennen-2024',
  Play = 'play',
  GenericPlay = 'generic-play',
  PlayPremierLeague = 'play-premierleague',
  GenericPlayPremierLeague = 'generic-play-premierleague',
  Play6 = 'play6',
  GenericPlay6 = 'generic-play6',
}

enum BannerVariant {
  default = 'default',
  appPromo = 'appPromo'
}

enum BannerLayout {
  default = 'default',
  small = 'small',
  image = 'image'
}

enum BannerLogo {
  vrtmax = 'vrtmax',
  engie = 'engie',
  EKWielrennen2024 = 'ek-wielrennen-2024'
}

export {
  BannerTheme,
  BannerVariant,
  BannerLayout,
  BannerLogo
}

import type { FunctionComponent } from 'react'

import Icon from '../../../../atoms/icon'
import Title, { TitleSize } from '../../../../atoms/title'
import styles from '../../score.module.scss'
import { ScoreDistanceProps } from '../default'

const BannerDistance: FunctionComponent<ScoreDistanceProps> = ({
  distanceToGo,
  startLabel,
  endLabel,
  date,
  darkMode
}) => {
  return <Title darkMode={darkMode} size={TitleSize.XSmall}>
    {date && <span className={styles.item}>{date}</span>}
    <span className={styles.item}>{startLabel}{endLabel && <><Icon name={'arrow-right-thin'} /> {endLabel}</>}</span>
    {distanceToGo && <span className={styles.item}>nog {distanceToGo} km</span>}
  </Title>
}

export default BannerDistance

export type {
  ScoreDistanceProps
}

import type { FunctionComponent, PropsWithChildren } from 'react'

import clsx from 'clsx'
import Sticky from 'react-stickynode'

import Title from '../../atoms/title'
import Well from '../../molecules/well'

import styles from './shortcut.module.scss'

interface ShortcutsProps extends PropsWithChildren {
  items: string[]
  darkMode?: boolean
}

const scrollToElement = (id: string) => {
  const element = document.querySelector(`[data-tab-active="true"] [data-id="${id}"]`)
  if (element instanceof HTMLElement) {
    window.scrollTo({
      top: parseInt(String(element.offsetTop)),
      behavior: 'smooth'
    })
  }
}

const Shortcuts: FunctionComponent<ShortcutsProps> = (
  {
    items = [],
    darkMode
  }
) => {
  const letters = 'abcdefghijklmnopqrstuvwxyz'.split('')

  return <Sticky enabled={true} bottomBoundary={'#azlist'} top={140}>
    <Well className={clsx(
      styles.shortcutsWrapper,
      darkMode && styles.tagDark
    )}>
      <Title size={'XSmall'}>zoek alfabetisch</Title>
      <div className={styles.shortcuts}>
        {letters.map((item: string, index: number) => {
          const active = items.includes(item)
          return <button key={`${index}-${item}`} className={styles.letter} onClick={() => scrollToElement(item)} data-href={active ? `/nl/a-z#${item}` : undefined}>{item.toUpperCase()}</button>
        })}
      </div>
    </Well>
  </Sticky>
}

export default Shortcuts

export type { ShortcutsProps }

import type { BannerProps } from '../banner'

import clsx from 'clsx'
import { forwardRef } from 'react'

import Caption from '../../../atoms/caption'
import Icon from '../../../atoms/icon'
import Title, { TitleSize } from '../../../atoms/title'

import styles from './small.module.scss'

const BannerSmall = forwardRef<HTMLInputElement, BannerProps>((
  {
    title,
    subTitle,
    link,
    themeConfig,
    className,
    click
  },
  ref
) => {
  const targetLink = themeConfig?.link ?? link
  const Tag = targetLink
    ? 'a'
    : 'div'

  return <Tag
    className={clsx(
      styles.wrapper,
      className
    )}
    onClick={() => click()}
    href={targetLink ? targetLink : undefined}
    target={targetLink ? '_blank' : undefined}
    ref={ref}
  >
    <div className={styles.content}>
      <Title className={styles.title} size={TitleSize.XSmall}>{title}<Icon className={styles.icon} name={'chevron-right'} /></Title>
      {subTitle && <Caption className={styles.caption}>{subTitle}</Caption>}
    </div>
  </Tag>
})

BannerSmall.displayName = 'BannerSmall'

export {
  BannerSmall
}

import type { FunctionComponent, PropsWithChildren } from 'react'

import Caption from '../../../../atoms/caption'
import Meta from '../../../../atoms/meta'
import { StatusTypes } from '../../index'
import styles from '../../score.module.scss'

interface ScoreRoundsProps extends PropsWithChildren {
  roundsToGo?: number
  label?: string
  date?: string
  startLabel?: string
  darkMode?: boolean
  status?: StatusTypes | string
}

const DefaultRounds: FunctionComponent<ScoreRoundsProps> = ({
  roundsToGo,
  label
}) => {
  return <>
    {roundsToGo && <Meta className={styles.label}>nog <span className={styles.bigNumber}>{roundsToGo}</span> {roundsToGo === 1 ? 'ronde' : 'rondes'}</Meta>}
    {label && <Caption className={styles.label}>{label}</Caption>}
  </>
}

export default DefaultRounds

export type {
  ScoreRoundsProps
}

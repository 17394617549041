import clsx from 'clsx'
import React, { FunctionComponent } from 'react'

import ProgramItem from '../../program-item'
import { ProgramListProps } from '../index'
import styles from '../program-list.module.scss'

const ProgramListDefault: FunctionComponent<ProgramListProps> = ({
  start,
  end,
  items
}) => {
  return <ol className={clsx(
    styles.programList,
    styles.programListDefault
  )}>
    <li className={styles.background}></li>
    {items.map((item, index) => <ProgramItem
      listStart={start}
      listEnd={end}
      key={`${index}-${item.title}-${item.subTitle}`}
      {...item}
    />)}
  </ol>
}

export default ProgramListDefault

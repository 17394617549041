import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import Button from '../../../../../molecules/button'
import {
  getRoundIcon,
  shortenRoundLabel
} from '../helpers'

import styles from './bracket-navigation.module.scss'

interface BracketNavigationProps {
  rounds: any[]
  activeRound: number
  activeSubRound: number
  onNavigate: (index: number, subIndex?: number) => void
}

const BracketNavigation: FunctionComponent<BracketNavigationProps> = (
  props
) => {
  const {
    rounds,
    activeRound,
    activeSubRound,
    onNavigate,
  } = props

  return <div className={styles.navigation}>
    {
      rounds.map((round, index) => {
        const shouldSplitMatches = round.items?.length > 8

        const buttons = shouldSplitMatches
          ? <>
            <Button
              className={clsx(
                index === activeRound
                && (!activeSubRound || activeSubRound === 0)
                && styles.roundIsActive,
              )}
              iconAfter={getRoundIcon(rounds, index)}
              onClick={() => onNavigate(index, 0)}
            />
            <hr className={styles.separator}/>
            <Button
              className={clsx(
                index === activeRound
                && activeSubRound === 1
                && styles.roundIsActive,
              )}
              iconAfter={getRoundIcon(rounds, index)}
              onClick={() => onNavigate(index, 1)}
            />
          </>
          : <Button
            className={clsx(
              index === activeRound && styles.roundIsActive,
            )}
            iconAfter={getRoundIcon(rounds, index)}
            onClick={() => onNavigate(index)}
          />

        return <div
          key={round.label}
          className={clsx(
          styles.round,
          shouldSplitMatches && styles.roundWithSubRounds,
        )}>
          <div className={styles.label}>{shortenRoundLabel(round.label)}</div>
          {buttons}
        </div>
      })
    }
  </div>
}

export default BracketNavigation

export {
  BracketNavigation
}

export type {
  BracketNavigationProps
}

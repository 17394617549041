import type { FunctionComponent } from 'react'
import type { Icons } from '../../../../design-tokens/iconography/icons'
import type { ScoreRoundsProps } from '../default'

import CellWithIcon from '../../../../organisms/table/components/cell-with-icon'
import { StatusTypes } from '../../index'

interface ScoreResultRoundsProps extends ScoreRoundsProps{
  winner?: any
  startLabel?: string
  status?: string | StatusTypes
  url?: string,
  roundsToGoIcon?: Icons
}

const ResultRounds: FunctionComponent<ScoreResultRoundsProps> = (
  {
    url,
    roundsToGo,
    winner,
    startLabel,
    status,
    roundsToGoIcon
  }) => {
  const Tag = url ? 'a' : 'div'

  return <Tag href={url}>
    {
      status === StatusTypes.End && winner &&
      <CellWithIcon icon={'trophy'} name={winner.name} href={winner.href}/>
    }
    {
      status === StatusTypes.Live && roundsToGo &&
      <CellWithIcon icon={roundsToGoIcon} name={'nog ' + roundsToGo + (roundsToGo === 1 ? ' ronde' : ' rondes')}/>
    }
    {
      (
        status === StatusTypes.NotStarted
        || status === StatusTypes.AfterToday
      )
      && startLabel
      && <CellWithIcon icon={'clock-with-background'} name={startLabel}/>
    }
  </Tag>
}

export default ResultRounds

export type {
  ScoreResultRoundsProps
}

import type { CSSProperties, FunctionComponent } from 'react'
import type { AvatarProps } from '../../../molecules/avatar'
import type { CardProps } from '../../card'

import { getThemeColorFromValue, Theme } from '@sporza/config'
import clsx from 'clsx'

import { Avatar } from '../../../molecules/avatar'
import styles from '../media-card.module.scss'

type MediaCardAvatarProps = AvatarProps & Partial<CardProps> & {
  theme?: Theme
}

const MediaCardAvatar: FunctionComponent<MediaCardAvatarProps> = (
  {
    image,
    icon,
    title,
    text,
    innerRef,
    className,
    darkMode,
    onClick,
    theme = Theme.Default
  }
) => {
  return <Avatar
    image={image}
    icon={icon}
    title={title}
    text={text}
    className={clsx(
      styles.mediaAvatar,
      className,
      darkMode && styles.dark,
      styles.clickable
    )}
    style={
      theme === Theme.OlympischeSpelen2024 || theme === Theme.ParalympischeSpelen2024
        ? getThemeColorFromValue(title ? title : icon) as CSSProperties
        : undefined
    }
    innerRef={innerRef}
    onClick={onClick}
  />
}

export {
  MediaCardAvatar
}

export type {
  MediaCardAvatarProps,
}

import type { FunctionComponent, PropsWithChildren, ReactElement } from 'react'
import type { ButtonProps } from '../../molecules/button'

import clsx from 'clsx'

import styles from './az-list.module.scss'
import Default from './layout'

type AZListLayoutTypes =
  'default'

type AZListTabItemType = { label: string, items: AZListListType[] }
type AZListListType = { title: string, href: string, items: ButtonProps[] }

type AZListDataType = AZListTabItemType[]

interface AZListProps extends PropsWithChildren {
  data: AZListDataType,
  layout?: AZListLayoutTypes
}

const Layout: FunctionComponent<AZListProps> = ({
  layout= 'default',
  data
}) => {
  const azListLayout: {[Key in AZListLayoutTypes]: ReactElement} = {
      'default': <Default data={data} />
  }

  return azListLayout[layout]
}

const AZList: FunctionComponent<AZListProps> = (
  props
) => {
  return <div
    id='azlist'
    className={clsx(
      styles.azList
    )}>
    <Layout {...props} />
  </div>
}

export default AZList

export type {
  AZListProps,
  AZListDataType,
  AZListListType,
  AZListTabItemType
}

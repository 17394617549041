import type { FunctionComponent } from 'react'
import type { ScoreSetsProps } from '../default'

import clsx from 'clsx'

import Caption from '../../../../atoms/caption'
import Meta from '../../../../atoms/meta'
import Title, { TitleSize } from '../../../../atoms/title'
import styles from '../../score.module.scss'

const BannerSets: FunctionComponent<ScoreSetsProps> = (
  {
    title,
    date,
    sets,
    label,
    sport,
    darkMode
  }
) => {
  const setLabel = sport === 'basketball' ? 'q' : <>set&nbsp;</>

  return !sets
    ? <div className={styles.scoreWithoutSets}>
      {title && <Caption className={styles.title}>{title}</Caption>}
      {date && <Title darkMode={darkMode} className={styles.date} size={'Small'}>{date}</Title>}
      {label && <Caption className={styles.label}>{label}</Caption>}
    </div>
    : <div className={styles.scoreSets}>
      {label && <Caption className={styles.label}>{label}</Caption>}
      {sets?.map((set, index: number) => <span className={clsx(
          styles.set,
          set.label === 'score' && styles.darker,
          set.label === 'einde' && styles.darker,
          set.label === 'fouten' && styles.fouls,
          set.label !== 'fouten' && set.label !== 'score' && index === sets?.length-1 && styles.setStyle,
          sets[index+1]?.label === 'fouten' && styles.setStyle,
          sets[index+1]?.label === 'score' && styles.setStyle,
        )} key={index}>
        <Title darkMode={darkMode} desktopSize={TitleSize.Small} className={styles.setNumber}>
          {set?.home}
          {set?.tiebreak && <sup className={styles.tiebreak}>
            {set?.tiebreak && set?.tiebreak?.home}
          </sup>}
        </Title>
        <Caption className={styles.number}>
          {set?.number ? <Meta>{setLabel}{set?.number}</Meta> : set?.label}
        </Caption>
        <Title darkMode={darkMode} desktopSize={TitleSize.Small} className={styles.setNumber}>
          {set?.away}
          {set?.tiebreak && <sup className={styles.tiebreak}>
            {set?.tiebreak && set?.tiebreak?.away}
          </sup>}
        </Title>
      </span>
      )}
    </div>
}

export default BannerSets

export type {
  ScoreSetsProps
}

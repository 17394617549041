import type { FunctionComponent } from 'react'
import type { StoryCardComponentProps } from '../../story-card'

import Icon from '../../../atoms/icon'
import Title from '../../../atoms/title'
import Pill from '../../../molecules/pill'
import styles from '../tab-section.module.scss'

interface BulletListProps {
  items: StoryCardComponentProps[]
  label: string
}
const BulletList: FunctionComponent<BulletListProps> = ({
  items,
  label
}) => {

  return (
    <ul className={styles.bulletList}>
      {items.map((item, index: number) => {
        const { title, tags = [], link } = item.componentProps

        return <li key={index}>
          <a className={styles.link} href={link}>
            <div className={styles.time}>
              <Pill iconBefore={'clock'} label={tags[0]?.text}/>
            </div>
            <div className={styles.linkTitle}>
              {label === 'videos' &&
                <Icon name={'video'} className={styles.icon}/>
              }
              <Title>
                {title}
              </Title>
            </div>
          </a>
        </li>
      })}
    </ul>
  )
}

export default BulletList

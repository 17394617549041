import { interactieApiOrigin } from '@sporza/config'
import { waitForGlobalAsync } from '@sporza/utils/objects'

import { logger as parentLogger } from '../logger'

type Vote = {
  slug: string,
  votingId?: string,
  selection?: any[] | undefined,
  votings?: any[] | undefined,
  motivation?: string
}

const logger = parentLogger.child({
  module: 'interaction'
})

class InteractionService {
  protected readonly baseUrl: string
  protected accessToken: string | undefined
  protected marketingId: string | undefined
  protected userEmail: string | undefined

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  public setAccessToken = () => {
    window['VRT']?.['SSOController']?.getAccessToken(
      (accessToken: string) => {
        this.accessToken = accessToken
      }
    )
  }

  private getAccessToken = async (): Promise<string> =>
    new Promise((resolve, reject) => {
      return window['VRT']?.['SSOController']?.getAccessToken((result: unknown) => {
        if (typeof result === 'string') {
          return resolve(result)
        }

        reject(result)
      })
    })

  private setMarketingId = () => {
    this.marketingId = window['VRT']?.['SSOController']?.getMarketingId()
  }

  private setEmail = () => {
    this.userEmail = window['VRT']?.['SSOController']?.getUserData()?.email || ''
  }

  public createVote = async (vote?: Vote): Promise<number> => {
    if (!vote) {
      logger.error('Voting - vote is missing')

      return 400
    }

    try {
      await waitForGlobalAsync('VRT.SSOController.getAccessToken')

      this.setAccessToken()
      this.setMarketingId()
      this.setEmail()

      const response = await fetch(`${this.baseUrl}/vote/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await this.getAccessToken()}`
        },
        body: JSON.stringify({
          ...vote,
          user: {
            marketingId: this.marketingId,
            email: this.userEmail
          }
        })
      })

      if (!response.ok) {
        logger.warn('Voting API - An error occurred while fetching:', response.statusText)
      }

      return response.status
    } catch (error) {
      logger.error('Voting API - An error occurred while fetching:', error)

      return 500
    }
  }

  public getVote = async (slug?: string): any => {
    if (!slug) {
      logger.error('Voting Results - slug is missing')

      return
    }

    try {
      await waitForGlobalAsync('VRT.SSOController.getAccessToken')

      this.setAccessToken()
      this.setMarketingId()
      this.setEmail()

      const response = await fetch(`${this.baseUrl}/vote/${slug}/${this.marketingId}`, {
        method: 'GET',
        headers: {
          'Origin': interactieApiOrigin,
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await this.getAccessToken()}`
        }
      })

      return await response.json()
    } catch (error) {
      logger.error('Voting API - An error occurred while fetching results:', error)

      return
    }
  }
}

export {
  InteractionService
}

export type {
  Vote
}

import type { FunctionComponent } from 'react'
import type { RawProps } from './layouts/raw'
import type { DefaultProps } from './layouts/table'
import type { UseApiProps } from '@sporza/hooks/use-api'

import { useApi } from '@sporza/hooks'

import Raw from './layouts/raw'
import TableDefault from './layouts/table'

export enum TableLayoutTypes {
  Default = 'Default',
  Raw = 'Raw',
  Scoreboard = 'Scoreboard'
}

interface TableComponentProps {
  componentProps: TableProps
}

interface TableProps extends DefaultProps, RawProps, UseApiProps {
  data: any
  layout?: TableLayoutTypes
}

const tableLayout: any = {
  [TableLayoutTypes.Default]: TableDefault,
  [TableLayoutTypes.Scoreboard]: TableDefault,
  [TableLayoutTypes.Raw]: Raw
}

const Table: FunctionComponent<TableProps> = (props) => {
  const Layout = tableLayout[props.layout || TableLayoutTypes.Default] || tableLayout[TableLayoutTypes.Default]

  const { data } = useApi(props, {
    keyPrefix: 'table'
  })

  const shouldRender = (props.data?.length > 0 && props.columns?.length > 0) || props.rawData || props.data?.rawData

  return shouldRender && <Layout {...(data || props)}/>
}

export default Table

export type {
  TableProps,
  TableComponentProps
}


import type { FunctionComponent } from 'react'

import styles from '../layouts/table.module.scss'

interface CellWithColorProps {
		colour: string,
		name?: string,
		href?: string
}
const CellWithColor: FunctionComponent<CellWithColorProps> = ({ colour, name, href }) => {
		const ElementTag = href ? 'a' : 'div'

		return (
				<ElementTag href={href}>
						<span className={styles.color} style={{ backgroundColor: colour || 'transparent' }}></span>
						{name}
				</ElementTag>
		)
}
export default CellWithColor

import type { ClassValue } from 'clsx'
import type {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  Ref
} from 'react'
import type { Swiper as SwiperClass } from 'swiper/types'

import 'swiper/css'
import 'swiper/css/pagination'

import clsx from 'clsx'
import { Children } from 'react'
import {
  A11y,
  Pagination
} from 'swiper/modules'
import {
  Swiper,
  SwiperSlide
} from 'swiper/react'

import { Breakpoints } from '../../design-tokens/breakpoints'

import styles from './carousel.module.scss'

interface CarouselProps extends PropsWithChildren {
  spaceBetween?: number,
  slidesPerView?: number,
  slidesPerGroup?: number,
  slidesOffsetBefore?: number,
  slidesOffsetAfter?: number,
  slidesPerViewMedium?: number,
  slidesPerGroupMedium?: number,
  slidesOffsetBeforeMedium?: number,
  slidesOffsetAfterMedium?: number,
  onSwiper?: (swiper: SwiperClass) => void
  onSlideChange?: (swiper: SwiperClass) => void
  enablePagination?: boolean
  className?: ClassValue
  innerRef?: Ref<HTMLDivElement>
}

const Carousel: FunctionComponent<CarouselProps> = (
  {
    spaceBetween = 8,
    slidesPerView = 1.05,
    slidesPerGroup = 1.05,
    slidesOffsetBefore = 0,
    slidesOffsetAfter = 0,
    slidesPerViewMedium = 3,
    slidesPerGroupMedium = 3,
    slidesOffsetBeforeMedium = 0,
    slidesOffsetAfterMedium = 0,
    onSwiper,
    onSlideChange,
    enablePagination = true,
    className,
    innerRef,
    children
  }
) => {
  return <div
    className={clsx(
      styles.carousel,
      className
    )}
    ref={innerRef}
  >
    <Swiper
      modules={[
        A11y,
        Pagination,
      ]}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      slidesOffsetBefore={slidesOffsetBefore}
      slidesOffsetAfter={slidesOffsetAfter}
      breakpoints={{
        [Breakpoints.medium]: {
          slidesPerView: slidesPerViewMedium,
          spaceBetween: 12,
          slidesPerGroup: slidesPerGroupMedium,
          slidesOffsetBefore: slidesOffsetBeforeMedium,
          slidesOffsetAfter: slidesOffsetAfterMedium
        }
      }}
      cssMode={!slidesOffsetBefore}
      pagination={{
        clickable: true,
        enabled: enablePagination
      }}
      onSwiper={(swiper) => {
        onSwiper && onSwiper(swiper)
      }}
      onSlideChange={(swiper) => {
        onSlideChange && onSlideChange(swiper)
      }}
    >
      {
        Children
          .map(
            children,
            (child, index) =>
              <SwiperSlide key={(child as ReactElement)?.key || index}>
                {child}
              </SwiperSlide>
          )
      }
    </Swiper>
  </div>
}

export {
  Carousel
}

export type {
  CarouselProps
}

import type { FunctionComponent, PropsWithChildren, ReactElement } from 'react'

import { bffBaseUrl } from '@sporza/config'
import clsx from 'clsx'

import { ButtonProps } from '../../molecules/button'
import { LinkListComponentProps } from '../link-list'
import { SectionComponentProps } from '../section'

import Default from './layout'
import styles from './search.module.scss'

type SearchLayoutTypes =
  'default'

type SearchDataType = {
  filters?: ButtonProps[],
  mostSearched?: LinkListComponentProps,
  themes?: LinkListComponentProps,
  articles?: SectionComponentProps,
  videos?: SectionComponentProps,
  podcasts?: SectionComponentProps,
  players?: LinkListComponentProps,
  teams?: LinkListComponentProps,
  suggestions?: ButtonProps[],
  didYouMean?: ButtonProps[],
  pagination?: any
}

interface SearchProps extends PropsWithChildren {
  query?: string,
  filter?: string,
  data: SearchDataType,
  layout?: SearchLayoutTypes,
  sportApiUrl: string,
  minCharacters?: number,
  key?: string
}

const Layout: FunctionComponent<SearchProps> = ({
  query,
  filter,
  data,
  sportApiUrl= `${bffBaseUrl}/content/search`,
  layout= 'default'
}) => {
  const searchLayout: {[Key in SearchLayoutTypes]: ReactElement} = {
      'default': <Default query={query}
                          filter={filter}
                          data={data}
                          sportApiUrl={sportApiUrl} />
  }

  return searchLayout[layout]
}

const Search: FunctionComponent<SearchProps> = (
  props
) => {
  return <div
    className={clsx(
        styles.search
    )}>
    <Layout {...props} />
  </div>
}

export default Search

export type {
  SearchProps,
  SearchDataType
}

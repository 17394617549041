import type { FunctionComponent } from 'react'
import type { ScoreboardComponentProps } from '../../scoreboard'

import clsx from 'clsx'
import { useEffect, useState } from 'react'

import { Breakpoints } from '../../../design-tokens/breakpoints'
import Button from '../../../molecules/button'
import { ScoreboardLayoutTypes } from '../../scoreboard'
import Scoreboard from '../../scoreboard'
import styles from '../live-dashboard.module.scss'

const Matches: FunctionComponent<any> = ({ sport, maxItems, context }) => {
  const [extended, setExtended] = useState<boolean>(false)
  const [limit, setLimit] = useState<number>(maxItems)
  const [screenWidth, setScreenWidth] = useState<number>(0)

  const extendButtonTitle =
    extended
      ? 'minder wedstrijden tonen'
      : `alle wedstrijden tonen (${sport?.items?.length - limit})`

  const handleResize = () => {
    setScreenWidth(window.innerWidth)
  }

  useEffect(() => {
    setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleResize, false)
  }, [])

  useEffect(() => {
    if (screenWidth >= Breakpoints.xlarge) {
      setLimit(maxItems)
    } else if (screenWidth >= Breakpoints.medium) {
      setLimit(3)
    } else {
      setLimit(2)
    }
  }, [screenWidth])

  const itemsToShow = extended ? sport.items.length : limit
  let competitionName: any = ''

  return <>
    <div className={clsx(styles.matches, extended && styles.extended)}>
      {sport.items
        .slice(0, itemsToShow)
        .map((item: ScoreboardComponentProps, index: number, array: any) => {
          const isLastItem = item.componentProps.competitionName !== array[index + 1]?.competitionName
          const showHeader = competitionName !== item.componentProps.competitionName
          competitionName = item.componentProps.competitionName
          return <Scoreboard
            key={index + JSON.stringify(item.componentProps)}
            {...item.componentProps}
            showHeader={showHeader}
            isLastItem={isLastItem}
            layout={ScoreboardLayoutTypes.Compact}
            context={context}
          />
        })
      }
    </div>
    {sport.items.length > limit &&
      <div className={styles.expandButton}>
        <Button
          iconAfter={extended ? 'show-less' : 'show-more'}
          onClick={() => setExtended(!extended)}
        >
          {extendButtonTitle}
        </Button>
      </div>
    }
  </>
}

export default Matches

import type { VotingProps } from '../../voting'

import { useAuth } from '@sporza/hooks'
import clsx from 'clsx'
import { FunctionComponent, useEffect } from 'react'
import { useState } from 'react'

import { VotingPhase } from '../../config'

import styles from './iedereen-bondscoach.module.scss'
import { IedereenBondscoachCountdown, IedereenBondscoachResults, IedereenBondscoachVoting } from './layouts'

interface IedereenBondscoachProps extends VotingProps {
  formations?: string[]
  votingId?: string
}

const phaseComponents: Record<VotingPhase, any> = {
  [VotingPhase.countdown]: IedereenBondscoachCountdown,
  [VotingPhase.voting]: IedereenBondscoachVoting,
  [VotingPhase.results]: IedereenBondscoachResults
}

const IedereenBondscoach: FunctionComponent<IedereenBondscoachProps> = (
  {
    phase = VotingPhase.countdown,
    ...props
  }
) => {
  const [phaseSt, setPhaseSt] = useState<VotingPhase>(phase as VotingPhase)
  const [vote, setVote] = useState()
  const [initPlayers, setInitPlayers] = useState<any[]>([])
  const Phase = phaseComponents[phaseSt]

  const { isLoggedIn } = useAuth()

  useEffect(() => {
    if (!isLoggedIn) {
      setPhaseSt(VotingPhase.countdown)
    }
  }, [isLoggedIn])

  const handlePhaseChange = (newPhase: VotingPhase, vote: never, defaultPlayers: any[]) => {
    setPhaseSt(newPhase)

    if (vote) {
      setVote(vote)
    }

    if (defaultPlayers) {
      setInitPlayers(defaultPlayers)
    }
  }

  return <Phase
    {...props}
    phase={phase}
    handlePhaseChange={handlePhaseChange}
    vote={vote}
    defaultPlayers={initPlayers}
    className={clsx(
      props.className,
      styles.iedereenBondscoach,
    )}
  />
}

export {
  IedereenBondscoach
}

export type {
  IedereenBondscoachProps
}


import type { FunctionComponent } from 'react'
import type { Icons } from '../../../../design-tokens/iconography/icons'
import type { EventSetProps } from '../../../../molecules/field-timeline/event-set'
import type { RulerItemProps } from '../../../../molecules/field-timeline/ruler'
import type { IntermediatesProps } from '../../../../molecules/intermediates/intermediates'
import type { ScoreSetsProps } from '../../../../molecules/score/layout'

import clsx from 'clsx'

import Caption from '../../../../atoms/caption'
import Icon from '../../../../atoms/icon'
import Heading from '../../../../molecules/heading'
import Score, { SportTypes, StatusTypes } from '../../../../molecules/score'
import { AccordionProps } from '../../../accordion'
import { ScoreboardLayoutTypes } from '../../index'
import styles from '../../scoreboard.module.scss'

interface ScoreboardRoundsProps extends ScoreSetsProps {
  competition?: string
  competitionName?: string
  darkMode?: boolean
  date?: string
  eventSets?: EventSetProps[]
  groups?: AccordionProps[]
  intermediates?: IntermediatesProps
  isLastItem?: boolean
  label?: string
  layout?: ScoreboardLayoutTypes
  location?: string // location has icon
  rounds?: number
  roundsToGo?: number
  ruler?: RulerItemProps[]
  showHeader?: boolean
  showLinks?: boolean
  sport: SportTypes | string
  startLabel?: string
  status?: StatusTypes | string
  temperature?: number
  title?: string
  type?: string
  typeIcon?: string | Icons
  weatherIcon?: string
  winner?: { name: string, shortName?: string, url?: string }
  channels?: any
}

const DefaultRoundsBoard: FunctionComponent<ScoreboardRoundsProps> = (
  {
    competitionName,
    competition,
    status,
    winner,
    roundsToGo,
    rounds,
    label,
    startLabel,
    location,
    sport,
    showLinks,
    showHeader,
    layout,
    isLastItem = false,
    type,
    typeIcon,
    weatherIcon,
    temperature
  }
) => {
  const Tag = showLinks && winner?.url ? 'a' : 'div'

  return <>
    {showHeader && competitionName && <Heading className={styles.competitionHeading} variant={'secondary'} align={layout === 'compact' ? 'Left' : 'Center'}>{competitionName}</Heading>}
    <div className={clsx(
      styles.scoreboardContent,
      isLastItem && styles.lastItem
    )}>
      <div className={styles.info}>
        <div className={styles.list}>
          {competition && <Caption className={styles.listItem}>{competition}</Caption>}
          {type && <Caption className={styles.listItem}>{typeIcon && <Icon name={typeIcon} />}{type}</Caption>}
          {temperature && <Caption className={styles.listItem}>{weatherIcon && <Icon name={weatherIcon} />}{temperature} &deg;C</Caption>}
          {rounds && <Caption className={styles.listItem}><Icon name={sport === 'cycling' ? 'cycling-rounds' : 'formula1-laps'} />{rounds} rondes</Caption>}
        </div>
        {startLabel && <Caption>{startLabel}</Caption>}
        {location && <Caption><Icon name={'location'} />{location}</Caption>}
        {winner?.name && <Tag className={styles.winner} href={showLinks ? winner.url : undefined}><Caption weight={'Bold'}><Icon name={'cycling-hotseat'} />{winner.name}</Caption></Tag>}
      </div>
      <Score layout={'default'} sport={sport} roundsToGo={roundsToGo} status={status} label={label} />
    </div>
  </>
}

export default DefaultRoundsBoard

export type {
  ScoreboardRoundsProps
}

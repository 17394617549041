import type { FunctionComponent } from 'react'
import type { F1LineupItemProps } from './f1-lineup-item'

import clsx from 'clsx'

import Title, { TitleElement,TitleSize } from '../../../atoms/title'
import styles from '../f1-lineup.module.scss'

import { F1LineupItem } from './f1-lineup-item'

interface F1LineupGroupProps {
  label?: string,
  labelDescription?: any,
  items?: F1LineupItemProps[]
}

const F1LineupGroup: FunctionComponent<F1LineupGroupProps> = (
  {
    label,
    labelDescription,
    items
  }
) => {
  const isStartGroup = label === 'START'

  return <div className={clsx(
    styles.f1LineupGroup,
    isStartGroup && styles.startGroup
  )}>
    {
      label
      && !isStartGroup
      && <div className={styles.f1LineupGroupLabel}>
        <Title
          className={styles.f1LineupGroupLabelInner}
          size={TitleSize.Small}
          ariaLabel={labelDescription}
          tag={TitleElement.SPAN}
        >
          {label}
        </Title>
      </div>
    }
    {
      items?.length
        ? items?.map((item: any) =>
          <F1LineupItem
            key={item.position}
            {...item}
            isMute={!isStartGroup}
          />
        )
        : false
    }
  </div>
}

export {
  F1LineupGroup
}

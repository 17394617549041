import type { FunctionComponent } from 'react'
import type { ImageCardProps } from '../../index'

import clsx from 'clsx'

import Caption from '../../../../atoms/caption'
import Title, { TitleSize } from '../../../../atoms/title'
import Button, { ButtonVariant } from '../../../../molecules/button'
import Image from '../../../../molecules/image'
import Card from '../../../card'
import styles from '../../image-card.module.scss'

const ImageCardDefault: FunctionComponent<ImageCardProps> = (
  {
    title,
    img,
    subTitle,
    link,
    className
  }
) => {
  return (img || title || subTitle) && <Card
    link={link}
    className={clsx(
      styles.imageCard,
      className
    )}
  >
    {img && <Image src={img} noCrop/>}

    {
      title || subTitle
        ? <Button
          className={styles.button}
          variant={ButtonVariant.list}
        >
          {subTitle && <Caption className={styles.subTitle}>{subTitle}</Caption>}
          {
            title && <Title
              className={styles.title}
              size={TitleSize.Small}
            >{title}</Title>
          }
        </Button>
        : null
    }
  </Card>
}

export {
  ImageCardDefault
}

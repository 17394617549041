import type { FunctionComponent, PropsWithChildren } from 'react'

import Title, { TitleElement, TitleSize } from '../../atoms/title'
import Section, { SectionComponentProps } from '../section/section'

import styles from './medal-summary.module.scss'

interface MedalSummaryComponentProps {
  componentProps: MedalSummaryProps
}

interface MedalSummaryProps extends PropsWithChildren {
  title?: string
  section?: SectionComponentProps
  tableSection?: SectionComponentProps
}

const MedalSummary: FunctionComponent<MedalSummaryProps> = (
  {
    title,
    section,
    tableSection
  }
) => {
  return <div className={styles.medalSummary}>
    {title && <Title size={TitleSize.Large} className={styles.title} tag={TitleElement.H2}>{title}</Title>}
    <div className={styles.content}>
      {section && <Section {...section.componentProps} className={styles.section} />}
      {tableSection && <Section {...tableSection.componentProps} className={styles.tableSection} />}
    </div>
  </div>
}

export default MedalSummary

export type {
  MedalSummaryProps,
  MedalSummaryComponentProps
}

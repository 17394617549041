import type { FunctionComponent, ReactElement } from 'react'

import DataListRing from './layout/ring'
import DataListDefault from './layout'

enum DataListLayout {
  LabelTop = 'LabelTop',
  Border = 'Border',
  Ring = 'Ring'
}

enum DataListSize {
  Medium = 'medium',
  Large = 'large'
}

interface DataListComponentProps {
  componentType: string
  componentProps: DataListProps
}

interface DataListProps {
  items: {
    value: string,
    subValue?: string,
    title?: string,
    icon?: string
    image?: string
    number?: string
  }[],
  size?: DataListSize
  layout?: DataListLayout
  shadow?: boolean
  className?: string
}

const DataList: FunctionComponent<DataListProps> = (props) => {
  const layout = props.layout || DataListLayout.Border

  const dataListLayout: {[Key in DataListLayout as string]: ReactElement} = {
    [DataListLayout.LabelTop]: <DataListDefault {...props} />,
    [DataListLayout.Border]: <DataListDefault {...props} />,
    [DataListLayout.Ring]: <DataListRing {...props} />
  }

  return dataListLayout[layout]
}

export default DataList

export {
  DataListLayout,
  DataListSize
}

export type {
  DataListProps,
  DataListComponentProps
}

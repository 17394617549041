import type { FunctionComponent } from 'react'
import type { TimelineProps } from '../../timeline'

import { useApi } from '@sporza/hooks'
import clsx from 'clsx'
import { formatDistance } from 'date-fns'
import nlLocale from 'date-fns/locale/nl-BE'
import { useEffect, useRef, useState } from 'react'
import { Gallery } from 'react-photoswipe-gallery'
import SwiperCore from 'swiper'

import Title, { TitleDirection, TitleElement, TitleSize } from '../../../../atoms/title'
import Button, { ButtonVariant } from '../../../../molecules/button'
import { Carousel } from '../../../../molecules/carousel'
import { Notification, NotificationType } from '../../../../molecules/notification'
import List, { ListAlignment } from '../../../list'
import { onBeforeOpen, onOpen, options } from '../../../mastermedia'
import { NavigationIcons } from '../../../section/layouts/slider'
import { DripLoader } from '../../components'
import DynamicEvent from '../../components/dynamic-event'
import { TimelineMode } from '../../config'
import { TimelineType } from '../../timeline'
import styles from '../../timeline.module.scss'

const TimelineTeaserComponent: FunctionComponent<TimelineProps> = (props) => {
  const {
    author = false,
    link,
    linkText,
    className
  } = props
  const { data } = useApi(props)

  const { timeline = {} } = data || props
  const {
    type,
    items = [],
    autoLoad = false
  } = timeline

  const [subtitle, setSubtitle] = useState<string>()
  const ref = useRef(null)
  const hasItems = items.length > 0

  const [controlledSwiper, setControlledSwiper] = useState<SwiperCore>()
  const [, setSignal] = useState({})
  const forceRerender = () => setSignal({})

  useEffect(() => {
    if (controlledSwiper) {
      forceRerender()
      controlledSwiper.on('slideChange', forceRerender)
      return () => {
        controlledSwiper.off('slideChange', forceRerender)
      }
    }
  }, [controlledSwiper])

  useEffect(() => {
    const latestTimestamp = items.find((item: any) => item.timestamp)?.timestamp

    if (latestTimestamp) {
      setSubtitle(
        'laatste update: ' +
        formatDistance(
          new Date(latestTimestamp),
          Date.now(),
          {
            addSuffix: true,
            locale: nlLocale
          }
        )
      )
    }
  }, [JSON.stringify(items)])

  return <>
    <div
      key="timeline"
      className={clsx(
        // styles.timeline,
        // 'sw-timeline'
        styles.timelineTeaser,
        className,
      )}
    >
      <div className={styles.timelineHeader}>
        {
          hasItems
          && <div className={styles.timelineTitle}>
            <DripLoader />
            <Title
              size={TitleSize.Large}
              tag={TitleElement.H2}
              subTitle={subtitle}
              direction={TitleDirection.Column}
            >Liveblog</Title>
          </div>
        }

        {
          items?.length > 3
            ? <div className={styles.actionBar}>
              <Button
                variant={ButtonVariant.tertiary}
                iconBefore={NavigationIcons.Left}
                onClick={() => controlledSwiper?.slidePrev()}
                tabIndex={-1}
                className={clsx({
                  [styles.carouselButtonDisabled]: controlledSwiper?.isBeginning
                })}
              />
              <Button
                variant={ButtonVariant.tertiary}
                iconBefore={NavigationIcons.Right}
                onClick={() => controlledSwiper?.slideNext()}
                tabIndex={-1}
                className={clsx({
                  [styles.carouselButtonDisabled]: controlledSwiper?.isEnd
                })}
              />
            </div>
            : null
        }
      </div>

      {
        hasItems
          ? <Gallery
            withCaption
            onBeforeOpen={onBeforeOpen}
            onOpen={onOpen}
            options={options}
          >
            <Carousel
              onSwiper={setControlledSwiper}
              enablePagination={false}
              innerRef={ref}
              // className={styles.events}
            >
              {
                items.map((item: any) => {
                  const key = JSON.stringify(item)

                  return <DynamicEvent
                    key={key}
                    {...item}
                    timelineType={type || TimelineType.Default}
                    autoLoad={autoLoad}
                    link={link}
                    mode={TimelineMode.Teaser}
                  />
                })
              }
            </Carousel>
          </Gallery>
          : author
            ? <Notification
              type={NotificationType.Info}
              label="Timeline"
              text="Timeline does not have any events yet"
            />
            : false
      }

      {
        hasItems && link && linkText
          ? <List
            align={ListAlignment.Center}
          >
            <Button
              href={link}
              iconAfter="chevron-right"
            >{linkText}</Button>
          </List>
          : null
      }
    </div>
  </>
}

export {
  TimelineTeaserComponent
}

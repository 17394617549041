import type { FunctionComponent } from 'react'
import type { PlayernameProps } from '../../../../molecules/playername'
import type { ScoreboardSetsProps } from '../default'

import clsx from 'clsx'

import Playername from '../../../../molecules/playername'
import Score, { ScoreLayoutTypes } from '../../../../molecules/score'

import styles from './banner.module.scss'

const BannerSetsBoard: FunctionComponent<ScoreboardSetsProps> = (
  {
    status,
    title,
    label,
    home,
    away,
    sets,
    date,
    sport,
    showLinks,
    darkMode
  }
) => {
  const homePlayers = home as [PlayernameProps['player']]
  const awayPlayers = away as [PlayernameProps['player']]

  return <div className={clsx(
    styles.scoreboard,
    styles.sets,
    darkMode ? styles.darkMode : undefined
  )}>
    <div className={styles.setsPlayers}>
      <span className={styles.setsPlayer}>
        {homePlayers?.map((player, index) => <Playername darkMode={darkMode} size={'Small'} desktopSize={'XLarge'} key={`${index}-${player?.name}`} player={player} showLinks={showLinks} />)}
      </span>
      <span className={styles.setsPlayer}>
        {awayPlayers?.map((player, index) => <Playername darkMode={darkMode} size={'Small'} desktopSize={'XLarge'} key={`${index}-${player?.name}`} player={player} showLinks={showLinks} />)}
      </span>
    </div>
    <Score layout={ScoreLayoutTypes.Banner} date={date} title={title} sets={sets} sport={sport} status={status} label={label} darkMode={darkMode} />
  </div>
}

export default BannerSetsBoard

export type {
  ScoreboardSetsProps
}

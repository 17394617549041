import type { FunctionComponent, PropsWithChildren } from 'react'
import type { SportTypes } from '../../index'

import clsx from 'clsx'

import Caption from '../../../../atoms/caption'
import Meta from '../../../../atoms/meta'
import styles from '../../score.module.scss'

interface ScoreSetsProps extends PropsWithChildren {
  title?: string
  sport?: string | SportTypes
  sets?: [{
    number: number
    label?: string
    home: number
    away: number
    tiebreak?: {
      home: number
      away: number
    }
  }]
  label?: string
  date?: string
  darkMode?: boolean
}

const DefaultSets: FunctionComponent<ScoreSetsProps> = (
  {
    sets,
    label,
    sport
  }
) => {
  const setLabel = sport === 'basketball' ? 'q' : <>set&nbsp;</>

  return <>
    {label && <Caption className={styles.tennisLabel}>{label}</Caption>}
    {sets?.map((set, index: number) => <span className={clsx(
        styles.set,
        set.label === 'score' && styles.darker,
        set.label === 'einde' && styles.darker,
        set.label === 'fouten' && styles.fouls,
        set.label !== 'fouten' && set.label !== 'score' && index === sets?.length-1 && styles.setStyle,
        sets[index+1]?.label === 'fouten' && styles.setStyle,
        sets[index+1]?.label === 'score' && styles.setStyle,
      )} key={index}>
      <span>
        {set?.home}
        {set?.tiebreak && <sup className={styles.tiebreak}>
          {set?.tiebreak && set?.tiebreak?.home}
        </sup>}
      </span>
      <Meta className={styles.number}>
        {set?.number ? <><span className={styles.setLabel}>{setLabel}</span>{set?.number}</> : set?.label}
      </Meta>
      <span>
        {set?.away}
        {set?.tiebreak && <sup className={styles.tiebreak}>
          {set?.tiebreak && set?.tiebreak?.away}
        </sup>}
      </span>
    </span>
    )}
  </>
}

export default DefaultSets

export type {
  ScoreSetsProps
}

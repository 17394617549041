import type { ClassValue } from 'clsx'
import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import Icon from '../../atoms/icon'
import Title, { TitleSize } from '../../atoms/title'

import styles from './quote.module.scss'

interface QuoteComponentProps {
  componentProps: QuoteProps
}

interface QuoteProps {
  text: string
  person?: string
  href?: string
  className?: ClassValue
}

const Quote: FunctionComponent<QuoteProps> = (
  {
    text,
    person,
    href,
    className
  }
) => {
  return <div className={clsx(
    styles.quoteContainer,
    className
  )}>
    <div className={styles.content}>
      <Icon name={'quote'} className={styles.icon} />
      <div className={styles.quote}>
        <Title
          size={TitleSize.Large}
          link={href}
          tag={'h3'}
          text={text}
        />
      </div>
      {person && <Title
        size={TitleSize.XSmall}
        className={styles.person}
      >
        {person}
      </Title>}
    </div>
  </div>
}

export {
  Quote
}

export type {
  QuoteProps,
  QuoteComponentProps
}

import type { FunctionComponent, PropsWithChildren } from 'react'
import type { Icons } from '../../../design-tokens/iconography/icons'
import type { WielerManagerContainerProps } from './container'
import type { WielermanagerRankings } from '@sporza/services'

import { useAuth } from '@sporza/hooks'
import { PersonalisationService } from '@sporza/services'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

import Icon from '../../../atoms/icon'
import Paragraph from '../../../atoms/paragraph'
import Title, { TitleSize } from '../../../atoms/title'
import Button from '../../../molecules/button'
import { getTitleFromMatch } from '../helpers'
import { trackBannerClick } from '../wielermanager'

import commonStyles from './common.module.scss'
import WielerManagerContainer from './container'
import styles from './ranking.module.scss'

interface WielerManagerRankingProps extends WielerManagerContainerProps, PropsWithChildren {
  source: string
  stage: string
  year?: string
  apiBaseUrl: string
  title?: string
  data?: any
}

const getRankingDelta = (delta?: number) => {
  if (!delta)
    return

  let iconName, iconClassName, deltaString

  switch (true) {
    case delta > 0:
      iconName = 'arrow-up'
      iconClassName = styles.iconArrowUp
      deltaString = `+${delta}`
      break
    case delta < 0:
      iconName = 'arrow-down'
      iconClassName = styles.iconArrowDown
      deltaString = delta
      break
    case delta === 0:
      iconName = 'equals'
      break
    default:
      break
  }

  return <span className={styles.delta}>
    <Icon
      name={iconName as Icons}
      className={iconClassName}
    />
    {deltaString}
  </span>
}

const WielerManagerRanking: FunctionComponent<WielerManagerRankingProps> = (
  {
    widgetName,
    innerRef,
    title = 'Klassement',
    source = 'TOUR',
    year = new Date().getFullYear().toString(),
    stage,
    data = {},
    apiBaseUrl,
    cyclingManagerUrl,
    containerQueries,
    bannerContext,
    showSponsor,
  }
) => {
  const { match } = data
  const [rankings, setRankings] = useState<WielermanagerRankings>()
  const { marketingId, isLoggedIn } = useAuth()
  const subtitle = getTitleFromMatch(match, `Doe mee met de ${widgetName} ${year}!`)

  title = rankings
    ? title
    : isLoggedIn
      ? `${widgetName} ${year}`
      : 'Jouw ploeg ook aan de start?'

  useEffect(() => {
    if (marketingId) {
      const fetchPersonalisedRankings = async () => {
        const personalisationService = new PersonalisationService(apiBaseUrl)
        const personalisedRankings = await personalisationService.fetchWielermanagerRankings(
          source,
          year,
          stage,
          marketingId
        )

        setRankings(personalisedRankings)
      }

      fetchPersonalisedRankings()
        .catch(console.error)
    }
  }, [marketingId])

  const hasRankingData = rankings?.currentRank || rankings?.currentScore || rankings?.winners

  return <WielerManagerContainer
    widgetName={widgetName}
    innerRef={innerRef}
    containerQueries={containerQueries}
    ctaUrl={cyclingManagerUrl}
    source={source}
    showSponsor={showSponsor}
    year={year}
  >
    <div className={clsx(
      styles.container,
      !hasRankingData && commonStyles.centered
    )}>
      <Title
        className={commonStyles.title}
        size={TitleSize.Large}
      >{title}</Title>
      {
        subtitle
        && <Title
          className={commonStyles.subtitle}
          size={TitleSize.Small}
        >{subtitle}</Title>
      }
      {
        isLoggedIn
          ? hasRankingData
            ? <div className={styles.content}>
              {
                rankings?.currentRank
                  ? <div className={styles.contentContainer}>
                    <Title
                      className={clsx(commonStyles.title, styles.title)}
                      size={TitleSize.Small}
                    >
                      <Icon
                        name="competitie"
                        className={styles.iconCompetition}
                      /> jouw positie
                    </Title>
                    <div className={styles.rankScoreContainer}>
                      <span className={styles.rankScore}>nr. {rankings?.currentRank || '...'}</span>
                      {getRankingDelta(rankings?.rankDelta)}
                    </div>
                  </div>
                  : false
              }
              {
                rankings?.currentScore
                  ? <div className={styles.contentContainer}>
                    <Title
                      className={clsx(commonStyles.title, styles.title)}
                      size={TitleSize.Small}
                    >
                      jouw punten
                    </Title>
                    <div className={styles.rankScoreContainer}>
                      <span className={styles.rankScore}>{rankings?.currentScore || '...'}</span>
                      {getRankingDelta(rankings?.scoreDelta)}
                    </div>
                  </div>
                  : false
              }
              {
                rankings
                  ?.winners
                  ?.length
                  ? <div className={styles.contentContainer}>
                    <Title
                      className={clsx(commonStyles.title, styles.title)}
                      size={TitleSize.Small}
                    >
                      ploegenklassement
                    </Title>

                    <ol className={styles.list}>
                      {
                        rankings
                          ?.winners
                          .map(winner =>
                            <li key={winner} className={styles.listItem}>{winner}</li>
                          )
                      }
                    </ol>
                  </div>
                  : null
              }
            </div>
            : <>
              <Paragraph className={commonStyles.paragraph}>
                Stel nu je ploeg samen, voer transfers uit en word straks de koning van het peloton.
              </Paragraph>
              <Button
                iconAfter="chevron-right"
                href={`https://${widgetName}.sporza.be`}
                target="_blank"
                withBorder={true}
                onClick={() => {
                  trackBannerClick({
                    ...bannerContext,
                    targetPageUrl: `https://${widgetName}.sporza.be`,
                    targetPageName: '/',
                    targetPageId: widgetName + '-stel-je-ploeg-samen'
                  })
                  return true
                }}
              >Deelnemen</Button>
            </>
          : <>
            <Paragraph className={commonStyles.paragraph}>
              Meld je nu aan met je VRT-profiel, bekijk je resultaat en vergelijk hoe jouw vrienden het doen!
            </Paragraph>
            <Button
              iconAfter="chevron-right"
              href={`https://${widgetName}.sporza.be`}
              target="_blank"
              withBorder={true}
              onClick={() => {
                trackBannerClick({
                  ...bannerContext,
                  targetPageUrl: `https://${widgetName}.sporza.be`,
                  targetPageName: '/',
                  targetPageId: widgetName + '-stel-je-ploeg-samen'
                })
                return true
              }}
            >Aanmelden</Button>
          </>
      }
    </div>
  </WielerManagerContainer>
}

export default WielerManagerRanking

export type {
  WielerManagerRankingProps
}

import type { CSSProperties, FunctionComponent, PropsWithChildren } from 'react'

import clsx from 'clsx'

import styles from './grid.module.scss'

interface GridProps extends PropsWithChildren {
  className?: string
  style?: CSSProperties
  collapse?: boolean
  collapseX?: boolean
}

const Grid: FunctionComponent<GridProps> = (
  {
    children,
    style,
    className,
    collapse = false,
    collapseX = false
  }
) => {
  return <div
    style={style}
    className={clsx(
      styles.grid,
      collapse && styles.collapse,
      collapseX && styles.collapseX,
      className
    )}
  >
    {children}
  </div>
}

export default Grid

export type {
  GridProps
}

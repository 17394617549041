import { addContextToEvent } from '../utils'

import { getPageEventData } from './page-impression'

interface BannerClickArguments {
  targetPageUrl?: string
  targetPageName?: string
  targetPageId?: string
  bannerTitle: string
  componentName?: string
  componentId?: string
  bannerSubtitle?: string
  bannerType?: string
  bannerAdvertiser?: string
  componentPosition?: number
  componentCount?: number
  calculationId?: string
  featureKey?: string
  featureValue?: string
  featureSource?: string
  featureRuleId?: string
  featureExtra?: string
}

const bannerClickEvent = (
  {
    targetPageUrl,
    targetPageName,
    targetPageId,
    bannerTitle,
    componentName,
    componentId,
    bannerSubtitle,
    bannerType,
    bannerAdvertiser,
    componentPosition,
    componentCount,
    calculationId,
    featureKey,
    featureValue,
    featureSource,
    featureRuleId,
    featureExtra,
  }: BannerClickArguments
) => {
  if (typeof window === 'undefined') {
    console.warn('sporza eba - no banner click triggered, not running in browser')

    return
  }

  const trackUsingTemplate = window.VRT?.EBA?.EDDLHelper?.trackUsingTemplate
  const templates = window.VRT?.EBA?.defaultTemplates

  if (!trackUsingTemplate || !templates) {
    console.warn('sporza eba - could not find EBA utils to track banner click')

    return
  }

  try {
    const eventData = {
      // target
      '$tapu': targetPageUrl,
      '$tapn': targetPageName,
      '$tapi': targetPageId,

      '$bati': bannerTitle,

      // optional
      '$bana': componentName,
      '$baid': componentId,
      '$bast': bannerSubtitle,
      '$baty': bannerType,
      '$baad': bannerAdvertiser,
      '$bacp': componentPosition,
      '$bacc': componentCount,
      '$baci': calculationId,

      // feature
      '$feke': featureKey,
      '$feva': featureValue,
      '$fesr': featureSource,
      '$feri': featureRuleId,
      '$feex': featureExtra,
    }

    const pageEventData = getPageEventData()

    let bannerClick = { ...templates.banner_click_1_1_4 }
    bannerClick = addContextToEvent(bannerClick, 'context_target')
    bannerClick = addContextToEvent(bannerClick, 'context_page')

    trackUsingTemplate(
      bannerClick,
      {
        ...eventData,
        ...pageEventData
      }
    )
  } catch (e) {
    console.error('failed to send banner click event', e)
  }
}

export {
  bannerClickEvent
}

export type {
  BannerClickArguments
}

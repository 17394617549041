import type { FunctionComponent } from 'react'
import type { ImageComponentProps, ImageProps } from '../../molecules/image'

import { ComponentType } from '@sporza/services/component-mapper'
import { useRef } from 'react'
import { Gallery } from 'react-photoswipe-gallery'

import Image from '../../molecules/image'
import { onBeforeOpen, onOpen, options } from '../mastermedia'
import Mediaplayer, { MediaplayerComponentProps } from '../mediaplayer'

import {
  ImageCarouselSlider
} from './layouts'

enum ImageCarouselLayout {
  Default = 'default'
}

interface ImageCarouselProps {
  layout: ImageCarouselLayout,
  items?: ImageComponentProps[] | MediaplayerComponentProps[]
}

const ImageCarousel: FunctionComponent<ImageCarouselProps> = (
  {
    layout = ImageCarouselLayout.Default,
    items
  }
) => {
  let ElementTag

  switch (true) {
    case items && items.length <= 1:
      ElementTag = 'div'
      break
    case layout === ImageCarouselLayout.Default:
    default:
      ElementTag = ImageCarouselSlider
      break
  }

  const refs = useRef({})

  return <Gallery withCaption onBeforeOpen={onBeforeOpen} onOpen={onOpen} options={options}>
    <ElementTag>
      {
        items
          ?.map((item, index) => {
            switch (item.componentType) {
              case ComponentType.ResponsiveImage:
                return <Image key={item.componentName || index} {...(item.componentProps as ImageProps)}
                              priority={true}
                              boxed={item.componentProps?.noCrop}
                              columns={1}
                              addToGallery={true}/>
              case ComponentType.Mediaplayer:
                return <Mediaplayer innerRef={refs.current[index] ??= { current: null }} key={item.componentName || index} {...item.componentProps} />
              default:
                console.warn(`Component type ${item.componentType} is not supported in ImageCarousel`)
                return null
            }
          })
      }
    </ElementTag>
  </Gallery>
}

export type {
  ImageCarouselProps,
}

export {
  ImageCarousel,
  ImageCarouselLayout
}

import type { FunctionComponent } from 'react'
import type { ScoreboardDistanceProps } from '../default'

import clsx from 'clsx'

import Icon from '../../../../atoms/icon'
import Paragraph from '../../../../atoms/paragraph'
import Title, { TitleSize } from '../../../../atoms/title'
import FieldTimeline from '../../../../molecules/field-timeline/field-timeline'
import Intermediates from '../../../../molecules/intermediates/intermediates'
import Score, { ScoreLayoutTypes, StatusTypes } from '../../../../molecules/score'
import { Accordion, AccordionLayouts } from '../../../accordion'
import styles from '../../scoreboard.module.scss'

const DetailedDistanceBoard: FunctionComponent<ScoreboardDistanceProps> = (
  {
    competitionName,
    sport,
    status,
    type,
    typeIcon,
    weatherIcon,
    temperature,
    distance,
    distanceToGo,
    winner,
    label,
    stage,
    startLabel,
    endLabel,
    showLinks,
    isLastItem= false,
    eventSets,
    ruler,
    groups,
    intermediates
  }
) => {
  const Tag = showLinks && winner?.url ? 'a' : 'div'

  return <>
    <div className={clsx(
      styles.scoreboardContent,
      styles.detailed,
      isLastItem && styles.lastItem
    )}>
      <div className={styles.info}>
        <Title desktopSize={TitleSize.Medium} size={TitleSize.XSmall}>{competitionName}</Title>
        <div className={styles.list}>
          {stage && <Paragraph className={styles.listItem}>{stage}</Paragraph>}
          {type && <Paragraph className={styles.listItem}>{typeIcon && <Icon name={typeIcon} />}{type}</Paragraph>}
          {temperature && !stage && <Paragraph className={styles.listItem}>{weatherIcon && <Icon name={weatherIcon} />}{temperature} &deg;C</Paragraph>}
          {distance && <Paragraph className={styles.listItem}><Icon name={'cycling-distance'} />{distance} km</Paragraph>}
        </div>
        <Paragraph><Icon name="location" />{startLabel} - {endLabel}</Paragraph>
        {winner && <Tag className={styles.winner} href={showLinks ? winner.url : undefined}><Paragraph weight={'Bold'}><Icon name={'cycling-hotseat'} />{winner.name}</Paragraph></Tag>}
      </div>
      <Score layout={ScoreLayoutTypes.Detailed} sport={sport} distance={distance} distanceToGo={distanceToGo} status={status} label={label} typeIcon={typeIcon} />
    </div>
    {eventSets && <FieldTimeline status={status as StatusTypes} eventSets={eventSets} ruler={ruler} />}
    {groups && <Accordion items={groups} layout={AccordionLayouts.Groups}/>}
    {intermediates && <Intermediates {...intermediates} />}
  </>
}

export default DetailedDistanceBoard

export type {
  ScoreboardDistanceProps
}

import type { ClassValue } from 'clsx'
import type { FunctionComponent, PropsWithChildren } from 'react'
import type { Icons } from '../../design-tokens/iconography/icons'

import clsx from 'clsx'

import Icon from '../../atoms/icon'
import Meta from '../../atoms/meta'
import Image from '../image'
import { imageLayouts } from '../teamname'

import styles from './playername.module.scss'

enum PlayernameSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  XLarge = 'XLarge'
}

interface PlayernameProps extends PropsWithChildren {
  player: {
    name: string
    url?: string
    href?: string
    logoUrl?: string
    icon?: string
    rankingPosition?: number
    seed?: number
    suffix?: string
    initiative?: boolean
    winner?: boolean
    iconBefore?: Icons
    isAway?: boolean
  }
  size?: string | PlayernameSize,
  desktopSize?: string | PlayernameSize,
  showLinks?: boolean
  apiHost?: string
  target?: string
  darkMode?: boolean,
  className?: ClassValue
}

const Playername: FunctionComponent<PlayernameProps> = (
  {
    player,
    size = 'Medium',
    desktopSize,
    showLinks,
    apiHost,
    target,
    darkMode,
    className
  }
) => {
  const {
    name,
    url,
    href,
    logoUrl,
    rankingPosition,
    seed,
    suffix,
    initiative,
    winner,
    icon
  } = player || {}
  const Tag = (url || href) && showLinks ? 'a' : 'span'

  const extras: (string | JSX.Element)[] = []
  if (rankingPosition) extras.push(`${rankingPosition}`)
  if (seed) extras.push(<><Icon name={'tennis-seed'} className={styles.seed} />{seed}</>)
  if (suffix) extras.push(suffix)

  const extrasElement = () => extras.map((element, index) => {
    let front, back, comma = ', '
    if (index === 0){
      front = '('
    }
    if (index === extras.length-1){
      back = ')'
      comma = ''
    }
    return <Meta darkMode={darkMode} key={`${index}-${element}`}>{front}{element}{comma}{back}</Meta>
  })

  return <span className={clsx(
    styles.playername,
    size === 'Small' && styles.playernameSmall,
    size === 'Large' && styles.playernameLarge,
    size === 'XLarge' && styles.playernameXLarge,
    desktopSize === 'Small' && styles.playernameDesktopSmall,
    desktopSize === 'Large' && styles.playernameDesktopLarge,
    desktopSize === 'XLarge' && styles.playernameDesktopXLarge,
    winner && styles.winner,
    darkMode && styles.darkMode,
    className
  )}>
    {logoUrl && <span className={styles.flag}>
      <Image
        src={logoUrl}
        layout={imageLayouts['tennis']}
        noCrop
      />
    </span>}
    <Tag
      href={showLinks ? url || href : undefined}
      target={showLinks && target ? target : undefined}
      className={styles.name}
    >
      {name} {extrasElement()} {initiative && <Icon name={'tennis-initiative'} className={styles.initiative} />}{icon && <Icon name={icon} className={styles.icon} />}
    </Tag>
  </span>
}

export default Playername

export type {
  PlayernameProps
}

export {
  PlayernameSize
}

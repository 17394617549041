import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import Pill, { PillSize } from '../../../molecules/pill'

import styles from './custom.cell.module.scss'

interface CellNumberProps {
  number: string,
  column?: any,
  options?: Record<string, any>
  className?: string
}

const CellNumber: FunctionComponent<CellNumberProps> = (
  {
    number,
    options = {},
    className
  }
) => {
  const getNumber = () => {
    switch (true) {
      case options.html !== undefined:
        return <span dangerouslySetInnerHTML={{
          __html: number
        }} />
      case options.pill:
        return <Pill size={PillSize.Large}>{number}</Pill>
      default:
        return number
    }
  }

  return <div
    style={{ textAlign: options?.align ? options.align : undefined }}
    className={clsx(
      className,
      styles.number,
      options?.squircle && styles.squircle,
      options?.pill && styles.pill,
      options?.theme && styles[options.theme],
      options?.borderLeft && styles.borderLeft
    )}
  >
    {getNumber()}
  </div>
}

export default CellNumber

import { VideoErrorMessages } from '../index'

const randomErrorExclamation = () => {
  const exclamations = ['Oei', 'Ai', 'Uh-oh']

  return Math.random() < 0.05
    ? exclamations[Math.floor(Math.random() * exclamations.length)]
    : 'Oeps'
}

const getErrorCodeByMessage = (message: string) => {
  switch (message) {
    case 'CONTENT_AVAILABLE_ONLY_FOR_BE_RESIDENTS':
    case 'CONTENT_AVAILABLE_ONLY_FOR_BE_RESIDENTS_AND_EXPATS':
    case 'CONTENT_AVAILABLE_ONLY_IN_BE':
      return 104
    case 'CONTENT_IS_AGE_RESTRICTED':
      return 109
    default:
      return 9999
  }
}

const errorHandler = (setError: (error: any) => void) => (error: any) => {
  const { detail } = error
  const code = detail.code && detail.code > 0
    ? detail.code
    : getErrorCodeByMessage(detail.message)
  const message = detail.message && VideoErrorMessages[detail.message as keyof typeof VideoErrorMessages]
    ? VideoErrorMessages[detail.message as keyof typeof VideoErrorMessages]
    : 'Er is een onbekende fout opgetreden.'

  /*eslint no-fallthrough: 0*/
  switch (code) {
    case 4:
    case 5:
      // display no message, the player recovers itself
      break

    // do nothing for login errors, these are handled by the SSO widget eventhandlers
    // AGGREGATOR_ERROR_GEOBLOCKED
    case 104:
    // AGGREGATOR_ERROR_NOT_AUTHENTICATED
    case 105: // USER IS NOT LOGGED IN -> DISPLAY LOGIN FORM
    // AGGREGATOR_ERROR_NO_ROAMING
    case 107: // USER IS LOGGED IN -> FORWARD TO COMPLETE/VERIFY PROFILE
      break

    // VIDEO_NOT_FOUND
    case 102:
    default:
      setError({
        title: randomErrorExclamation(),
        message: message
      })
  }
}

export {
  randomErrorExclamation,
  getErrorCodeByMessage,
  errorHandler
}

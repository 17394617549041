import type { ClassValue } from 'clsx'
import type { FunctionComponent } from 'react'

import { ImageCardDefault } from './layout'

enum ImageCardLayout {
  Default = 'default'
}

interface ImageCardType {
  componentType: string
  componentProps: ImageCardProps
}

interface ImageCardProps {
  layout?: ImageCardLayout
  title?: string
  subTitle?: string
  img?: string
  link?: string
  className?: ClassValue
}

const layoutComponents = {
  [ImageCardLayout.Default]: ImageCardDefault
}

const ImageCard: FunctionComponent<ImageCardProps> = (
  props
) => {
  const Layout = layoutComponents[props.layout as keyof typeof layoutComponents] || layoutComponents[ImageCardLayout.Default]

  return <Layout {...props} />
}

export default ImageCard

export type {
  ImageCardType,
  ImageCardProps
}

export {
  ImageCard,
  ImageCardLayout
}

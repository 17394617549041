import type { MediaCardProps } from '../../media-card'

const findCard = (items?: any[], currentCard?: MediaCardProps | null, direction?: 'prev' | 'next') => {
  if (!items?.length || !currentCard) {
    return null
  }

  const currentIndex = items.findIndex((item, index) => {
    const itemId = `${item?.componentProps?.title}`
    return `${index}-${itemId}` === currentCard.id
  })

  if (currentIndex === -1) {
    return null
  }

  const newIndex = direction === 'prev' ? currentIndex - 1 : currentIndex + 1
  const newCard = items[newIndex]
  if (!newCard) {
    return null
  }

  const itemId = `${newIndex}-${newCard?.componentProps?.title}`
  return {
    ...newCard.componentProps,
    id: itemId
  }
}

export {
  findCard
}

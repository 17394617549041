import type { DataSourceArray, PhotoSwipeOptions } from 'photoswipe'
import type { FunctionComponent } from 'react'
import type { ImageComponentProps, ImageProps } from '../../molecules/image'

import { ComponentType } from '@sporza/services/component-mapper'
import PhotoSwipe from 'photoswipe'
import { useRef } from 'react'
import { Gallery } from 'react-photoswipe-gallery'

import Image2 from '../../molecules/image'
import Mediaplayer, { MediaplayerComponentProps, MediaplayerProps } from '../mediaplayer'

import {
  MasterMediaGrid,
  MasterMediaSlider
} from './layouts'

enum MasterMediaLayout {
  Default = 'default',
  Slider = 'slider',
}

interface MasterMediaProps {
  layout: MasterMediaLayout,
  items?: Array<ImageComponentProps | MediaplayerComponentProps>
}

// dynamic with/height for gallery images
const onBeforeOpen = (pswpInstance: PhotoSwipe) => {
  const dataSource = pswpInstance?.options?.dataSource

  pswpInstance.on('beforeOpen', () => {
    pswpInstance.options.bgOpacity = 1
    if (Array.isArray(dataSource)) {
      for (let idx = 0, len = dataSource.length; idx < len; idx++) {
        const item = dataSource[idx]
        const img = new Image()
        img.onload = () => {
          item.width = img.naturalWidth
          item.height = img.naturalHeight
          pswpInstance?.refreshSlideContent(idx)
        }
        img.src = item && item.src as string
      }
    }
  })
}

const onOpen = (pswpInstance: PhotoSwipe) => {
  const dataSource = pswpInstance.options.dataSource as DataSourceArray

  pswpInstance.on('change', () => {
    if (pswpInstance.bg)
      pswpInstance.bg.style.backgroundImage = `url(${dataSource[pswpInstance.currIndex].src})`
  })
}
const options: PhotoSwipeOptions = {
  bgOpacity: 1,
}

const MasterMedia: FunctionComponent<MasterMediaProps> = (
  {
    layout,
    items
  }
) => {
  const ElementTag = layout === MasterMediaLayout.Slider
    ? MasterMediaSlider
    : MasterMediaGrid

  const refs = useRef({})

  return <Gallery withCaption onBeforeOpen={onBeforeOpen} onOpen={onOpen} options={options}>
    <ElementTag>
      {
        items
          ?.map((item, index) => {
            switch (item.componentType) {
              case ComponentType.ResponsiveImage:
                return <Image2 key={item.componentName || index} {...(item.componentProps as ImageProps)}
                               priority={true}
                               columns={1}
                               addToGallery={true}/>
              case ComponentType.Mediaplayer:
                return <Mediaplayer innerRef={refs.current[index] ??= { current: null }} key={item.componentName || index} {...(item.componentProps as MediaplayerProps)} />
              default:
                console.warn(`Component type ${item.componentType} is not supported in MasterMedia`)
                return null
            }
          })
      }
    </ElementTag>
  </Gallery>
}

export type {
  MasterMediaProps,
}

export {
  MasterMedia,
  MasterMediaLayout,
  onBeforeOpen,
  onOpen,
  options
}

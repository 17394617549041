enum SectionType {
  Default = 'Default',
  MostTrending = 'MostTrending',
  ReadMore = 'ReadMore',
  VerticalVideoPlaylist = 'VerticalVideoPlaylist',
  Video = 'Video',
  Livestream = 'Livestream',
  Event = 'Event',
  RelatedArticles = 'RelatedArticles',
  Audio = 'Audio',
  Player = 'Player',
}

enum SectionLayout {
  Grid1Column = 'Grid1Column',
  Grid2Column = 'Grid2Column',
  Grid2ColumnSquare = 'Grid2ColumnSquare',
  Grid2ColumnOnMobileSlider = 'Grid2ColumnOnMobileSlider',
  Grid3Column = 'Grid3Column',
  Grid3ColumnScroll = 'Grid3ColumnScroll',
  Grid3ColumnSlider = 'Grid3ColumnSlider',
  Grid3ColumnOnMobileSlider = 'Grid3ColumnOnMobileSlider',
  Grid4Column = 'Grid4Column',
  Grid4ColumnSlider = 'Grid4ColumnSlider',
  Grid4ColumnSliderBottom = 'Grid4ColumnSliderBottom',
  Grid4ColumnSliderOnMobile4Rows = 'Grid4ColumnSliderOnMobile4Rows',
  Grid5Column = 'Grid5Column',
  Grid5ColumnSquare = 'Grid5ColumnSquare',
  Grid5ColumnSlider = 'Grid5ColumnSlider',
  Grid6ColumnSlider = 'Grid6ColumnSlider',
  Grid6ColumnSquare = 'Grid6ColumnSquare',
  Grid3x3Column = 'Grid3x3Column',
  Grid3x3ColumnAds = 'Grid3x3ColumnAds',
  Grid3x4Column = 'Grid3x4Column',
  Grid2x4ColumnMatches = 'Grid2x4ColumnMatches',
  Grid1ColumnOverflow = 'Grid1ColumnOverflow',
  Grid11ColumnSlider = 'Grid11ColumnSlider',
}

enum SectionBackground {
  Default = 'Default',
  Dark = 'Dark',
  Light = 'Light',
  Grey = 'Grey',
  Gradient = 'Gradient'
}

enum SectionSpacing {
  Default = 'Default',
  Narrow = 'Narrow'
}

export {
  SectionBackground,
  SectionLayout,
  SectionSpacing,
  SectionType
}

import type { FunctionComponent } from 'react'
import type { StoryCardComponentProps, StoryCardProps } from '../../story-card'

import { useEffect, useState } from 'react'

import Image from '../../../molecules/image'

import styles from './images.module.scss'

interface SpotlightImagesProps {
  items: StoryCardComponentProps[]
  activeIndex: number
}

const SpotlightImages: FunctionComponent<SpotlightImagesProps> = (
  {
    items,
    activeIndex = 0
  }
) => {
  const [active, setActive] = useState<number>(0)

  useEffect(()=> {
    setActive(activeIndex)
  }, [activeIndex])

  return <div className={styles.wrapper} data-active={`${active}`} aria-hidden={true}>
    <div className={styles.images}>
      {items.map((item: any, index: number) =>
        <a key={`${index}-${item.componentProps.image}`} href={item.componentProps.link}>
          <Image
            className={styles.image}
            priority={index === 0}
            src={item.componentProps.image}
            alt={item.componentProps.imageAlt}
            focalPoint={item.componentProps.imageFocalPoint}
            columns={1}
          />
        </a>
      )}
    </div>
  </div>
}

export default SpotlightImages

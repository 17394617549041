import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import styles from './raw.module.scss'

interface RawProps {
  data?: any
  rawData?: any
  className?: string
  darkMode?: boolean
  useOutset?: boolean
}

const Raw: FunctionComponent<RawProps> = (
  {
    data = {}, // TODO: check why we sometimes get data instead of rawData
    rawData,
    darkMode,
    useOutset = false
  }
) => {
  return <div className={clsx(
    styles.wrapper,
    darkMode && styles.dark,
    useOutset && styles.outset,
  )} dangerouslySetInnerHTML={{ __html: rawData || data.rawData }}/>
}

export default Raw

export type {
  RawProps
}

import type { FunctionComponent } from 'react'
import type { TeamnameProps } from '../../../../molecules/teamname'
import type { ScoreboardScoreProps } from '../default'

import clsx from 'clsx'

import Score, { ScoreLayoutTypes } from '../../../../molecules/score'
import Teamname from '../../../../molecules/teamname'
import { ScoreboardLayoutTypes } from '../../index'

import styles from './banner.module.scss'

const BannerScoreBoard: FunctionComponent<ScoreboardScoreProps> = (
  {
    sport,
    status,
    label,
    title,
    date,
    home,
    away,
    score,
    darkMode,
    showLogos,
    showLinks,
    designSystemBaseUrl
  }
) => {
  return <div className={clsx(
    styles.scoreboard,
    darkMode ? styles.darkMode : undefined,
  )}>
    <Teamname
      size={'XXLarge'}
      fallbackImage={`${designSystemBaseUrl}/images/placeholders/team.svg`}
      sport={sport}
      team={home as TeamnameProps['team']}
      showLogos={showLogos}
      showLinks={showLinks}
      logoPosition={'right'}
      darkMode={darkMode}
    />

    <Score layout={ScoreLayoutTypes.Banner}
      score={score}
      sport={sport}
      status={status}
      label={label}
      date={date}
      title={title}
      darkMode={darkMode}
    />

    <Teamname
      size={'XXLarge'}
      fallbackImage={`${designSystemBaseUrl}/images/placeholders/team.svg`}
      sport={sport}
      team={away as TeamnameProps['team']}
      showLogos={showLogos}
      showLinks={showLinks}
      logoPosition={'left'}
      darkMode={darkMode}
      isAway={true}
    />
  </div>
}

export default BannerScoreBoard

export type {
  ScoreboardScoreProps,
  ScoreboardLayoutTypes
}

import type { FunctionComponent, PropsWithChildren, ReactElement, ReactNode } from 'react'
import type { Icons } from '../../../design-tokens/iconography/icons'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import { Children, cloneElement, useEffect, useState } from 'react'
import SwiperCore from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import Button from '../../../molecules/button'
import { NavigationIcons } from '../../section/layouts/slider'

import styles from './slider.module.scss'

type MasterMediaSliderProps = PropsWithChildren

const MasterMediaSlider: FunctionComponent<MasterMediaSliderProps> = (
  {
    children
  }
) => {
  const [controlledSwiper, setControlledSwiper] = useState<SwiperCore>()
  const [prevIcon, setPrevIcon] = useState<Icons>(NavigationIcons.LeftDisabled)
  const [nextIcon, setNextIcon] = useState<Icons>(NavigationIcons.Right)

  const handleSlideToEdge = (swiper: any) => {
    if (swiper.isBeginning) {
      setPrevIcon(NavigationIcons.LeftDisabled)
    } else {
      setNextIcon(NavigationIcons.RightDisabled)
    }
  }

  const handleSlideAwayFromEdge = () => {
    if (prevIcon === NavigationIcons.LeftDisabled) {
      setPrevIcon(NavigationIcons.Left)
    } else if (nextIcon === NavigationIcons.RightDisabled) {
      setNextIcon(NavigationIcons.Right)
    }
  }

  const actionButtons = () => <div className={styles.actionButtons}>
    <Button
      variant="tertiary"
      iconBefore={prevIcon}
      onClick={() => controlledSwiper?.slidePrev()}
      darkMode={true}
    />

    <Button
      variant="tertiary"
      iconBefore={nextIcon}
      onClick={() => controlledSwiper?.slideNext()}
      darkMode={true}
    />
  </div>

  return <Swiper
    spaceBetween={10}
    slidesPerView={1}
    pagination={{
      type: 'fraction'
    }}
    modules={[Navigation, Pagination]}
    cssMode={true}
    onToEdge={handleSlideToEdge}
    onFromEdge={handleSlideAwayFromEdge}
    onSwiper={(swiper) => setControlledSwiper(swiper)}
    onRealIndexChange={() => {
      Children
        .toArray(children)
        .forEach((item) => {
          item.props?.innerRef?.current?.firstChild?.api?.pause()
        })
    }}
    className={styles.slider}
  >
    {
      Children
        .toArray(children as ReactNode)
        .map((item) =>
          <SwiperSlide
            key={`slide-${(item as ReactElement).key}`}>
            {cloneElement(item as ReactElement)}
          </SwiperSlide>
        )
    }
    <div className={styles.bottom}>
      <div className={styles.scrollbarNavigation}>
        {actionButtons()}
      </div>
    </div>
  </Swiper>
}

export type {
  MasterMediaSliderProps
}

export {
  MasterMediaSlider
}

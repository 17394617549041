import type { FunctionComponent } from 'react'
import type { ScoreboardComponentProps } from '../../../../scoreboard'

import { useState } from 'react'

import Button, { ButtonVariant } from '../../../../../molecules/button'
import Scoreboard from '../../../../scoreboard'

import styles from './bracket-round.module.scss'

type BracketMatchProps = ScoreboardComponentProps & {
  matches?: ScoreboardComponentProps[]
}

const BracketMatch: FunctionComponent<BracketMatchProps> = (
  {
    matches,
    ...matchProps
  }
) => {
  const [showMatchDetails, setShowMatchDetails] = useState<boolean>(false)

  return <>
    <Scoreboard
      showHeader={false}
      {...matchProps.componentProps}
    />
    {
      matches
        ? <div>
          <Button
            variant={ButtonVariant.tertiary}
            iconAfter={showMatchDetails ? 'chevron-up' : 'chevron-down'}
            className={styles.matchDetailsButton}
            onClick={() => setShowMatchDetails(!showMatchDetails)}
          >matchdetails</Button>
          <div>
            {
              showMatchDetails && matches.map((subMatch: any, subIndex: number) =>
                <Scoreboard
                  key={subMatch.matchId || subIndex}
                  showHeader={false}
                  hideCompetitionName={true}
                  {...subMatch.componentProps}
                />
              )
            }
          </div>
        </div>
        : false
    }
  </>
}

export default BracketMatch

export {
  BracketMatch
}

export type {
  BracketMatchProps
}



import type { FunctionComponent } from 'react'

import { useListImpression } from '@sporza/tracking'
import clsx from 'clsx'
import { useRef } from 'react'

import Icon from '../../../atoms/icon'
import styles from '../layouts/table.module.scss'

import cellStyles from './custom.cell.module.scss'

interface CustomRowProps {
  row: any,
  ebaData: any
}

const CustomRow: FunctionComponent<CustomRowProps> = ({ row, ebaData }) => {
  const ref = useRef(null)

  useListImpression(
    {
      ref,
      disableTracking: !ebaData,
      ...ebaData,
      elementTitle: row.original?.customValue
    }
  )

  return <tr
    ref={ref}
    className={clsx(
      styles.row,
      styles.customRow,
      cellStyles.customRow,
      row.original?.customTheme && styles[`${row.original?.customTheme}Row`],
    )}
  >
    <td colSpan={100} className={clsx(
      styles.cell,
      cellStyles.cell,
      row.original?.customIcon && styles.cellWithIcon,
    )}>
      <span className={styles.cellContent}>
        {row.original?.customIcon && <Icon name={row.original?.customIcon} />}
        {row.original?.customValue}
      </span>
    </td>
  </tr>
}
export default CustomRow

import type { FunctionComponent, PropsWithChildren } from 'react'
import type { WielerManagerContainerProps } from './container'

import Icon from '../../../atoms/icon'
import Title, { TitleSize } from '../../../atoms/title'
import { getTitleFromMatch } from '../helpers'

import commonStyles from './common.module.scss'
import WielerManagerContainer from './container'
import styles from './statistics.module.scss'

interface WielerManagerStatisticsProps extends WielerManagerContainerProps, PropsWithChildren {
  data?: any
  layoutType?: string
  cyclingManagerUrl?: string
}

const WielermanagerTable: FunctionComponent<any> = ({ riders, title }) => {
  return <div className={styles.contentContainer}>
    {title}
    <ol className={styles.list}>
      {
        riders?.length
          ? riders.map(
            (rider: any) =>
              <li key={rider.name} className={styles.listItem}>{rider.name}</li>
          )
          : false
      }
    </ol>
  </div>
}

const WielerManagerStatistics: FunctionComponent<WielerManagerStatisticsProps> = (
  {
    widgetName,
    source,
    innerRef,
    data = {},
    layoutType,
    cyclingManagerUrl,
    containerQueries,
    showSponsor,
    designSystemBaseUrl,
    year
  }
) => {
  const {
    match,
    playerStats = {},
    playerImageUrl,
    playerImageUrls
  } = data
  const { leaders, transfersIn, transfersOut } = playerStats

  const getWidgetTitle = () => layoutType === 'leaders'
    ? `Meest gekozen ${source?.endsWith('f') ? 'kopvrouw' : 'kopman'}`
    : 'Meest gekozen transfers'

  const headTitle = getWidgetTitle()
  const subtitle = getTitleFromMatch(match)

  const getTransferTitle = (transferDirection = 'in') => {
    const title = transferDirection === 'in'
      ? 'renners in'
      : 'renners uit'
    const icon = transferDirection === 'in'
      ? 'arrow-circle-left'
      : 'arrow-circle-right'

    return <div className={styles.listTitle}>
      <Icon
        name={icon}
        darkMode={
          source?.toLowerCase() === 'giro'
          || source?.toLowerCase() === 'tour'
        }
      />
      {title}
    </div>
  }

  return <WielerManagerContainer
    widgetName={widgetName}
    innerRef={innerRef}
    containerQueries={containerQueries}
    ctaUrl={cyclingManagerUrl}
    playerImageUrl={playerImageUrl}
    playerImageUrls={playerImageUrls}
    source={source}
    showSponsor={showSponsor}
    designSystemBaseUrl={designSystemBaseUrl}
    year={year}
  >
    <div className={styles.container}>
      <Title
        className={commonStyles.title}
        size={TitleSize.Large}
      >{headTitle}</Title>
      {
        subtitle
        && <Title
          className={commonStyles.subtitle}
          size={TitleSize.Small}
        >{subtitle}</Title>
      }
      <div className={styles.content}>
        {
          layoutType === 'leaders'
            ? <WielermanagerTable riders={leaders}/>
            : <>
              <WielermanagerTable riders={transfersIn} title={getTransferTitle()}/>
              <WielermanagerTable riders={transfersOut} title={getTransferTitle('out')}/>
            </>
        }
      </div>
    </div>
  </WielerManagerContainer>
}

export default WielerManagerStatistics

export type {
  WielerManagerStatisticsProps
}

import type { MediaCardProps } from '../../media-card'

import { useState } from 'react'

import { findCard } from '../utils'

const useMediaModal = (cards?: any[]) => {
  const [activeCard, setActiveCard] = useState<MediaCardProps|null>()

  const setPrevActiveCard = () => {
    const prevCard = findCard(cards, activeCard, 'prev')
    setActiveCard(prevCard)
  }

  const setNextActiveCard = () => {
    const nextCard = findCard(cards, activeCard, 'next')
    setActiveCard(nextCard)
  }

  const setActiveCardWithId = (index: number, card?: Record<any, any>, selectedMediaIndex?: number) => {
    if (!card) {
      return
    }

    setActiveCard({
      ...card,
      id: `${index}-${card.title}`,
      selectedMediaIndex
    })
  }

  return {
    activeCard,
    setActiveCard,
    setPrevActiveCard,
    setNextActiveCard,
    setActiveCardWithId
  }
}

export {
  useMediaModal
}

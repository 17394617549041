import type { FunctionComponent } from 'react'
import type { ScoreboardDistanceProps } from '../default'

import clsx from 'clsx'

import Icon from '../../../../atoms/icon'
import Score, { StatusTypes } from '../../../../molecules/score'

import styles from './simple.module.scss'

type ScoreboardSimpleDistanceProps = ScoreboardDistanceProps

const SimpleDistanceBoard: FunctionComponent<ScoreboardSimpleDistanceProps> = (
  {
    sport,
    status,
    distance,
    distanceToGo,
    winner,
    label,
    typeIcon
  }
) => {
  return <div className={styles.simple}>
    {
      label
      && (status === StatusTypes.End || status === StatusTypes.Live)
      && <div className={clsx(styles.header, status === StatusTypes.Live ? styles.live : undefined)}>{label}</div>
    }
    <div className={styles.wrapper}>
      {status === StatusTypes.End && winner && <span className={styles.label}>{winner.shortName || winner.name}</span>}
      {status === StatusTypes.End && !winner && <Icon className={styles.finishIcon} name='cycling-finish' />}
      {status !== StatusTypes.End && <Score
          layout="simple"
          sport={sport}
          distance={distance}
          distanceToGo={distanceToGo}
          status={status}
          typeIcon={typeIcon}
        />}
      {(status === StatusTypes.AfterToday || status === StatusTypes.NotStarted) && <span className={styles.label}>{label}</span>}
    </div>
  </div>
}

export default SimpleDistanceBoard

export type {
  ScoreboardSimpleDistanceProps
}

import type { FunctionComponent } from 'react'
import type { ScoreDistanceProps } from '../default'

import CellWithIcon from '../../../../organisms/table/components/cell-with-icon'
import { StatusTypes } from '../../index'

interface ScoreResultDistanceProps extends ScoreDistanceProps{
  winner?: any
  startLabel?: string
  status?: string | StatusTypes
}

const ResultDistance: FunctionComponent<ScoreResultDistanceProps> = (
  {
    distanceToGo,
    winner,
    startLabel,
    status
  }) => {
  return <>
    {
      status === StatusTypes.End && winner &&
      <CellWithIcon icon={'trophy'} name={winner.name} href={winner.href}/>
    }
    {
      status === StatusTypes.Live && distanceToGo &&
      `nog ${distanceToGo} km`
    }
    {
      (
        status === StatusTypes.NotStarted
        || status === StatusTypes.AfterToday
      )
      && startLabel
      && <CellWithIcon icon={'clock-with-background'} name={startLabel.split(' ')[0]}/>
    }
  </>
}

export default ResultDistance

export type {
  ScoreResultDistanceProps
}

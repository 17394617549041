import type { FunctionComponent, ReactElement } from 'react'
import type {
  MatchdayBracketProps,
  MatchdayTableProps
} from './layout'

import { MatchdayBracket, MatchdayTable } from './layout'

enum MatchdayLayoutTypes {
  Default = 'default',
  Bracket = 'bracket'
}

interface MatchDayComponentProps {
  componentProps: MatchdayProps
}

type MatchdayProps = MatchdayTableProps & MatchdayBracketProps & {
  layout?: MatchdayLayoutTypes
}

const Layout: FunctionComponent<MatchdayProps> = ({
  layout= MatchdayLayoutTypes.Default,
  ...props
}) => {
  const matchdayLayout: {[Key in MatchdayLayoutTypes]: ReactElement} = {
      [MatchdayLayoutTypes.Default]: <MatchdayTable {...props} />,
      [MatchdayLayoutTypes.Bracket]: <MatchdayBracket {...props} />,
  }

  return matchdayLayout[layout]
    ? matchdayLayout[layout]
    : null
}

const Matchday: FunctionComponent<MatchdayProps> = (
  props
) => <Layout {...props} />

export default Matchday

export type {
  MatchdayProps,
  MatchdayLayoutTypes,
  MatchDayComponentProps
}

import type { RefObject } from 'react'
import type { ListImpressionArguments } from '../events'

import { useIntersectionObserver } from '@sporza/hooks'
import { useState } from 'react'

import { listImpressionEvent } from '../events'

interface UseListImpressionArguments extends ListImpressionArguments {
  ref: RefObject<Element>,
  disableTracking?: boolean
}

const useListImpression = (
  {
    ref,
    disableTracking = false,
    ...listImpressionArgs
  }: UseListImpressionArguments
): void => {
  if (disableTracking)
    return

  const [hasTriggeredEvent, setHasTriggeredEvent] = useState(false)

  const inTabSectionElement = ref.current?.closest('[data-tab-active]')
  const activeTabSectionElement = ref.current?.closest('[data-tab-active="true"]')

  const inTabSectionAndActive = !inTabSectionElement || (inTabSectionElement && activeTabSectionElement)

  const entry = useIntersectionObserver(
    ref,
    {
      threshold: 0.75,
      freezeOnceVisible: !!inTabSectionAndActive // when in tab section and not active, don't freeze because list not triggered yet
    }
  )

  const isVisible = !!entry?.isIntersecting && inTabSectionAndActive

  if (!hasTriggeredEvent && isVisible) {
    listImpressionEvent(listImpressionArgs)
    setHasTriggeredEvent(true)
  }
}

export {
  useListImpression
}

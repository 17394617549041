import type { CSSProperties, FunctionComponent, PropsWithChildren } from 'react'
import type { ColumnDef, ColumnPinningState } from '@tanstack/react-table'

import { mapToComponent } from '@sporza/services/component-mapper'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { camelCase } from 'change-case'
import clsx from 'clsx'
import { useEffect, useMemo, useRef, useState } from 'react'

import Hidden from '../../../atoms/hidden'
import Icon from '../../../atoms/icon'
import Title, { TitleSize } from '../../../atoms/title'
import Button from '../../../molecules/button'
import Image, { ImageLayout } from '../../../molecules/image'
import {
  CellNumber,
  CellRank,
  CellText,
  CellWithButtons,
  CellWithColor,
  CellWithFlag,
  CellWithHighlightText,
  CellWithIcon,
  CellWithImage,
  CellWithLineupItem,
  CellWithLogo,
  CellWithScoreResult,
  CellWithSubTitle,
  CellWithTags,
  CustomRow,
  DefaultRow,
  HeaderRow,
} from '../components'
import CellWithMatchReport from '../components/cell-with-match-report'
import customCellStyles from '../components/custom.cell.module.scss'
import { defaultColumn } from '../config'
import { handlePinnedColumns } from '../helpers'
import { TableLayoutTypes } from '../index'

import styles from './table.module.scss'

enum ColumnCellType {
  Icon = 'Icon',
  Image = 'Image',
  Link = 'Link',
  Rank = 'Rank',
  Number = 'Number',
  Text = 'Text',
  WithColor = 'WithColor',
  WithImage = 'WithImage',
  WithFlag = 'WithFlag',
  WithLogo = 'WithLogo',
  WithIcon = 'WithIcon',
  WithScoreResult = 'WithScoreResult',
  WithHighlightText = 'WithHighlightText',
  WithTags = 'WithTags',
  WithLineupItem = 'WithLineupItem',
  WithSubTitle = 'WithSubTitle',
  WithButtons = 'WithButtons',
  WithMatchReport = 'WithMatchReport'
}

interface ColumnDetails {
  [key: string]: string | any
}

type ColumnDefWithCellType = ColumnDef<ColumnDetails, any> & {
  type?: any,
  hidden?: boolean
  hideHeader?: boolean
  pin?: string
  title?: string
  typeOptions?: any
  classNames?: string[]
}

interface DefaultProps extends PropsWithChildren {
  className?: string
  darkMode?: boolean
  label?: string
  subLabel?: string
  caption?: string
  link?: string
  data: ColumnDetails[]
  columns: ColumnDefWithCellType[],
  limit?: number,
  expandable?: boolean
  layout?: TableLayoutTypes
  sport?: string
  hideImages?: boolean
  footer?: any
  useOutset?: boolean
  analyticsId?: string
  enableEba?: boolean
}

const renderCell = (column: any, value: any, hideImages = false, ebaData: any) => {
  const val = value()

  if (val === undefined) return null

  const commonClassName = clsx(
    column?.typeOptions?.fontMedium && customCellStyles.fontMedium,
    column?.typeOptions?.fontMedium && customCellStyles.fontMedium
  )

  switch (column.type) {
    case ColumnCellType.Image:
      return <img
        src={val}
        alt=""
        style={{
          width: column.size ? column.size / 10 + 'rem' : '2.4rem',
          maxWidth: 'none'
        }}
      />

    case ColumnCellType.Icon: {
      const width = column?.typeOptions?.iconSize ? column.typeOptions.iconSize / 10 + 'rem' : '2.4rem'
      const background = column.typeOptions?.withBackground ? 'var(--color-background-200)' : undefined

      return <Icon
        ariaHidden={true}
        name={val}
        color={column.typeOptions?.color}
        withBackground={column.typeOptions?.withBackground}
        className={clsx(
          styles.icon,
          styles[camelCase(val)])}
        style={{
          '--icon-size': width,
          '--icon-background': background,
          '--icon-background-radius': background ? 0 : undefined,
          '--icon-background-padding': background ? '.8rem' : undefined
        } as CSSProperties}
      />
    }

    case ColumnCellType.Link:
      return val.href !== undefined ? <a
        href={val.href}
        style={{
          width: column.size && column.size / 10 + 'rem',
          display: column.size || column.typeOptions?.align && 'block',
          textAlign: column.typeOptions?.align,
          whiteSpace: column.typeOptions?.align && 'normal'
        }}
        className={clsx(
          styles.cellWithLink,
          val.href && customCellStyles.customCellWithLink,
          column?.typeOptions?.fontMedium && customCellStyles.fontMedium
        )
        }>
        {val.logoUrl && <Image layout={ImageLayout.Square} className={styles.logo} src={val.logoUrl}/>} {val.name}
      </a> : val.name

    case ColumnCellType.WithColor:
      return <CellWithColor {...val} />

    case ColumnCellType.WithImage:
      return <CellWithImage {...val} hideImages={hideImages} column={column} options={column.typeOptions}/>

    case ColumnCellType.WithFlag:
      return <CellWithFlag {...val} hideImages={hideImages} column={column} options={column.typeOptions}/>

    case ColumnCellType.WithLogo:
      return <CellWithLogo {...val} hideImages={hideImages} column={column} options={column.typeOptions}/>

    case ColumnCellType.WithIcon:
      return <CellWithIcon {...val} hideImages={hideImages} column={column} options={column.typeOptions}
                           className={commonClassName}/>

    case ColumnCellType.Rank:
      return <CellRank {...val} />

    case ColumnCellType.Number:
      return <CellNumber number={val} options={column.typeOptions} className={commonClassName}/>

    case ColumnCellType.Text:
      return <CellText {...val} options={column.typeOptions}/>

    case ColumnCellType.WithScoreResult:
      return <CellWithScoreResult {...val} />

    case ColumnCellType.WithHighlightText:
      return <CellWithHighlightText {...val} options={column.typeOptions}/>

    case ColumnCellType.WithTags:
      return <CellWithTags {...val} options={column.typeOptions}/>

    case ColumnCellType.WithLineupItem:
      return <CellWithLineupItem {...val} options={column.typeOptions}/>

    case ColumnCellType.WithButtons:
      return <CellWithButtons ebaData={ebaData} buttons={val}/>

    case ColumnCellType.WithSubTitle:
      return <CellWithSubTitle {...val} />

    case ColumnCellType.WithMatchReport:
      return <CellWithMatchReport ebaData={ebaData} name={val} />

    default:
      return null
  }
}

const Table: FunctionComponent<DefaultProps> = (
  {
    className,
    darkMode,
    label,
    subLabel,
    link,
    data,
    columns,
    caption,
    limit = 10,
    expandable = false,
    hideImages,
    layout = TableLayoutTypes.Default,
    footer,
    useOutset = false,
    analyticsId,
    enableEba
  }
) => {
  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({ left: [], right: [] })
  const [showLimitedRows, setShowLimitedRows] = useState(expandable)
  const [tableData, setTableData] = useState(data)
  const ref = useRef(null)

  const ebaData = enableEba
    ? {
      listAnalyticsId: analyticsId,
      componentType: 'table',
      listTitle: label ? label : undefined,
      listElementCount: data?.filter((item: any) => !item.isCustomRow).length,
      listType: 'table',
      listElementPosition: 0
    }
    : null

  const dataMemo = useMemo<ColumnDetails[]>(() => tableData || [], [tableData])
  const columnMemo = useMemo<ColumnDefWithCellType[]>(
    () =>
      columns
        ?.map((column) => {
          if (column.type) {
            return {
              ...column,
              cell: ({ getValue }) => renderCell(column, getValue, hideImages, ebaData),
            }
          }
          return column
        })
      || [],
    [JSON.stringify(columns), hideImages]
  )

  useEffect(() => {
    setTableData(data)
  }, [data])

  useEffect(() => {
    handlePinnedColumns(setColumnPinning, columnMemo)
  }, [columnMemo])

  const updateLiveMatch = (match: any, rowOriginalMatchId: number | string) => {
    //TODO: update type definition of the match when bff merge with mono repo

    setTableData(tableData?.map(rowData => {
      if (rowData.match?.componentProps?.matchId === rowOriginalMatchId) {
        rowData = Object.assign(rowData, { match })
      }
      return rowData
    }))
  }

  const tableInstance = useReactTable({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    defaultColumn,
    columns: columnMemo,
    data: dataMemo,
    getCoreRowModel: getCoreRowModel(),

    // column pinning
    enablePinning: true,
    onColumnPinningChange: setColumnPinning,

    state: {
      columnPinning,
    },
  })

  let rows = tableInstance.getRowModel().rows
  const originalRowsLength = rows.length
  const hasMoreRows = originalRowsLength > limit

  if (expandable && showLimitedRows) {
    rows = rows.slice(0, limit)
  }

  return <section
    ref={ref}
    className={
      clsx(
        styles.tableContainer,
        className,
        useOutset && styles.outset,
      )
    }
  >
    {
      label
      && <Title
        className={styles.label}
        link={link}
        size={TitleSize.Medium}
        subTitle={subLabel}
      >
        {label}
      </Title>
    }
    <div
      className={
        clsx(
          styles.tableWrapper
        )
      }
    >
      <table
        className={
          clsx(
            styles.table,
            layout === TableLayoutTypes.Scoreboard && styles.scoreboard,
            darkMode && styles.tableDark
          )
        }
      >
        {caption && <Hidden tag={'caption'}>{caption}</Hidden>}
        <thead className={styles.head}>
        {
          tableInstance
            .getHeaderGroups()
            .map(headerGroup => <HeaderRow key={headerGroup.id} headerGroup={headerGroup}/>)
        }
        </thead>
        <tbody>
        {
          rows
            .map((row, index) => {
              const ebaDataWithIndex = ebaData
                ? {
                  ...ebaData,
                  listElementPosition: index + 1
                }
                : null
              return row.original.isCustomRow
                ? <CustomRow ebaData={ebaDataWithIndex} key={row.id} row={row}/>
                : <DefaultRow ebaData={ebaDataWithIndex} key={row.id} row={row} updateLiveMatch={updateLiveMatch}/>
            })
        }
        </tbody>
      </table>
    </div>
    {
      expandable
      && hasMoreRows
      && <footer className={styles.actionBar}>
        <Button
          iconAfter={showLimitedRows ? 'chevron-down' : 'chevron-up'}
          onClick={() => setShowLimitedRows(!showLimitedRows)}
        >
          {
            showLimitedRows
              ? 'meer tonen'
              : 'minder tonen'
          }
        </Button>
      </footer>
    }
    {
      footer
      && <footer className={styles.footer}>
        {mapToComponent(footer)}
      </footer>
    }
  </section>
}

export default Table

export {
  ColumnCellType
}

export type {
  DefaultProps,
  ColumnDetails,
  ColumnDefWithCellType
}

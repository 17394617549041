import type { FunctionComponent } from 'react'
import type { BaseEventProps } from './base-event'

import clsx from 'clsx'

import Category from '../../../atoms/category'
import Icon from '../../../atoms/icon'
import Meta from '../../../atoms/meta'
import Title, { TitleSize } from '../../../atoms/title'
import Button, { ButtonVariant } from '../../../molecules/button'
import Pill from '../../../molecules/pill'
import { TimelineMode } from '../config'

import styles from './events.module.scss'

interface PlayerEventProps extends BaseEventProps {
  player: any
  team: any
}

const PlayerEvent: FunctionComponent<PlayerEventProps> = (
  {
    id,
    timestamp,
    label,
    subLabel,
    icon,
    player,
    team,
    mode = TimelineMode.Default,
    link,
    isBelgian,
  }
) => {
  const isDefault = mode === TimelineMode.Default
  const isTeaser = mode === TimelineMode.Teaser

  return <div
    className={clsx(
      isDefault && styles.event,
      isTeaser && styles.eventTeaser,
      isTeaser && styles.isClickable,
      'sw-timeline-item'
    )}
    data-event-id={id}
    onClick={isTeaser && link ? () => window.location.href = `${link}#timeline-${timestamp}` : undefined}
  >
    <span className={styles.eventAnchor} id={`timeline-${timestamp}`}></span>

    {
      isDefault
        ?
        <div className={styles.labelContainer}>
          {label && <Pill {...label} />}
        </div>
        : null
    }

    <div className={clsx(
      styles.eventContent,
      styles.highlight,
      styles.player
    )}>
      <div className={styles.iconTitleContainer}>
        {
          isBelgian
            ? <Icon name="os-2024-belg" />
            : null
        }
        {
          subLabel
            && isDefault
            && <Category className={styles.subLabel}>{subLabel.label}</Category>
        }
        {icon && <Icon {...icon} className={styles.icon} />}
        <div className={styles.playerContent}>
          {player?.name && <Title size={TitleSize.XSmall}>
            <Button variant={ButtonVariant.tertiary} href={player.href}>{player.name}</Button>
          </Title>}
          {team?.name && <Meta>{team.name}</Meta>}
        </div>
      </div>
    </div>

    {
      isTeaser
        ?
        <div className={clsx(
          styles.eventFooter,
          styles.highlightFooter
        )}>
          {label?.label && <Meta iconBefore={label.iconBefore}>{label.label}</Meta>}
          {subLabel && <Pill {...subLabel} iconBefore={undefined} />}
        </div>
        : null
    }
  </div>
}

export default PlayerEvent

export type {
  PlayerEventProps,
}

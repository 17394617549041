import type { FunctionComponent } from 'react'
import type { Icons } from '../../design-tokens/iconography/icons'

import { Theme } from '@sporza/config'
import clsx from 'clsx'
import { useState } from 'react'

import Caption from '../../atoms/caption'
import Icon from '../../atoms/icon'
import Title, { TitleElement, TitleSize } from '../../atoms/title'
import Button from '../../molecules/button'
import Image, { ImageLayout } from '../../molecules/image'
import List, { ListAlignment } from '../list'

import styles from './player-list.module.scss'

interface PlayerListItemProps {
  firstName: string
  lastName: string
  href?: string
  shirtNumber?: number
  label?: number
  icon?: Icons
  image?: string,
}

interface PlayerListProps {
  title?: string
  subTitle?: string
  items?: PlayerListItemProps[]
  collapsed?: boolean
  link?: string
  linkLabel?: string
  theme?: Theme,
  limit?: number
  designSystemBaseUrl?: string
}

// VERDER: link list grey ipv team
const PlayerList: FunctionComponent<PlayerListProps> = (
  {
    title,
    subTitle,
    items,
    collapsed = false,
    theme = Theme.Default,
    link,
    linkLabel,
    limit,
    designSystemBaseUrl
  }
) => {
  const isAccordeon = theme === Theme.Default

  const [active, setActive] = useState(!collapsed)

  const [expanded, setExpanded] = useState(false)

  return (
    <div className={clsx(
      styles.wrapper,
      theme === Theme.OlympischeSpelen2024 && styles.os2024
    )}>
      <header className={styles.header}>
        <div className={styles.titles}>
          {title && <Title
            className={styles.title}
            size={TitleSize.Medium}
            desktopSize={TitleSize.Large}
            title={title}
            tag={TitleElement.H2}
          />}
          {subTitle && <Caption>{subTitle}</Caption>}
        </div>
        {
          isAccordeon
          && <Button
            className={styles.accordeonButton}
            onClick={() => setActive(!active)}
            iconAfter={active ? 'chevron-up' : 'chevron-down'}
            variant={'tertiary'}
          >{active ? 'sluiten' : 'tonen'}</Button>
        }
      </header>
      <ul className={clsx(
        styles.list,
        active && styles.active
      )}>
        {items
          ?.slice(0, expanded ? items.length : limit)
          ?.map((item: PlayerListItemProps, index: number) => {
            const Tag = item.href ? 'a' : 'div'
            return <li className={styles.item} key={`${index}-${item.firstName}-${item.lastName}`}>
              <Tag className={styles.link} href={item.href}>
                <div className={styles.profile}>
                  <Image
                    fallbackImage={`${designSystemBaseUrl}/images/placeholders/player.svg`}
                    layout={ImageLayout.Square}
                    className={styles.image}
                    src={item.image}
                    alt={item.firstName}/>
                  {item.shirtNumber && <div className={styles.shirtNumber}>{item.shirtNumber}</div>}
                  {item.icon && <Icon className={styles.icon} name={item.icon} />}
                </div>
                <div className={styles.info}>
                  <div className={styles.name}>
                    <Caption>
                      {item.firstName}
                    </Caption>
                    <Title
                      className={styles.name}
                      size={TitleSize.Small}
                      title={item.lastName}
                    />
                  </div>
                  {item.label && <span className={styles.label}>{item.label}</span>}
                </div>
              </Tag>
            </li>
          })
        }
      </ul>
      <footer className={styles.footer}>
        <List align={ListAlignment.Center}>
          {!isAccordeon && limit &&
            <Button
              onClick={() => setExpanded(!expanded)}
              iconAfter={expanded ? 'chevron-up' : 'chevron-down'}
            >
              {expanded ? 'minder' : 'meer'}
            </Button>
          }
          {
            link && linkLabel
            && <Button
              className={styles.more}
              variant={'primary'}
              iconAfter={'chevron-right'}
              href={link}
            >{linkLabel || 'meer'}</Button>
          }
        </List>
      </footer>
    </div>
  )
}

export default PlayerList

export type {
  PlayerListProps,
  PlayerListItemProps
}

import type { FunctionComponent, PropsWithChildren } from 'react'

import styles from './grid.module.scss'

type MasterMediaGridProps = PropsWithChildren

const MasterMediaGrid: FunctionComponent<MasterMediaGridProps> = (
  {
    children
  }
) => {
  return <section
    className={styles.mastermedia}
  >
    {children}
  </section>
}

export {
  MasterMediaGrid
}

import type { CSSProperties, Dispatch, FunctionComponent, PropsWithChildren, SetStateAction } from 'react'
import type { DragEndEvent } from '@dnd-kit/core'

import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors, } from '@dnd-kit/core'
import {
  arraySwap,
  rectSwappingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import clsx from 'clsx'

import Button from '../../../../../molecules/button'
import { Notification, NotificationType } from '../../../../../molecules/notification'

import styles from './formation.module.scss'
import { PlayerOption } from './player-option'

interface FormationProps extends PropsWithChildren {
  formations?: string[]
  selectedFormation?: string
  setSelectedFormation?: (formation: string) => void
  players?: any[]
  setPlayers?: Dispatch<SetStateAction<any[] | undefined>>
  notification?: string
  reorderMode?: boolean
}

const SortablePlayerOption: FunctionComponent<any> = (
  {
    id,
    isDisabled,
    isReorderActive,
    ...props
  }
) => {
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = useSortable(
    {
      id,
      disabled: isDisabled,
      animateLayoutChanges: () => false
    }
  )

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: 'none'
  }

  const inlineStyles: CSSProperties = {
    opacity: isDragging ? '.6' : '1',
    borderRadius: '10px',
    cursor: isDisabled ? 'not-allowed' : isDragging ? 'grabbing' : 'grab',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: isDragging ? 'scale(1.05)' : 'scale(1)',
    touchAction: 'none'
  }

  return <div
    ref={setNodeRef}
    style={style}
    {...attributes}
    {...listeners}
  >
    <PlayerOption
      style={inlineStyles}
      isReorderActive={isReorderActive && !isDisabled}
      {...props}
    />
  </div>
}

const getTeamLineup = (tactic = '4-3-3', players: any[], isReorderActive?: boolean) => {
  const tacticArr = tactic.split('-').map(Number)
  tacticArr.unshift(1)
  let positionCounter = 0

  return tacticArr.reduce((acc: any[], lineCount, index) => {
    const teamLineup = players
      .slice(positionCounter, positionCounter + lineCount)
      .map(player => {
        if (player.function === 'KEEPER') {
          return <PlayerOption
            key={player?.shortName}
            id={player?.shortName}
            option={player}
            isReorderActive={false}
          />
        } else {
          return <SortablePlayerOption
            key={player?.shortName}
            id={player?.shortName}
            option={player}
            isDisabled={player?.function === 'KEEPER'}
            isReorderActive={isReorderActive}
          />
        }
      })

    positionCounter += lineCount

    if (index > 0) {
      acc.unshift(<div key={`separator-${index}`} className={styles.separator}></div>)
    }

    acc.unshift(...teamLineup)

    return acc
  }, [])
}

const Formation: FunctionComponent<FormationProps> = (
  {
    formations,
    selectedFormation,
    setSelectedFormation,
    players = [],
    setPlayers,
    notification,
    reorderMode = false,
    children
  }
) => {
  // const [items, setItems] = useState(players || [])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const handleDragEnd = (event: DragEndEvent) => {
    if (!setPlayers) {
      return
    }

    const { active, over } = event

    if (active.id !== over?.id) {
      setPlayers((players) => {
        if (!players) {
          return
        }

        const oldIndex = players.findIndex(item => item?.shortName === active?.id)
        const newIndex = players.findIndex(item => item?.shortName === over?.id)

        return arraySwap(players, oldIndex, newIndex)
      })
    }
  }

  return <div className={styles.formation}>
    {
      formations
      && setSelectedFormation
      && <div className={styles.formationHeader}>
        {
          formations.map((formation: string) =>
            <Button
              key={formation}
              onClick={() => setSelectedFormation(formation)}
              className={clsx(
                styles.formationButton,
                selectedFormation === formation ? styles.isSelected : ''
              )}
            >{formation}</Button>
          )
        }
      </div>
    }

    {
      notification
      && <Notification
        type={NotificationType.Error}
        text={notification}
        darkMode={true}
      />
    }

    {
      reorderMode
        ? <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={players?.map(item => item?.shortName) || []}
            strategy={rectSwappingStrategy}
          >
            <div className={styles.field}>
              {
                getTeamLineup(selectedFormation, players || [], true)
              }
            </div>
          </SortableContext>
        </DndContext>
        : <div className={styles.field}>
          {
            getTeamLineup(selectedFormation, players || [])
          }
        </div>
    }

    {children}
  </div>
}

export {
  Formation
}

import type { FunctionComponent } from 'react'

import Caption from '../../../../atoms/caption'
import styles from '../../score.module.scss'
import { ScoreRoundsProps } from '../default'

const DetailedRounds: FunctionComponent<ScoreRoundsProps> = ({
  roundsToGo,
  label
}) => {
  return <>
    {Boolean(roundsToGo) && <Caption className={styles.label}>nog <span className={styles.bigNumber}>{roundsToGo}</span> {roundsToGo === 1 ? 'ronde' : 'rondes'}</Caption>}
    {label && <Caption className={styles.label}>{label}</Caption>}
  </>
}

export default DetailedRounds

export type {
  ScoreRoundsProps
}

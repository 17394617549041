import type { FunctionComponent } from 'react'
import type { SectionStaticProps } from './section-grid'
import type { SectionSliderProps } from './slider'

import { useBreakpoint } from '@sporza/hooks/use-breakpoint'

type SectionHybridProps = SectionStaticProps & SectionSliderProps

const SectionHybrid: FunctionComponent<SectionHybridProps> = (props) => {
  const { getOption } = useBreakpoint()

  const hybridOptions = getOption(props.hybridOptions)
  if (hybridOptions){
    const Tag = hybridOptions.tag
    return <Tag {...props} {...hybridOptions.props} />
  }
}
export default SectionHybrid

import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import Hidden from '../../../atoms/hidden'
import Button, { ButtonVariant } from '../../../molecules/button'

import styles from './custom.cell.module.scss'

interface CellWithLineupItemProps {
  position?: number,
  player?: any,
  events?: any[],
  column?: any,
  options?: Record<string, any>
}

const CellWithLineupItem: FunctionComponent<CellWithLineupItemProps> = (
  {
    position,
    player,
    events,
    column = {},
    options = {}
  }
) => {
  return <div className={
    clsx(
      styles.lineupItem,
      options.reversed && styles.reversed,
    )
  }
  >
    {
      position
        ? <span className={styles.position}>
          <Hidden>shirtnummer</Hidden> {position}
        </span> : false
    }
    {
      player?.shortName
        ? <Button
          variant={ButtonVariant.tertiary}
          className={styles.playerName}
          href={player.href}
          ariaLabel={`${player?.firstName} ${player?.lastName}`}
        >{player.shortName}</Button>
        : false
    }
    {
      events?.length
        ? events.map((event: any) => <Button
          key={event.href}
          {...event}
          href={null} // disable for now, check if we still need this
          variant={ButtonVariant.tertiary}
        />)
        : false
    }
  </div>
}

export default CellWithLineupItem

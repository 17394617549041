import type { FunctionComponent } from 'react'

import { addScriptToDOM } from '@sporza/utils/dom'
import { useEffect } from 'react'

import { RegattaRaceName } from './config'
import styles from './sailing.module.scss'

enum SailingType {
  List = 'list',
  Single = 'single'
}

enum SailingLinkMode {
  Tab = 'tab',
  Popup = 'popup'
}

type SailingList = {
  server?: string,
  eventId?: string,
  regattaName?: RegattaRaceName,
  title?: string | false,
  noStyles?: boolean,
  linkMode?: SailingLinkMode
}

type SailingRace = {
  regattaName: RegattaRaceName,
  trackedRaceName: string,
  type?: SailingType,
  server?: string,
  title?: string | false,
  noStyles?: boolean,
  linkMode?: SailingLinkMode
}

interface SailingProps extends SailingList, Partial<SailingRace> {
  apiToken: string,
  type?: SailingType
}

const layouts = {
  [SailingType.List]: 'sapsa-regatta-list',
  [SailingType.Single]: 'sapsa-race-button'
}

const Sailing: FunctionComponent<SailingProps> = (
  {
    apiToken,
    type = SailingType.List,
    server,
    eventId,
    regattaName,
    trackedRaceName,
    title,
    noStyles,
    linkMode
  }: SailingProps
) => {
  useEffect(() => {
    const id = 'sailing-script'

    if (!document.getElementById(id)) {
      addScriptToDOM({
        src: 'https://2024.minakari.io/sapsacomp.umd.cjs',
        id
      })
    }
  }, [])

  const Layout = layouts[type] || layouts[SailingType.List]

  return <div className={styles.sailing}>
    <Layout
      api-token={apiToken}
      server={server}
      event-id={eventId}
      regatta-name={regattaName}
      tracked-race-name={trackedRaceName}
      title={title}
      no-styles={noStyles}
      link-mode={linkMode}
    />
  </div>
}

export {
  Sailing,
  SailingType,
  SailingLinkMode
}

export * from './config'

export type {
  SailingProps
}

import type { RefObject } from 'react'
import type { ListClickArguments } from '../events'

import { isObjectWithData } from '@sporza/utils/objects'
import { useEffect } from 'react'

import { listClickEvent } from '../events'

interface UseListClickArguments extends ListClickArguments {
  ref: RefObject<Element>,
  disableTracking?: boolean
}

const useListClick = (
  {
    ref,
    disableTracking = false,
    ...listClickArgs
  }: UseListClickArguments
): void => {
  if (disableTracking) {
    return
  }

  const callListClickEvent = () => {
    listClickEvent(listClickArgs)
  }

  useEffect(() => {
    if (ref && isObjectWithData(listClickArgs))
      ref.current
        ?.addEventListener('click', callListClickEvent)

    return () => {
      if (ref && isObjectWithData(listClickArgs))
        ref.current
          ?.removeEventListener('click', callListClickEvent)
    }
  }, [ref])
}

export {
  useListClick
}

import type { FunctionComponent } from 'react'
import type { PlayernameProps } from '../../../../molecules/playername'
import type { ScoreboardSetsProps } from '../default'

import clsx from 'clsx'

import Title, { TitleSize } from '../../../../atoms/title'
import Playername from '../../../../molecules/playername'
import Score, { ScoreLayoutTypes } from '../../../../molecules/score'
import styles from '../../scoreboard.module.scss'

const DetailedSetsBoard: FunctionComponent<ScoreboardSetsProps> = (
  {
    status,
    label,
    home,
    away,
    sets,
    sport,
    showLinks,
    competitionName,
    isLastItem= false
  }
) => {
  const homePlayers = home as [PlayernameProps['player']]
  const awayPlayers = away as [PlayernameProps['player']]

  return <>
  {competitionName && <Title desktopSize={TitleSize.Medium} size={TitleSize.XSmall}>{competitionName}</Title>}
    <div className={clsx(
      styles.scoreboardContent,
      isLastItem && styles.lastItem
    )}>
      <div className={styles.setsPlayers}>
        <span className={styles.setsPlayer}>
          {homePlayers?.map((player, index) => <Playername size={'Large'} key={`${index}-${player?.name}`} player={player} showLinks={showLinks} />)}
        </span>
        <span className={styles.setsPlayer}>
          {awayPlayers?.map((player, index) => <Playername size={'Large'} key={`${index}-${player?.name}`} player={player} showLinks={showLinks} />)}
        </span>
      </div>
      <Score layout={ScoreLayoutTypes.Detailed} sets={sets} sport={sport} status={status} label={label} />
    </div>
  </>
}

export default DetailedSetsBoard

export type {
  ScoreboardSetsProps
}

import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import Caption from '../../../atoms/caption'
import Icon from '../../../atoms/icon'
import Paragraph from '../../../atoms/paragraph'
import Title from '../../../atoms/title'
import Pill from '../../../molecules/pill'
import Card from '../../card'
import { LivestreamCardProps } from '../index'

import styles from './livestream-card.module.scss'

const LivestreamCardDefault: FunctionComponent<LivestreamCardProps> = (
  {
    link,
    icon,
    title,
    subtitle,
    button,
    time,
    withHover = true,
    className,
    image,
    ref,
    ebaData = {}
  }
) => {
  return <Card
    link={link}
    className={clsx(
      styles.livestreamCard,
      className
    )}
    withHover={withHover}
    innerRef={ref}
  >
    <div className={styles.image} style={{ backgroundImage: image && `url(${image})` }}></div>
    <div className={styles.content}>
      <div className={styles.top}>
        {button && button.status === 'LIVE' && <Pill iconBefore={button?.icon}>{button?.title}</Pill>}
        {button && button.status !== 'LIVE' && <Caption darkMode={true}>{button?.title} {button?.icon && <Icon className={styles.sporzaIcon} name={button?.icon} />}</Caption>}
        {time && <Caption className={time.status === 'NOT_STARTED' ? styles.notStarted : undefined}>{time?.icon && <Icon darkMode={false} name={time?.icon} />} {time?.text}</Caption>}
      </div>
      <div className={styles.titles}>
        <Title size={'Small'} darkMode={true}>{title}</Title>
        <Paragraph darkMode={true}>{subtitle}</Paragraph>
        {icon && <Icon className={styles.icon} name={icon} />}
      </div>
    </div>
  </Card>
}

export default LivestreamCardDefault

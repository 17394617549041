import { Fragment, FunctionComponent, PropsWithChildren } from 'react'

import Pill from '../../../../../molecules/pill'

import { PlayerOption } from './player-option'
import styles from './selection.module.scss'

interface SelectionProps extends PropsWithChildren {
  players?: any[]
  playersLeft?: number
  togglePlayerSelection?: (player: any) => void
}

const playerTypes = ['ATTACKER', 'MIDFIELDER', 'DEFENDER', 'KEEPER']
const playerTypeNames = ['aanvallers', 'middenvelders', 'verdedigers', 'keepers']

const Selection: FunctionComponent<SelectionProps> = (
  {
    players,
    playersLeft,
    togglePlayerSelection,
    children
  }
) => {
  const hasSelectedKeeper = players
    ?.some((player) =>
      player.function === 'KEEPER' && player.selected
    )

  return <div className={styles.selection}>
    {
      playerTypes.map((type, index) => (
        <Fragment key={playerTypeNames[index]}>
          <Pill className={styles.separator}>{playerTypeNames[index]}</Pill>
          <div className={styles.playerLine}>
            {
              players
                ?.filter((option) => option.function === type)
                ?.map((option) =>
                  <PlayerOption
                    key={option.name}
                    option={option}
                    toggleOption={togglePlayerSelection}
                    isDisabled={
                      (hasSelectedKeeper && option.function === 'KEEPER' && !option.selected)
                      || (!playersLeft && !option.selected)
                    }
                  />
                )
            }
          </div>
        </Fragment>
      ))
    }

    {children}
  </div>
}

export {
  Selection
}

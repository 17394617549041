import React, { FunctionComponent } from 'react'

import { FilterProps } from '../../molecules/filter'
import { ProgramListProps } from '../../molecules/program-list'

import EpgDefault from './layout'

interface EpgProps {
  title?: string
  day?: string
  itemWidth?: number
  items?: ProgramListProps[]
  filter?: FilterProps
  now?: Date
  sportApiUrl?: string
  tags?: string[]
}

const Epg: FunctionComponent<EpgProps> = (props) =>
   <EpgDefault {...props} />


export default Epg

export type {
  EpgProps
}

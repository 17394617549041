import type { FunctionComponent } from 'react'
import type { BaseEventProps } from './base-event'

import clsx from 'clsx'

import Category from '../../../atoms/category'
import Icon from '../../../atoms/icon'
import Meta from '../../../atoms/meta'
import Title, { TitleElement, TitleSize } from '../../../atoms/title'
import Button, { ButtonSize, ButtonVariant } from '../../../molecules/button'
import Image from '../../../molecules/image'
import Pill from '../../../molecules/pill'
import Scoreboard, { ScoreboardComponentProps, ScoreboardLayoutTypes } from '../../scoreboard'
import { Owner, TimelineMode } from '../config'
import { TimelineType } from '../timeline'

import styles from './events.module.scss'

interface ScoreboardEventProps extends BaseEventProps {
  titlePrefix: string
  scoreboard: ScoreboardComponentProps
}

const ScoreboardEvent: FunctionComponent<ScoreboardEventProps> = (
  {
    id,
    timestamp,
    label,
    subLabel,
    icon,
    title,
    titlePrefix,
    text,
    image,
    owner,
    timelineType,
    scoreboard,
    mode = TimelineMode.Default,
    isBelgian,
    link,
  }
) => {
  const { home, away } = scoreboard?.componentProps || {}
  const isDefault = mode === TimelineMode.Default
  const isTeaser = mode === TimelineMode.Teaser

  let fullText

  if (timelineType === TimelineType.Full) {
    const team = owner === Owner.HOME ? home : away

    fullText = <>
      {
        icon || team.name
          ? <div className={styles.iconTitleContainer}>
            {
              isBelgian
                ? <Icon name="os-2024-belg" />
                : null
            }
            <Icon {...icon} className={styles.icon} />
            <Title size={TitleSize.Small} tag={TitleElement.H3}>
              {titlePrefix}&nbsp;
              <Button
                href={team.href}
                variant={ButtonVariant.tertiary}
                size={ButtonSize.large}
              >{team.name}
              </Button>
            </Title>
          </div>
          : null
      }
      {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
    </>
  }

  return <div
    className={clsx(
      isDefault && styles.event,
      isTeaser && styles.eventTeaser,
      isTeaser && styles.isClickable,
      'sw-timeline-item'
    )}
    data-event-id={id}
    onClick={isTeaser && link ? () => window.location.href = `${link}#timeline-${timestamp}` : undefined}
  >
    <span className={styles.eventAnchor} id={`timeline-${timestamp}`}></span>

    {
      isDefault
        ?
        <div className={styles.labelContainer}>
          {label && <Pill {...label} />}
        </div>
        : null
    }

    <div className={clsx(
      styles.eventContent
    )}>
      {
        subLabel
          && isDefault
          && <Category className={styles.subLabel}>{subLabel.label}</Category>
      }
      {fullText}
      {image && <Image {...image} addToGallery={true} />}
      { !isTeaser &&
        <Scoreboard
          {...scoreboard.componentProps}
          url={undefined}
          sportApiUrl={undefined}
          interval={undefined}
          title={title}
          layout={ScoreboardLayoutTypes.Event}
        />
      }
    </div>

    {
      isTeaser
        ?
        <div className={styles.eventFooter}>
          {label?.label && <Meta iconBefore={label.iconBefore}>{label.label}</Meta>}
          {subLabel && <Pill {...subLabel} iconBefore={undefined} />}
        </div>
        : null
    }
  </div>
}

export default ScoreboardEvent

export type {
  ScoreboardEventProps,
}

import { addVendorTags, isVendorAllowed, Vendor } from '@sporza/utils/gdpr'
import { waitForGlobal } from '@sporza/utils/objects'
import { useEffect, useState } from 'react'

import { vendorNotAllowed } from './components'

const useGdpr = (vendor: Vendor) => {
  const [gdprVendorAllowed, setGdprVendorAllowed] = useState(false)

  useEffect(() => {
    const isAllowed = () => {
      const vendorAllowed = isVendorAllowed(vendor)
      setGdprVendorAllowed(vendorAllowed)

      if (vendorAllowed) {
        addVendorTags()
      }

      return vendorAllowed
    }

    const checkVendorExponentially = (timeout = 1000) => {
      if (!isAllowed()) {
        setTimeout(() => {
          checkVendorExponentially(timeout * 1.2)
        }, timeout)
      }
    }

    waitForGlobal(window, 'VRT.CODRegulator', () => {
      checkVendorExponentially()
    })
  }, [])

  return {
    gdprVendorAllowed,
    vendorNotAllowedComponent: vendorNotAllowed(vendor),
  }
}

export {
  useGdpr
}

const getTitleFromMatch = (match: any, fallbackTitle?: string) => {
  if (!match)
    return fallbackTitle

  const subtitleParts = []

  if (match.stage) {
    subtitleParts.push(`rit ${match.stage}`)

    match.totalStages && subtitleParts.push(`\u00A0/ \u00A0${match.totalStages}`)
  }

  if (match.competitionName) {
    subtitleParts.length > 0 && subtitleParts.push('-')
    subtitleParts.push(match.competitionName)
  }

  return subtitleParts.join(' ')
}

export {
  getTitleFromMatch
}

import type { FunctionComponent } from 'react'

import clsx from 'clsx'
import { useEffect, useState } from 'react'

import Title, { TitleElement, TitleSize } from '../../../atoms/title'
import Button from '../../../molecules/button'
import { SportTypes } from '../../../molecules/score'
import DataCard from '../../data-card'
import { DrawItemType } from '../config'

import styles from './result-selection.module.scss'

interface ResultSelectionGroupProps {
  type?: DrawItemType
  name?: string
  objects?: any[]
  availableSlots?: number
  sport?: string
  open?: boolean
  designSystemBaseUrl?: string
}

function fillGroups(groups: any[] = [], availableSlots?: number) {
  if (!availableSlots)
    return groups

  const existingPositions = new Set(groups.map(obj => obj.position))
  let emptyPosition = 0

  while (groups.length < availableSlots) {
    while (existingPositions.has(emptyPosition)) {
      emptyPosition++
    }

    groups.push({ position: emptyPosition, name: '' })
    existingPositions.add(emptyPosition)
  }

  groups.sort((a, b) => a.position - b.position)

  return groups
}

function getPlayerImage(designSystemBaseUrl: string, imageUrl: string, sport?: string) {
  if (!sport) {
    return
  }

  if (imageUrl) {
    return imageUrl
  }

  switch (sport) {
    case SportTypes.Cycling:
      return `${designSystemBaseUrl || ''}/images/draws/cycling/default-selection-icon.svg`
    default:
      return `${designSystemBaseUrl || ''}/images/draws/default/default-selection-icon.svg`
  }
}

const ResultSelectionGroup: FunctionComponent<ResultSelectionGroupProps> = (
  {
    type,
    name,
    objects,
    availableSlots,
    sport,
    designSystemBaseUrl
  }
) => {
  const [init, setInit] = useState<boolean>(false)
  const [groups, setGroups] = useState(fillGroups(objects, availableSlots))
  const [hasNewItem, setHasNewItem] = useState<boolean>(false)
  const [previousLength, setPreviousLength] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (!init) {
      setInit(true)
    }
  }, [])

  useEffect(() => {
    if (!objects || !init)
      return

    const currentLength = objects
      .filter((obj: any) => obj.name !== '---')
      .length

    if (previousLength === undefined) {
      setPreviousLength(currentLength)
    } else if (currentLength > previousLength) {
      setHasNewItem(true)
    }

    setPreviousLength(currentLength || 0)

    const timerId = setTimeout(() => {
      setHasNewItem(false)
    }, 1000)

    setGroups(fillGroups(objects, availableSlots))

    return () => clearTimeout(timerId)
  }, [objects, availableSlots])

  return <div
    key={name}
    className={clsx(
      styles.group,
      hasNewItem && styles.hasNewGroupItem
    )}
  >
    <Button
      variant={'tertiary'}
      className={clsx(
        styles.selectionTitle
      )}
    >
      <Title
        className={styles.selectionGroup}
        size={TitleSize.XSmall}
        tag={TitleElement.H3}
      >{name}</Title>
    </Button>

    <div className={clsx(
      styles.content
    )}>
      {
        groups?.map((group: any, index) =>
          <DataCard
            key={`${group.name}-${index}`}
            className={clsx(
              styles.dataCard,
              !group.name && styles.emptyDataCard
            )}
            {...group}
            link={group.url}
            type={type}
            sport={sport}
            img={getPlayerImage(designSystemBaseUrl, group.playerImageUrl, sport)}
            title={group.name}
            nationality={{
              flagUrl: group.flagUrls?.svg,
              name: group.nationalityShort
            }}
          />
        )
      }
    </div>
  </div>
}

export {
  ResultSelectionGroup
}

export type {
  ResultSelectionGroupProps
}

import type { FunctionComponent } from 'react'
import type { Row } from '@tanstack/react-table'

import { useApi } from '@sporza/hooks'
import { useListImpression } from '@sporza/tracking'
import { flexRender } from '@tanstack/react-table'
import { pascalCase } from 'change-case'
import clsx from 'clsx'
import { useEffect, useRef } from 'react'

import Hidden from '../../../atoms/hidden'
import { ColumnDetails } from '../layouts/table'
import styles from '../layouts/table.module.scss'

interface DefaultRowProps {
  row: Row<ColumnDetails>
  updateLiveMatch: (data: any, id: string) => void
  ebaData: any
}

const DefaultRow: FunctionComponent<DefaultRowProps> = (
  {
    row,
    updateLiveMatch,
    ebaData
  }
) => {
  const ref = useRef(null)
  const { data } = useApi(row.original.match)

  useEffect(() => {
    updateLiveMatch(data, row.original.match?.componentProps?.matchId)
  },
    [data]
  )

  useListImpression(
    {
      ref,
      disableTracking: !ebaData,
      ...ebaData,
      elementTitle: row.original.name?.title || row.original.match?.componentProps?.name || row.original.title || row.original.name
    }
  )

  const hideConditionStyle = (hideCondition: []) => {
    const found = hideCondition?.filter((id: any) => {
      const foundData = row
        .getVisibleCells()
        .filter((cell:any) => {
          switch (true){
            case cell.column.columnDef?.id === id && cell.column.columnDef?.id === 'matchLink': {
              const { href, rewatchHref, isActive } = cell.getValue()
              return (href || rewatchHref) && !isActive
            }
            default:
              return cell.column.columnDef?.id === id && cell.getValue()
          }
        })

      return foundData?.length > 0
    })

    return found?.length > 0 ? styles.hide : ''
  }

  return <tr
    ref={ref}
    className={clsx(
      styles.row,
      row.original.rowType && styles[`row${pascalCase(row.original.rowType)}`],
      row.original.match?.componentProps?.status === 'LIVE' && styles.rowLive
    )}
  >
    {row.original.ariaLabel && <Hidden tag={'th'}>{row.original.ariaLabel}</Hidden>}
    {
      row
        .getVisibleCells()
        .map((cell:any) => {
            return <td
              aria-hidden={!!row.original.ariaLabel}
              key={cell.id}
              className={
                clsx(
                  styles.cell,
                  hideConditionStyle(cell?.column?.columnDef?.hideCondition),
                  cell?.column?.columnDef?.typeOptions?.mobileOnly && styles.cellMobileOnly,
                  cell?.column?.columnDef?.typeOptions?.desktopOnly && styles.cellDesktopOnly,
                  cell?.column?.getIsPinned() && styles.cellSticky,
                  cell?.column?.id && styles[`cell${pascalCase(cell.column.id)}`],
                  cell?.column?.columnDef?.classNames?.map((className: string) => styles[`cell${pascalCase(className)}`]),
                  cell?.column?.columnDef?.typeOptions?.top3Highlight && row.index < 3 && styles.cellHighlight
                )
              }
              colSpan={cell.column.columnDef?.colSpan}
              style={{
                left: cell.column.getStart('left'),
                right: cell.column.getStart('right'),
                width: !isNaN(cell.column.columnDef?.size) ? cell.column.columnDef?.size / 10 + 'rem' : 'auto',
                paddingLeft: cell?.column?.columnDef?.type === 'WithSubTitle' ? '0' : undefined
              }}
            >
              {
                flexRender(
                  cell.column.columnDef.cell,
                  cell.getContext()
                )
              }
            </td>
          }
        )
    }
  </tr>
}
export default DefaultRow

import type { FunctionComponent } from 'react'

import styles from './drip-loader.module.scss'

interface DripLoaderProps {
  animate?: boolean
}

const DripLoader: FunctionComponent<DripLoaderProps> = (
  {
    animate = false,
  }
) => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 47" className={styles.dripLoader}>
    <rect y="27" width="27" height="4" rx="2" transform="rotate(-90 0 27)"/>
    <rect y="46" width="4" height="4" rx="2" transform="rotate(-90 0 46)"/>
    <rect y="40" width="4" height="4" rx="2" transform="rotate(-90 0 40)"/>
    <rect y="34" width="4" height="4" rx="2" transform="rotate(-90 0 34)"/>
  </svg>
}

export {
  DripLoader
}

export type {
  DripLoaderProps,
}

import { FunctionComponent, useEffect } from 'react'

import Title, { TitleSize } from '../../atoms/title'

import styles from './widget.module.scss'

interface GracenoteWidgetProps {
  title?: string
  sportId?: string
  phaseId?: string
  matchId?: string
  widgetId?: string
  customCSS?: string
}

const GracenoteWidget: FunctionComponent<GracenoteWidgetProps> = (
  props
) => {
  const {
    title,
    sportId,
    phaseId,
    matchId,
    widgetId,
    customCSS = 'https://api.sporza.be/loc/gracenoteWidgets.css'
  } = props

  useEffect(() => {
    // local sdk inladen, normaal via launch
    (function(g,r,a,c,e){r.gnsWidgetObject=e;r[e]=r[e]||function(n,o,t){t=Object.prototype.toString.call(t)==='[object Array]'?t:[t];(r[e].q=r[e].q||[]).push([n,o,t])};r[e].c=r[e].c||{ l:a };r.c=r.c||function(fs){r[e].c.featureSwitch=fs};const x=g.getElementsByTagName('script')[0],y=g.createElement('script'),z=g.createElement('script');y.async=0;y.src=c;z.async=1;z.src=a;x.parentNode.insertBefore(y,x);x.parentNode.insertBefore(z,x)})(document,window,'https://widgets.sports.gracenote.com/gns.sdk.loader.js','https://widgets.sports.gracenote.com/featureSwitches.jsonp', 'gnsWidget');gnsWidget('widgets','create')
  }, [])

  return <div className={styles.widget}>
    {title && <Title size={TitleSize.Large}>{title}</Title>}
    <div className="gnsWidget"
         data-custom_css={customCSS}
         data-sport_id={sportId}
         data-phase_id={phaseId}
         data-match_id={matchId}
         data-widget_id={widgetId}/>
  </div>
}

export default GracenoteWidget

export type {
  GracenoteWidgetProps
}
